import React from 'react';
import database from './functions/firebase';
import unique from 'uniqid';
import { ref, set } from 'firebase/database';
const UpdateProductionFirebase = (masterPrefix) => {
  const connectedMsgRef = ref(database, `loginAuth/${masterPrefix}/production`);
  const getUnique = unique();
  set(connectedMsgRef, getUnique);
};

export default UpdateProductionFirebase;
