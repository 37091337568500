import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';

import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
import moment from 'moment';
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectTableMasterPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { Skeleton } from '@mui/material';
import { BackButton } from '../utility/component/buttons';
import database from '../utility/component/functions/firebase';
import { child, get, onValue, ref } from 'firebase/database';

// Define custom styled components

const OrderStatusModal = ({
  status,
  handleClose,
  orderDetails,
  onSave,
  checkOrder,
}) => {
  const [count, setCount] = useState(1);
  const [depList, setDepList] = useState([]);
  const [loading, setLoading] = useState(false);
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    await axios
      .post(
        '/api/masters/listDepartmentMaster',
        {
          table_prefix_master: masterPrefix,
          DEPARTMENT_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        setDepList(a);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const getMaxSerialNo = (details) => {
    return details.reduce(
      (max, item) => (item.SERIAL_NO > max ? item.SERIAL_NO : max),
      details[0].SERIAL_NO
    );
  };

  const sortBySerialNo = (details) => {
    return details.sort((a, b) => a.SERIAL_NO - b.SERIAL_NO);
  };

  const currentStatus = Math.max(...orderDetails.map((i) => i.SERIAL_NO));
  const currentStatusOrder = orderDetails.filter(
    (i) => i.SERIAL_NO === currentStatus
  );
  const highestSerialNos = getMaxSerialNo(orderDetails);
  const lastOrder = orderDetails.filter(
    (o) => o.SERIAL_NO === highestSerialNos
  );
  const highestSerialNoss = depList.filter(
    (o) => o.DEPARTMENT_NAME === lastOrder[0].DEPARTMENT_NAME
  );
  const highestSerialNo = highestSerialNoss[0] ? highestSerialNoss[0].CODE : 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + 0.2;
        if (newCount > highestSerialNo) {
          clearInterval(interval);
          return highestSerialNo + 1; // Limit count to highest serial number
        }
        return newCount;
      });
    }, 80);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [highestSerialNo]);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const connectedRef = ref(database, `loginAuth/${masterPrefix}`);
    const connectedRef2 = ref(database, `loginAuth/${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          if (orderDetails[0]) {
            checkOrder(orderDetails[0].QR_CODE);
          }
        }
      });
    });
  }, []);
  const width = useSelector(selectWidth);
  const bolls = width >= 764 ? [1, 2, 3, 4, 5] : [...Array(10)];
  return (
    <div>
      <Modal
        open={status}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Slide direction="up" in={status} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: 'calc(100vh - 160px)',
            }}
          >
            <div className="flex justify-between border-b border-gray-200 px-4 pt-2 pb-2 ">
              <div className="text-[18px]  font-[700]">Track Order</div>
              <button onClick={handleClose}>
                <i className="bi text-red bi-x-square-fill"></i>
              </button>
            </div>
            <div
              style={{
                height: 'calc(100% - 80px)',
              }}
              className="mt-1"
            >
              <div className="px-4 py-2">
                <div className="text-sm flex ">
                  <div className="w-[100px] t:w-[150px] font-semibold">
                    Booking Date{' '}
                  </div>
                  :
                  <div className="ml-2">
                    {moment(orderDetails[0]?.DATE).format('ddd, DD MMM')}
                  </div>
                </div>
                <div className="text-sm flex">
                  <div className="w-[100px] t:w-[150px]  font-semibold">
                    Order ID{' '}
                  </div>
                  :
                  <div className="ml-2">
                    {orderDetails[0]?.BOOKING_ORDER_NO} - [
                    {orderDetails[0]?.MRC_NO} ]
                  </div>
                </div>
                <div className="text-sm flex">
                  <div className="w-[100px] t:w-[150px]  font-semibold">
                    Customer{' '}
                  </div>
                  :
                  <div className="ml-2 t:flex">
                    <div className="overflow-hidden ">
                      {orderDetails[0]?.CUSTOMER_NAME}
                    </div>
                    <div>[ {orderDetails[0]?.MOBILE} ]</div>
                  </div>
                </div>

                <div className="text-sm flex">
                  <div className="w-[100px] t:w-[150px]  font-semibold">
                    Item{' '}
                  </div>
                  :<div className="ml-2">{orderDetails[0]?.ITEM_NAME}</div>
                  <div className="ml-1 ">
                    {orderDetails[0]?.UPPER_LOWER === 'UPPER' ? '👕' : '👖'}
                  </div>
                </div>

                <div className="text-sm flex">
                  <div className="w-[100px] t:w-[150px]  font-semibold text-red text-[15px]">
                    Current Status{' '}
                  </div>
                  :
                  <div className="ml-2 text-red text-[15px]">
                    {currentStatusOrder[0]?.DEPARTMENT_NAME}
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: 'calc(100% - 250px)',
                }}
                className="w-full mt-3 overflow-y-auto  flex justify-start px-6"
              >
                <Stepper
                  activeStep={Math.floor(count)}
                  orientation="vertical"
                  sx={{
                    width: '100%',
                  }}
                >
                  {depList.map((de, i) => {
                    const steps = orderDetails.filter(
                      (o) => o.DEPARTMENT_NAME === de.DEPARTMENT_NAME
                    );

                    const step = steps[0];
                    const index = i + 1;
                    const dep = de.DEPARTMENT_NAME.toLowerCase();
                    const typeOfStage = dep.includes('wa')
                      ? washing
                      : dep.includes('cu')
                      ? cutting
                      : dep.includes('emb')
                      ? embroidery
                      : dep.includes('st')
                      ? sttiching
                      : dep.includes('bu')
                      ? buttonstype
                      : dep.includes('pr')
                      ? press
                      : dep.includes('re')
                      ? ready
                      : dep.includes('del')
                      ? delivery
                      : sttiching;

                    return (
                      <div key={i} className="flex w-full">
                        <div className="flex w-[60px]">
                          <div className="flex items-center flex-col ">
                            <div className="  w-full flex justify-center ">
                              <i
                                className={`bi py-1 ${
                                  step ? 'text-green' : 'text-gray-200'
                                } bi-check-circle-fill`}
                              ></i>
                            </div>

                            {!de?.DEPARTMENT_NAME.toLowerCase().includes(
                              'del'
                            ) && (
                              <div className="  ">
                                {[...Array(width > 1023 ? 5 : 9)].map(
                                  (o, i) => {
                                    const ind = i + 1;
                                    const inde = index + ind / 5;

                                    return (
                                      <div key={i} className="">
                                        <i
                                          className={`py-1 flex ${
                                            !step
                                              ? 'text-gray-100 '
                                              : 'text-gray-300 '
                                          } justify-center text-[3px] bi-circle-fill`}
                                        ></i>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                          <div className="mt-[2px] ml-1">
                            {de?.DEPARTMENT_NAME ===
                            currentStatusOrder[0]?.DEPARTMENT_NAME
                              ? step?.UPPER_LOWER === 'UPPER'
                                ? '👕'
                                : '👖'
                              : ''}
                          </div>
                        </div>

                        <div className=" flex flex-1  justify-between  h-[140px] t:h-fit">
                          <div className="flex items-cenetr ">
                            <div className=" mr-2">
                              <img
                                src={typeOfStage}
                                className="w-[28px] mt-1"
                              />
                            </div>
                            <div>
                              <div
                                className={`${
                                  step
                                    ? 'font-semibold'
                                    : 'text-gray-400 font-semibold'
                                }`}
                              >
                                {de?.DEPARTMENT_NAME}
                              </div>
                              <div className="text-sm text-gray-600">
                                {step && (
                                  <div className="t:flex">
                                    <div className="max-sm:hidden t:block">
                                      {step.DEPARTMENT_NAME}
                                    </div>
                                    {currentStatusOrder[0]?.DEPARTMENT_NAME !==
                                    step?.DEPARTMENT_NAME ? (
                                      <div className="t:flex t:ml-1">
                                        <div>was completed by</div>
                                        <div className="font-semibold t:ml-1 mr-1  ">
                                          {' '}
                                          {step?.EMPLOYEE_NAME}{' '}
                                        </div>
                                        <div>
                                          Contact : {step?.EMPLOYEE_MOBILE}
                                        </div>
                                        <div className="t:ml-1">
                                          on{' '}
                                          {moment(step?.DATE).format(
                                            'ddd, DD MMM'
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="t:flex t:ml-1">
                                        <div>is currently being handled by</div>
                                        <div className="font-semibold t:ml-1 mr-1  ">
                                          {' '}
                                          {step?.CURRENT_EMPLOYEE}{' '}
                                        </div>
                                        Contact : {step?.CURRENT_EMP_MOBILE} on{' '}
                                        {moment(step?.DATE).format(
                                          'ddd, DD MMM'
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {/* {step
                                  ? `${step?.DEPARTMENT_NAME} ${
                                      currentStatusOrder[0]?.DEPARTMENT_NAME !==
                                      step?.DEPARTMENT_NAME
                                        ? `was completed by ${step?.EMPLOYEE_NAME}  Contact : ${step?.EMPLOYEE_MOBILE}`
                                        : `is currently being handled by ${step?.CURRENT_EMPLOYEE}  Contact : ${step?.CURRENT_EMP_MOBILE}`
                                    } on ${moment(step?.DATE).format(
                                      'ddd, DD MMM'
                                    )}`
                                  : null} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Stepper>
              </div>
              <div className="flex justify-end mt-2 px-2">
                <BackButton onClick={handleClose} />
              </div>
            </div>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};
const SkeletonLoader = () => {
  return (
    <div className="h-full overflow-y-auto">
      {[...Array(7)].map((_, index) => (
        <div key={index} mb={2}>
          <Skeleton variant="rectangular" height={30} width="140px" />
          {[...Array(Math.floor(Math.random() * 3) + 1)].map(() => {
            return (
              <div className="flex">
                <div className="w-[100px] flex justify-center text-center ">
                  <Skeleton variant="rectangular" height={80} width="3px" />
                </div>
                <div>
                  <div className="mt-2">
                    <Skeleton variant="text" height={20} width="200px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="150px" />
                  </div>
                  <div className="">
                    <Skeleton variant="text" height={10} width="100px" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default OrderStatusModal;
