import moment from 'moment';

export const HistoryColumns = (width) => {
  const c = [
    {
      width: 60,
      headerAlign: 'center',
      align: 'left',
      field: 'PREFIX',
      accessor: 'PREFIX',
      headerName: 'PREFIX',
      renderHeader: (params) => <div>Prefix</div>,
    },
    {
      width: 140,
      headerAlign: 'center',
      align: 'left',
      field: 'BOOKING_ORDER_NO',
      accessor: 'BOOKING_ORDER_NO',
      headerName: 'BOOKING_ORDER_NO',
      renderHeader: (params) => <div>Booking Order No.</div>,
    },
    {
      width: 100,
      headerAlign: 'center',
      align: 'left',
      field: 'DATE',
      accessor: 'DATE',
      renderCell: (o) => {
        return (
          <div className="text-gray-700">
            {moment(o.value).format('DD-MM-YYYY')}
          </div>
        );
      },
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width: 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ITEM_NAME',
      accessor: 'ITEM_NAME',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width: 70,
      headerAlign: 'center',
      align: 'left',
      accessor: 'QNTY',
      renderHeader: (params) => <div>Qnty</div>,
    },

    {
      field: 'CUTTING_DATE',
      accessor: 'CUTTING_DATE',
      renderHeader: (params) => <div>Cutting Date</div>,
      width: 100,
      headerAlign: 'center',
      renderCell: (o) => {
        return (
          <div className="text-gray-700">
            {o.value && moment(o.value).format('DD-MM-YYYY')}
          </div>
        );
      },
      align: 'left',
    },
    {
      field: 'TRY_DATE',
      accessor: 'TRY_DATE',
      renderHeader: (params) => <div>Try Date</div>,
      renderCell: (o) => {
        return (
          <div className="text-gray-700">
            {o.value && moment(o.value).format('DD-MM-YYYY')}
          </div>
        );
      },
      width: 100,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'DEL_DATE',
      accessor: 'DEL_DATE',
      renderHeader: (params) => <div>Del. Date</div>,
      renderCell: (o) => {
        return (
          <div className="text-gray-700">
            {o.value && moment(o.value).format('DD-MM-YYYY')}
          </div>
        );
      },
      width: 100,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'INVNO',
      accessor: 'INVNO',
      renderHeader: (params) => <div>Bill No.</div>,
      width: 100,
      headerAlign: 'center',
      align: 'left',
    },
  ];
  return c;
};
