import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const Billing = createSlice({
  name: 'billing',
  initialState: {
    billingCart: [],
    billSettlement: {
      cashAmount: '',
      btcAmount: '',
      epaymentAmount: '',
      epaymentType: '',
      cardAmount: '',
      cardNumber: '',
      Remarks: '',
      clothAmount: '',
      DISCOUNT_RATE: 0,
      DISCOUNT_AMOUNT: 0,
    },
    billingAdvanceAmt: '',
    billingDiscount: '0',
    modifyBillCartList: [],
    advanceVoucherDetails: '',
    viewBillMod: false,
    viewBillInvoiceId: '',
  },
  reducers: {
    setBillingCartList(state, action) {
      state.billingCart = action.payload;
    },
    setViewBillMod(state, action) {
      state.viewBillMod = action.payload;
    },
    setViewBillInvoiceId(state, action) {
      state.viewBillInvoiceId = action.payload;
    },
    setAdvanceVoucherDetails(state, action) {
      state.advanceVoucherDetails = action.payload;
    },
    setModifyBillcartList(state, action) {
      state.modifyBillCartList = action.payload;
    },
    setBillingDicount(state, action) {
      state.billingDiscount = action.payload;
    },
    setBillSettlement(state, action) {
      state.billSettlement = action.payload;
    },
    setBillingAdvanceAmount(state, action) {
      state.billingAdvanceAmt = action.payload;
    },
  },
});

export default Billing.reducer;
export const {
  setBillingCartList,
  setBillSettlement,
  setViewBillMod,
  setViewBillInvoiceId,
  setAdvanceVoucherDetails,
  setModifyBillcartList,
  setBillingDicount,
  setBillingAdvanceAmount,
} = Billing.actions;
export const selectBillingCartList = (state) => state.billing.billingCart;
export const selectBillSettlement = (state) => state.billing.billSettlement;
export const selectBillingDiscountP = (state) => state.billing.billingDiscount;
export const selectBillingAdvanceAmount = (state) =>
  state.billing.billingAdvanceAmt;
export const selectModifyBillingCartlist = (state) =>
  state.billing.modifyBillCartList;

export const selectAdvanceVoucherDetails = (state) =>
  state.billing.advanceVoucherDetails;
export const selectBillModStatus = (state) => state.billing.viewBillMod;

export const selectBillInvoiceId = (state) => state.billing.viewBillInvoiceId;
