import React from 'react';

import axios from 'axios';
import {
  setItemDetails,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setRemarks,
  setSelectedBooingLowerItemDetails,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
} from '../utility/Redux/booking';
import {
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
} from '../utility/Redux/modal';
import moment from 'moment';

const GetItemDetails = (token, dispatch, prefix) => {
  return new Promise(function (resolve, reject) {
    const data = {
      table_prefix_master: prefix,
      SECTION_NAME: '',
      ICODE: '',
      ITEM_NAME: '',
    };

    dispatch(setSpinnerLoading('Loading items'));
    axios
      .post(`/api/masters/listItemMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response?.data?.error) {
          // setItemDetails(response.data.response);
          dispatch(setItemDetails(response.data.response));
          resolve(response.data.response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};

const getUpperMeasuremntDetails = (
  MasterPrifix,
  token,
  dispatch,
  bookingCustomerDetails,
  prefix,
  mrcNumber,
  selectedUpperItemDetails,
  familyMemberName,
  trydate,
  deliverydate,
  remarks,
  bookingDate,
  cusMobile_2,
  cusMobile_3,
  cusMobile,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  lowerList,
  customerPrefix,
  triggerAnimationUpper
) => {
  dispatch(setSpinnerLoading('Loading measurements'));

  axios
    .post(
      `/api/masters/listMeasurementMaster`,
      {
        table_prefix_master: MasterPrifix,
        UPPER_LOWER: 'UPPER',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      if (response.data.status === 200) {
        const emptyParametr = response.data.response;
        if (mrcNumber) {
          const data = {
            table_prefix: prefix,
            PREFIX: customerPrefix,
            MRC_NO: mrcNumber,
            ICODE: selectedUpperItemDetails.icode,
          };
          axios
            .post(`/api/booking/searchLastMeasurement`, data, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((e) => {
              if (!e.data.error) {
                const data = e.data.response;

                const upperData = data.filter((f) => f.UPPER_LOWER === 'UPPER');

                const isOldSizeAvailvle = upperData.filter(
                  (o) => o.SIZE !== ''
                );
                if (isOldSizeAvailvle[0]) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    title: 'Last Measurement',
                    msg: 'Do you want to load last upper parameter ?',
                    status: true,
                    button1: 'Yes',
                    button2: 'No',
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      let rowList = [];

                      for (
                        let index = 0;
                        index < emptyParametr.length;
                        index++
                      ) {
                        const isParameterAvailable = upperData.filter(
                          (e) => e.PARAMETER === emptyParametr[index].PARAMETER
                        );
                        if (isParameterAvailable[0]) {
                          rowList.push({
                            ...emptyParametr[index],
                            size: isParameterAvailable[0].SIZE,
                          });
                        } else {
                          rowList.push({
                            ...emptyParametr[index],
                            size: '',
                          });
                        }
                      }

                      dispatch(setUpperMeasuremnt(rowList));
                      const upperList = [];
                      rowList.map((e) => {
                        upperList.push({
                          BOOKING_ORDER_NO: '',
                          INVNO: '0',
                          DATE: moment(bookingDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ),
                          MRC_NO: mrcNumber,
                          LOWER_ICODE: selectedUpperItemDetails.lower_icode,
                          ICODE: selectedUpperItemDetails.icode,
                          ITEM_NAME: selectedUpperItemDetails.item_name,
                          PARAMETER: e.PARAMETER,
                          SIZE: e.size,

                          REGIONAL_INAME:
                            selectedUpperItemDetails.regional_iname,
                          SECTION_NAME: selectedUpperItemDetails.section_name,
                          UPPER_LOWER: selectedUpperItemDetails.upper_lower,
                          CODE: bookingCustomerDetails?.code
                            ? bookingCustomerDetails.code
                            : '',
                          CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                          MOBILE: bookingCustomerDetails?.mobile
                            ? bookingCustomerDetails?.mobile
                            : '',
                          MOBILE_2: bookingCustomerDetails?.mobile_2
                            ? bookingCustomerDetails?.mobile_2
                            : '',
                          MOBILE_3: bookingCustomerDetails?.mobile_3
                            ? bookingCustomerDetails?.mobile_3
                            : '',
                          FAMILY_MEM_NAME:
                            bookingCustomerDetails?.family_mem_name
                              ? bookingCustomerDetails?.family_mem_name
                              : '',
                          PHONE: bookingCustomerDetails?.company_phone
                            ? bookingCustomerDetails?.company_phone
                            : '',
                          ADDRESS: bookingCustomerDetails?.address
                            ? bookingCustomerDetails.address
                            : '',
                          CITY: bookingCustomerDetails?.city
                            ? bookingCustomerDetails?.city
                            : '',
                          STATE: bookingCustomerDetails?.state
                            ? bookingCustomerDetails?.state
                            : '',
                          COUNTRY: bookingCustomerDetails?.country
                            ? bookingCustomerDetails?.country
                            : '',
                          TRY_DATE: trydate
                            ? moment(trydate).format('YYYY-MM-DD')
                            : '',
                          DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                          REMARKS: isOldSizeAvailvle[0].REMARKS,
                        });
                      });
                      dispatch(setUpperMeasuremntList(upperList));
                      dispatch(
                        setRemarks({
                          ...remarks,
                          upper: isOldSizeAvailvle[0].REMARKS,
                        })
                      );
                      triggerAnimationUpper();
                      if (
                        selectedUpperItemDetails.lower_icode &&
                        selectedUpperItemDetails.lower_icode > 0
                      ) {
                        const loweritem = lowerList.filter(
                          (i) =>
                            i.icode === selectedUpperItemDetails.lower_icode
                        );
                        dispatch(
                          setSelectedBooingLowerItemDetails(loweritem[0])
                        );
                      }
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      let rowList = [];
                      for (
                        let index = 0;
                        index < emptyParametr.length;
                        index++
                      ) {
                        rowList.push({
                          ...emptyParametr[index],
                          size: '',
                        });
                      }
                      dispatch(setUpperMeasuremnt(rowList));
                      const upperList = [];
                      rowList.map((e) => {
                        upperList.push({
                          BOOKING_ORDER_NO: '',
                          INVNO: '0',
                          DATE: moment(bookingDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ),
                          MRC_NO: mrcNumber,
                          ICODE: selectedUpperItemDetails.icode,
                          LOWER_ICODE: selectedUpperItemDetails.lower_icode,

                          ITEM_NAME: selectedUpperItemDetails.item_name,
                          PARAMETER: e.PARAMETER,
                          SIZE: e.size,

                          REGIONAL_INAME:
                            selectedUpperItemDetails.regional_iname,
                          SECTION_NAME: selectedUpperItemDetails.section_name,
                          UPPER_LOWER: selectedUpperItemDetails.upper_lower,
                          CODE: bookingCustomerDetails?.code
                            ? bookingCustomerDetails.code
                            : '',
                          CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                          MOBILE: bookingCustomerDetails?.mobile
                            ? bookingCustomerDetails?.mobile
                            : '',
                          MOBILE_2: bookingCustomerDetails?.mobile_2
                            ? bookingCustomerDetails?.mobile_2
                            : '',
                          MOBILE_3: bookingCustomerDetails?.mobile_3
                            ? bookingCustomerDetails?.mobile_3
                            : '',
                          FAMILY_MEM_NAME:
                            bookingCustomerDetails?.family_mem_name
                              ? bookingCustomerDetails?.family_mem_name
                              : '',
                          PHONE: bookingCustomerDetails?.company_phone
                            ? bookingCustomerDetails?.company_phone
                            : '',
                          ADDRESS: bookingCustomerDetails?.address
                            ? bookingCustomerDetails.address
                            : '',
                          CITY: bookingCustomerDetails?.city
                            ? bookingCustomerDetails?.city
                            : '',
                          STATE: bookingCustomerDetails?.state
                            ? bookingCustomerDetails?.state
                            : '',
                          COUNTRY: bookingCustomerDetails?.country
                            ? bookingCustomerDetails?.country
                            : '',
                          TRY_DATE: trydate
                            ? moment(trydate).format('YYYY-MM-DD')
                            : '',
                          DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                          REMARKS: remarks.upper,
                        });
                      });
                      dispatch(setUpperMeasuremntList(upperList));
                      triggerAnimationUpper();

                      if (
                        selectedUpperItemDetails.lower_icode &&
                        selectedUpperItemDetails.lower_icode > 0
                      ) {
                        const loweritem = lowerList.filter(
                          (i) =>
                            i.icode === selectedUpperItemDetails.lower_icode
                        );
                        dispatch(
                          setSelectedBooingLowerItemDetails(loweritem[0])
                        );
                      }
                    },
                  });
                } else {
                  let rowList = [];
                  for (let index = 0; index < emptyParametr.length; index++) {
                    rowList.push({
                      ...emptyParametr[index],
                      size: '',
                    });
                  }
                  dispatch(setUpperMeasuremnt(rowList));
                  const upperList = [];
                  rowList.map((e) => {
                    upperList.push({
                      BOOKING_ORDER_NO: '',
                      INVNO: '0',
                      DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
                      MRC_NO: mrcNumber,
                      ICODE: selectedUpperItemDetails.icode,
                      ITEM_NAME: selectedUpperItemDetails.item_name,
                      PARAMETER: e.PARAMETER,
                      SIZE: e.size,
                      LOWER_ICODE: selectedUpperItemDetails.lower_icode,

                      REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
                      SECTION_NAME: selectedUpperItemDetails.section_name,
                      UPPER_LOWER: selectedUpperItemDetails.upper_lower,
                      CODE: bookingCustomerDetails?.code
                        ? bookingCustomerDetails.code
                        : '',
                      CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                      MOBILE: bookingCustomerDetails?.mobile
                        ? bookingCustomerDetails?.mobile
                        : '',
                      MOBILE_2: bookingCustomerDetails?.mobile_2
                        ? bookingCustomerDetails?.mobile_2
                        : '',
                      MOBILE_3: bookingCustomerDetails?.mobile_3
                        ? bookingCustomerDetails?.mobile_3
                        : '',
                      FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                        ? bookingCustomerDetails?.family_mem_name
                        : '',
                      PHONE: bookingCustomerDetails?.company_phone
                        ? bookingCustomerDetails?.company_phone
                        : '',
                      ADDRESS: bookingCustomerDetails?.address
                        ? bookingCustomerDetails.address
                        : '',
                      CITY: bookingCustomerDetails?.city
                        ? bookingCustomerDetails?.city
                        : '',
                      STATE: bookingCustomerDetails?.state
                        ? bookingCustomerDetails?.state
                        : '',
                      COUNTRY: bookingCustomerDetails?.country
                        ? bookingCustomerDetails?.country
                        : '',
                      TRY_DATE: trydate
                        ? moment(trydate).format('YYYY-MM-DD')
                        : '',
                      DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                      REMARKS: remarks.upper,
                    });
                  });
                  triggerAnimationUpper();

                  dispatch(setUpperMeasuremntList(upperList));
                  if (
                    selectedUpperItemDetails.lower_icode &&
                    selectedUpperItemDetails.lower_icode > 0
                  ) {
                    const loweritem = lowerList.filter(
                      (i) => i.icode === selectedUpperItemDetails.lower_icode
                    );
                    dispatch(setSelectedBooingLowerItemDetails(loweritem[0]));
                  }
                }
              }
            })

            .catch((error) => {
              console.log(error);

              if (error.response.data.name === 'TokenExpiredError') {
                dispatch(setSessionExpired(true));
              } else {
                dispatch(setAlertMsg('Something went wrong'));
                dispatch(setALertStatus(true));
              }
            })
            .finally(() => {
              dispatch(setSpinnerLoading(false));
            });
        } else {
          dispatch(
            setUpperMeasuremnt(
              emptyParametr.map((e) => {
                return {
                  ...e,
                  size: '',
                };
              })
            )
          );

          if (
            selectedUpperItemDetails.lower_icode &&
            selectedUpperItemDetails.lower_icode > 0
          ) {
            const loweritem = lowerList.filter(
              (i) => i.icode === selectedUpperItemDetails.lower_icode
            );
            dispatch(setSelectedBooingLowerItemDetails(loweritem[0]));
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: response.message,
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);

      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(setAlertMsg('Something went wrong'));
        dispatch(setALertStatus(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
const getEmptyUpperMeasuremntDetails = (
  MasterPrifix,
  token,
  dispatch,
  bookingCustomerDetails,
  prefix,
  mrcNumber,
  selectedUpperItemDetails,
  familyMemberName,
  trydate,
  deliverydate,
  remarks,
  bookingDate,
  cusMobile_2,
  cusMobile_3,
  cusMobile
) => {
  dispatch(setSpinnerLoading('loading'));
  axios
    .post(
      `/api/masters/listMeasurementMaster`,
      {
        table_prefix_master: MasterPrifix,
        UPPER_LOWER: 'UPPER',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      if (response.data.status === 200) {
        const emptyParametr = response.data.response;
        let rowList = [];
        for (let index = 0; index < emptyParametr.length; index++) {
          rowList.push({
            ...emptyParametr[index],
            size: '',
          });
        }
        dispatch(setUpperMeasuremnt(rowList));
        const upperList = [];
        rowList.map((e) => {
          upperList.push({
            BOOKING_ORDER_NO: '',
            INVNO: '0',
            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: mrcNumber,
            ICODE: selectedUpperItemDetails.icode,
            ITEM_NAME: selectedUpperItemDetails.item_name,
            PARAMETER: e.PARAMETER,
            SIZE: e.size,
            LOWER_ICODE: selectedUpperItemDetails.lower_icode,

            REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
            SECTION_NAME: selectedUpperItemDetails.section_name,
            UPPER_LOWER: selectedUpperItemDetails.upper_lower,
            CODE: bookingCustomerDetails?.code
              ? bookingCustomerDetails.code
              : '',
            CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
            MOBILE: bookingCustomerDetails?.mobile
              ? bookingCustomerDetails?.mobile
              : '',
            MOBILE_2: bookingCustomerDetails?.mobile_2
              ? bookingCustomerDetails?.mobile_2
              : '',
            MOBILE_3: bookingCustomerDetails?.mobile_3
              ? bookingCustomerDetails?.mobile_3
              : '',
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            PHONE: bookingCustomerDetails?.company_phone,
            ADDRESS: bookingCustomerDetails?.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails?.city
              ? bookingCustomerDetails?.city
              : '',
            STATE: bookingCustomerDetails?.state
              ? bookingCustomerDetails?.state
              : '',
            COUNTRY: bookingCustomerDetails?.country
              ? bookingCustomerDetails?.country
              : '',
            TRY_DATE: trydate ? moment(trydate).format('YYYY-MM-DD') : '',
            DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
            REMARKS: remarks,
          });
        });
        // triggerAnimationUpper();

        dispatch(setUpperMeasuremntList(upperList));
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: response.message,
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);

      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(setAlertMsg('Something went wrong'));
        dispatch(setALertStatus(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

const getCustomerListByNameOrMobile = async (
  prefix,
  token,
  dispatch,
  mobile,
  name
) => {
  dispatch(setSpinnerLoading('Loading'));
  const data = {
    table_prefix: prefix,
    CUSTOMER_NAME: name,
    MOBILE: mobile,
  };
  let result;
  const r = await axios
    .post('/api/booking/customerHelp', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((p) => {
      result = p;

      return p;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
      return {
        data: {
          response: [],
        },
      };
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
  return r;
};

const getCustomerMasterAPI = async (
  prefix,
  token,
  dispatch,
  mobile,
  name,
  address,
  city,
  state,
  country
) => {
  dispatch(setSpinnerLoading('Loading'));
  const data = {
    table_prefix: prefix,
    CUSTOMER_NAME: name ? name : '',
    MOBILE: mobile ? mobile : '',
    CODE: '',
    ADDRESS: address ? address : '',
    CITY: city ? city : '',
    STATE: state ? state : '',
    COUNTRY: country ? country : '',
  };
  let result;
  const r = await axios
    .post('/api/masters/listCustomerMaster', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((p) => {
      result = p;

      return p;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
      return {
        data: {
          response: [],
        },
      };
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
  return r;
};
const getLowerMeasuremntDetails = (
  MasterPrifix,
  token,
  dispatch,
  bookingCustomerDetails,
  prefix,
  mrcNumber,
  selectedLowerItemDetails,
  familyMemberName,
  trydate,
  deliverydate,
  remarks,
  bookingDate,
  cusMobile_2,
  cusMobile_3,
  cusMobile,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  selectedUpperItemDetails,
  customerPrefix,
  triggerAnimationLower
) => {
  dispatch(setSpinnerLoading('Loading Measuremnet'));
  axios
    .post(
      `/api/masters/listMeasurementMaster`,
      {
        table_prefix_master: MasterPrifix,
        UPPER_LOWER: 'LOWER',
      },

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      if (response.data.status === 200) {
        const emptyParametr = response.data.response;

        if (mrcNumber) {
          axios
            .post(
              `/api/booking/searchLastMeasurement`,
              {
                table_prefix: prefix,
                MRC_NO: mrcNumber,
                PREFIX: customerPrefix,
                ICODE: selectedLowerItemDetails.icode,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((e) => {
              if (!e.data.error) {
                const data = e.data.response;

                const lowerData = data.filter((f) => f.UPPER_LOWER === 'LOWER');
                const isOldSizeAvailvle = lowerData.filter(
                  (o) => o.SIZE !== ''
                );
                if (isOldSizeAvailvle[0]) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    title: 'Last Measurement',
                    msg: 'Do you want to load last lower parameter ?',
                    status: true,
                    button1: 'Yes',
                    button2: 'No',
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      let rowList = [];

                      for (
                        let index = 0;
                        index < emptyParametr.length;
                        index++
                      ) {
                        const isParameterAvailable = lowerData.filter(
                          (e) => e.PARAMETER === emptyParametr[index].PARAMETER
                        );
                        if (isParameterAvailable[0]) {
                          rowList.push({
                            ...emptyParametr[index],
                            size: isParameterAvailable[0].SIZE,
                          });
                        } else {
                          rowList.push({
                            ...emptyParametr[index],
                            size: '',
                          });
                        }
                      }

                      dispatch(setLowerMeasuremnt(rowList));
                      const lowerList = [];
                      rowList.map((e) => {
                        console.log(e);
                        lowerList.push({
                          BOOKING_ORDER_NO: '',
                          INVNO: '0',
                          DATE: moment(bookingDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ),
                          MRC_NO: mrcNumber,
                          ICODE: selectedLowerItemDetails.icode,
                          ITEM_NAME: selectedLowerItemDetails.item_name,
                          PARAMETER: e.PARAMETER,
                          SIZE: e.size,
                          LOWER_ICODE: selectedUpperItemDetails
                            ? selectedUpperItemDetails.lower_icode
                            : 0,
                          REGIONAL_INAME:
                            selectedLowerItemDetails.regional_iname,
                          SECTION_NAME: selectedLowerItemDetails.section_name,
                          UPPER_LOWER: selectedLowerItemDetails.upper_lower,
                          CODE: bookingCustomerDetails?.code
                            ? bookingCustomerDetails.code
                            : '',
                          CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                          MOBILE: bookingCustomerDetails?.mobile
                            ? bookingCustomerDetails?.mobile
                            : '',
                          MOBILE_2: bookingCustomerDetails?.mobile_2
                            ? bookingCustomerDetails?.mobile_2
                            : '',
                          MOBILE_3: bookingCustomerDetails?.mobile_3
                            ? bookingCustomerDetails?.mobile_3
                            : '',
                          FAMILY_MEM_NAME:
                            bookingCustomerDetails?.family_mem_name
                              ? bookingCustomerDetails?.family_mem_name
                              : '',
                          PHONE: bookingCustomerDetails?.company_phone,
                          ADDRESS: bookingCustomerDetails?.address
                            ? bookingCustomerDetails.address
                            : '',
                          CITY: bookingCustomerDetails?.city
                            ? bookingCustomerDetails?.city
                            : '',
                          STATE: bookingCustomerDetails?.state
                            ? bookingCustomerDetails?.state
                            : '',
                          COUNTRY: bookingCustomerDetails?.country,
                          TRY_DATE: trydate
                            ? moment(trydate).format('YYYY-MM-DD')
                            : '',
                          DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                          REMARKS: isOldSizeAvailvle[0].REMARKS,
                        });
                      });
                      dispatch(setLowerMeasuremntList(lowerList));
                      triggerAnimationLower();
                      dispatch(
                        setRemarks({
                          ...remarks,
                          lower: isOldSizeAvailvle[0].REMARKS,
                        })
                      );
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      let rowList = [];
                      for (
                        let index = 0;
                        index < emptyParametr.length;
                        index++
                      ) {
                        rowList.push({
                          ...emptyParametr[index],
                          size: '',
                        });
                      }

                      dispatch(setLowerMeasuremnt(rowList));
                      const lowerList = [];
                      rowList.map((e) => {
                        lowerList.push({
                          BOOKING_ORDER_NO: '',
                          INVNO: '0',
                          DATE: moment(bookingDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ),
                          MRC_NO: mrcNumber,
                          ICODE: selectedLowerItemDetails.icode,
                          ITEM_NAME: selectedLowerItemDetails.item_name,
                          PARAMETER: e.PARAMETER,
                          SIZE: e.size,
                          LOWER_ICODE: selectedUpperItemDetails
                            ? selectedUpperItemDetails.lower_icode
                            : 0,
                          REGIONAL_INAME:
                            selectedLowerItemDetails.regional_iname,
                          SECTION_NAME: selectedLowerItemDetails.section_name,
                          UPPER_LOWER: selectedLowerItemDetails.upper_lower,
                          CODE: bookingCustomerDetails?.code
                            ? bookingCustomerDetails.code
                            : '',
                          CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                          MOBILE: bookingCustomerDetails?.mobile
                            ? bookingCustomerDetails?.mobile
                            : '',
                          MOBILE_2: bookingCustomerDetails?.mobile_2
                            ? bookingCustomerDetails?.mobile_2
                            : '',
                          MOBILE_3: bookingCustomerDetails?.mobile_3
                            ? bookingCustomerDetails?.mobile_3
                            : '',
                          FAMILY_MEM_NAME:
                            bookingCustomerDetails?.family_mem_name
                              ? bookingCustomerDetails?.family_mem_name
                              : '',
                          PHONE: bookingCustomerDetails?.company_phone,
                          ADDRESS: bookingCustomerDetails?.address
                            ? bookingCustomerDetails.address
                            : '',
                          CITY: bookingCustomerDetails?.city
                            ? bookingCustomerDetails?.city
                            : '',
                          STATE: bookingCustomerDetails?.state
                            ? bookingCustomerDetails?.state
                            : '',
                          COUNTRY: bookingCustomerDetails?.country,
                          TRY_DATE: trydate
                            ? moment(trydate).format('YYYY-MM-DD')
                            : '',
                          DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                          REMARKS: remarks.lower,
                        });
                      });
                      dispatch(setLowerMeasuremntList(lowerList));
                      triggerAnimationLower();
                    },
                  });
                } else {
                  let rowList = [];
                  for (let index = 0; index < emptyParametr.length; index++) {
                    rowList.push({
                      ...emptyParametr[index],
                      size: '',
                    });
                  }

                  dispatch(setLowerMeasuremnt(rowList));
                  const lowerList = [];
                  rowList.map((e) => {
                    lowerList.push({
                      BOOKING_ORDER_NO: '',
                      INVNO: '0',
                      DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
                      MRC_NO: mrcNumber,
                      ICODE: selectedLowerItemDetails.icode,
                      ITEM_NAME: selectedLowerItemDetails.item_name,
                      PARAMETER: e.PARAMETER,
                      SIZE: e.size,
                      LOWER_ICODE: selectedUpperItemDetails
                        ? selectedUpperItemDetails.lower_icode
                        : 0,
                      REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
                      SECTION_NAME: selectedLowerItemDetails.section_name,
                      UPPER_LOWER: selectedLowerItemDetails.upper_lower,
                      CODE: bookingCustomerDetails?.code
                        ? bookingCustomerDetails.code
                        : '',
                      CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
                      MOBILE: bookingCustomerDetails?.mobile
                        ? bookingCustomerDetails?.mobile
                        : '',
                      MOBILE_2: bookingCustomerDetails?.mobile_2
                        ? bookingCustomerDetails?.mobile_2
                        : '',
                      MOBILE_3: bookingCustomerDetails?.mobile_3
                        ? bookingCustomerDetails?.mobile_3
                        : '',
                      FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                        ? bookingCustomerDetails?.family_mem_name
                        : '',
                      PHONE: bookingCustomerDetails?.company_phone,
                      ADDRESS: bookingCustomerDetails?.address
                        ? bookingCustomerDetails.address
                        : '',
                      CITY: bookingCustomerDetails?.city
                        ? bookingCustomerDetails?.city
                        : '',
                      STATE: bookingCustomerDetails?.state
                        ? bookingCustomerDetails?.state
                        : '',
                      COUNTRY: bookingCustomerDetails?.country,
                      TRY_DATE: trydate
                        ? moment(trydate).format('YYYY-MM-DD')
                        : '',
                      DEL_DATE: moment(deliverydate).format('YYYY-MM-DD'),
                      REMARKS: remarks.lower,
                    });
                  });
                  dispatch(setLowerMeasuremntList(lowerList));
                  triggerAnimationLower();
                }
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              dispatch(setSpinnerLoading(false));
            });
        } else {
          dispatch(
            setLowerMeasuremnt(
              emptyParametr.map((e) => {
                return {
                  ...e,
                  size: '',
                };
              })
            )
          );
        }
      } else {
        alert(response.data.error);
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(setAlertMsg('Something went wrong'));
      dispatch(setALertStatus(true));
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export {
  GetItemDetails,
  getUpperMeasuremntDetails,
  getLowerMeasuremntDetails,
  getEmptyUpperMeasuremntDetails,
  // getEmptyLowerMeasuremntDetails,
  getCustomerListByNameOrMobile,
  getCustomerMasterAPI,
};
