import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { SubmitButton } from '../utility/component/buttons';
import {
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../utility/Redux/security';
import useCookies from 'react-cookie/cjs/useCookies';
import {
  selectFirebaseStatusField,
  selectTableMasterPrefix,
  selectUserCode,
  setCompany,
  setCompanyAddress,
  setFinancialYearDetails,
  setPrefix,
  setUserEmail,
  setUserName,
  setUserType,
  setmasterTablePrifx,
} from '../utility/Redux/profile';
import Gicon from '../utility/images/G_Icon.svg';

import tailingX_2 from '../utility/images/Tailoring_Text.svg';
import tailiringLogo from '../utility/images/tailiringLogo.svg';
import AlertModal2 from '../utility/component/modals/alertWithTitle';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { isFireBaseLoginOn } from '../utility/common';
import {
  detectIsAnyOtherUserTryToLogin,
  isAnyAlreadyOneLogin,
} from '../dashboard/func';

export const FinancialYear = () => {
  const dispatch = useDispatch();

  const [userDetails, setuserDetails] = useState('');
  // const [company, setCompany] = useState('');
  const navigate = useNavigate();
  //u
  const [selectedFinancialYear, setSelectedFinancialYear] = useState('');
  const [finacialYearDetails, setfinancialYear] = useState([]);
  const location = useLocation();
  const CompanyEmail = location?.state?.CompanyEmail;
  const UserEmail = location?.state?.UserEmail;
  const [companyDetail, setcompanyDetail] = useState([]);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const getCompanyList = () => {
    if (CompanyEmail) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const email = CompanyEmail;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      dispatch(setSpinnerLoading('Loading'));
      axios
        .post(
          `/api/users/userCompanyInfo`,
          {
            company_email: CompanyEmail,
            user_email: UserEmail,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            setcompanyDetail(data);
          } else {
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setAlertMsg('Something went wrong.'));
          dispatch(setALertStatus(true));
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    }
  };

  const getFinacialYear = (table_prefix, user_email, company_email) => {
    if ((table_prefix, user_email, company_email)) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(user_email, secretPass);
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const data = {
        table_prefix: table_prefix,
        user_email: user_email,
        company_email: company_email,
      };
      dispatch(setSpinnerLoading('Loading...'));
      axios
        .post(`/api/users/financialYear`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            setfinancialYear(data.response);
          } else {
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setAlertMsg('Something went wrong.'));
          dispatch(setALertStatus(true));
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const encryptEmail = (email) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();

    localStorage.setItem('email', data);
    dispatch(setUserEmail(email));
  };

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('prefix', data);
  };

  const encryptMasterPrefix = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('masterPrefix', data);
  };

  const encryptUserType = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('userType', data);
  };

  const encryptDevMode = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('dev', data);
  };

  const submitButton = () => {
    if (userDetails) {
      const company = companyDetail.filter(
        (c) => c.company_name === userDetails
      );
      if (selectedFinancialYear) {
        encryptEmail(UserEmail);

        dispatch(setLogin(true));
        dispatch(setUserName(company[0].user_name));
        dispatch(setCompany(company[0].company_name));
        dispatch(
          setPrefix(company[0].table_prefix + selectedFinancialYear.name + '_')
        );
        dispatch(setFinancialYearDetails(selectedFinancialYear));
        localStorage.setItem(
          'financial',
          JSON.stringify({
            ...selectedFinancialYear,
          })
        );
        localStorage.setItem(
          'companyDetails',
          JSON.stringify({
            address: company[0].address,
            city: company[0].city,
            state: company[0].state,
            mobile: company[0].company_mobile,
            email: company[0].company_email,
            companyName: company[0].company_name,
            companyWebsite: company[0].company_website,
            start_date: company[0].start_date,
            end_date: company[0].end_date,
          })
        );
        dispatch(
          setCompanyAddress({
            address: company[0].address,
            city: company[0].city,
            state: company[0].state,
            mobile: company[0].company_mobile,
            email: company[0].company_email,
            companyName: company[0].company_name,
            companyWebsite: company[0].company_website,
          })
        );
        encryptData(company[0].table_prefix + selectedFinancialYear.name + '_');
        encryptMasterPrefix(company[0].table_prefix);
        encryptUserType(company[0].user_type);
        encryptDevMode(company[0].developer_yn);
        localStorage.setItem('company', company[0].company_name);
        localStorage.setItem('userName', company[0].user_name);
        dispatch(setUserType(company[0].user_type));

        localStorage.removeItem('lastDate');
        navigate('/');
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Financial Year',
            msg: 'Please Select Financial Year',
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Company name',
          msg: 'Please Select Company Name',
        })
      );
    }
  };
  useEffect(() => {
    if (!CompanyEmail) {
      navigate('/auth/login');
    }
  }, []);
  const fin = localStorage.getItem('finacialYear');
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const usercode = useSelector(selectUserCode);
  const statusField = useSelector(selectFirebaseStatusField);
  useEffect(() => {
    const user = localStorage.getItem('userDetails');
    const userDetails = companyDetail.filter((t) => t.company_name === user);
    setuserDetails(user);
    if (user && userDetails[0]) {
      getFinacialYear(
        userDetails[0]?.table_prefix,
        userDetails[0]?.user_email,
        userDetails[0]?.company_email
      );
    }
  }, [companyDetail]);
  useEffect(() => {
    const year = finacialYearDetails.filter((d) => (d.year = fin));
    if (!selectedFinancialYear) {
      setSelectedFinancialYear(year[0]);
    }
  }, [finacialYearDetails]);
  const expireAt = () => {
    const data = localStorage.getItem('expiresAt');
    return data;
  };
  // useEffect(() => {
  //   if (
  //     masterPrefix &&
  //     usercode &&
  //     expireAt() &&
  //     !alertTwoButtonModal.status &&
  //     isFireBaseLoginOn
  //   ) {
  //     detectIsAnyOtherUserTryToLogin(
  //       masterPrefix,
  //       setAlertTwoButtonModal,
  //       alertTwoButtonModal,
  //       dispatch,
  //       navigate,
  //       usercode,
  //       statusField
  //     );
  //   }
  // }, []);

  // useEffect(() => {
  //   if (
  //     masterPrefix &&
  //     usercode &&
  //     expireAt() &&
  //     !alertTwoButtonModal.status &&
  //     isFireBaseLoginOn
  //   ) {
  //     isAnyAlreadyOneLogin(
  //       masterPrefix,
  //       setAlertTwoButtonModal,
  //       alertTwoButtonModal,
  //       dispatch,
  //       navigate,
  //       usercode,
  //       statusField
  //     );
  //   }
  // }, []);
  return (
    <div
      className="flex flex-col bg-white justify-center items-center text-lg"
      style={
        window.innerWidth <= 500
          ? { padding: '85px 0px' }
          : window.innerWidth <= 1300
          ? { padding: '150px 0px' }
          : { height: '100vh' }
      }
    >
      <div className="l:bg-white  flex rounded-sm  ">
        <div className="  w-auto border-r  justify-center items-center hidden t:flex   ">
          <div className="text-center mr-4  ">
            <div className="w-full flex justify-center  ">
              <img
                src={tailiringLogo}
                alt=""
                style={{
                  height: '150px',
                  width: '150px',
                }}
              />
            </div>
            <div className="w-full flex justify-center ">
              <img
                src={tailingX_2}
                alt=""
                style={{
                  width: '150px',
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            borderRadius: '4px',

            zIndex: '100',
          }}
          className=" px-4 bg-white  h-auto py-6  flex overflow-justify-center"
        >
          <div className=" w-full   ">
            <div className="flex w-full justify-center ">
              <div className="text-center t:hidden mb-4">
                <div className="w-full flex justify-center  ">
                  <img
                    src={tailiringLogo}
                    alt=""
                    style={{
                      height: '150px',
                      width: 'auto',
                    }}
                  />
                </div>
                <div className="w-full flex justify-center ">
                  <img
                    src={tailingX_2}
                    alt=""
                    style={{
                      width: '150px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: 20,
              }}
              className=" -mt-3  font-semibold   flex justify-center t:justify-start pl-1"
            >
              Company Login
            </div>
            <div className=" rounded-sm  ">
              <div className="overflow-hidden ">
                <div
                  style={{
                    width: '320px',
                  }}
                  className=" flex  justify-between rounded-sm items-center p-1 pt-3"
                >
                  <TextField
                    select
                    InputProps={{
                      sx: {
                        fontSize: '14px',
                        backgroundColor: 'white',
                        // Adjust the font size as per your preference
                        '& .MuiOutlinedInput-notchedOutline': {
                          // Remove the default outline color
                          border: '0.5px solid #6e6d6b',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#03a9f4', // Change to your desired color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '0.1px solid #03a9f4', // Change to your desired color on focus
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: '14px',
                      },
                    }}
                    label="Select Company"
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            maxWidth: 150,
                          },
                        },
                      },
                    }}
                    fullWidth
                    value={userDetails}
                    onChange={(event) => {
                      localStorage.setItem('userDetails', event.target.value);
                      setuserDetails(event.target.value);
                      const data = companyDetail.filter(
                        (item) => item.company_name === event.target.value
                      );
                      getFinacialYear(
                        data[0].table_prefix,
                        data[0].user_email,
                        data[0].company_email
                      );
                    }}
                  >
                    {companyDetail[0] &&
                      companyDetail.map((i, key) => {
                        return (
                          <MenuItem key={key} value={i.company_name}>
                            {i.company_name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>

                <div
                  style={{
                    marginTop: '5px',
                    width: '320px',
                  }}
                  className=" flex  justify-between rounded-sm items-center p-1"
                >
                  <TextField
                    select
                    InputProps={{
                      sx: {
                        fontSize: '14px',
                        backgroundColor: 'white',
                        // Adjust the font size as per your preference
                        '& .MuiOutlinedInput-notchedOutline': {
                          // Remove the default outline color
                          border: '0.5px solid #6e6d6b',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#03a9f4', // Change to your desired color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '0.1px solid #03a9f4', // Change to your desired color on focus
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: '14px',
                      },
                    }}
                    size="small"
                    id="Select Financial Year"
                    name="Select Financial Year"
                    label="Select Financial Year"
                    value={fin}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Adjust the height as needed
                            maxWidth: 150,
                          },
                        },
                      },
                    }}
                    fullWidth
                    onChange={(event) => {
                      localStorage.setItem('finacialYear', event.target.value);
                      const year = finacialYearDetails.filter(
                        (d) => (d.year = event.target.value)
                      );
                      setSelectedFinancialYear(year[0]);
                    }}
                  >
                    {finacialYearDetails[0] ? (
                      finacialYearDetails.map((i, key) => {
                        return (
                          <MenuItem key={key} value={i.year}>
                            {i.year}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value={' '}> </MenuItem>
                    )}

                    {/* Add more MenuItem components as needed */}
                  </TextField>
                </div>
                <div className="w-full pt-4 flex items-center justify-center ">
                  <SubmitButton onClick={submitButton} />
                </div>
                <AlertModal2 />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpinnerLoading />

      <div className="mt-10 text-[13px] w-full justify-center  text-brown flex">
        A product of{' '}
        <div className="pl-1 flex items-center">
          <img
            src={Gicon}
            style={{
              width: '14px',
              height: '14px',
            }}
            alt="TailoringX"
          />
        </div>
        <label className="font-semibold pl-1 pr-1"> genius office</label>
        2023 All rights reserved.
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};
