export const autoSaveCollectionInBilling = true;

export const phoneRegex = /^(\+\d{1,3}[- ]?)?(\d{1,4}[- ]?)*\d{1,4}$/;
export const mobileInput = /^[+\d\s-]*$/;
export const not_permisson_forWhastapp =
  'You have not permission to send whatsapp. contact manager';
export const customerPrefixGlobal = [
  'A',
  'A0',
  'AB',
  'AC',
  'AD',
  'AF',
  'AG',
  'US',
];
export const licenceExpired_alert =
  'Your license has expired. Please contact support@geniusoffice.com, +91 85589 51962 for renewal. Visit https://www.geniusoffice.com.';
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isFireBaseLoginOn = true;
