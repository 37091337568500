import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsCheckSquare } from 'react-icons/bs';
import axios from 'axios';
import { setToken } from '../utility/Redux/security';
import { useDispatch } from 'react-redux';
import { useFocus } from '../utility/hooks/hooks';
import talringX from '../utility/images/Tailoring_Text.svg';
import Gicon from '../utility/images/G_Icon.svg';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckEmailAlert,
  setloading,
} from '../utility/Redux/modal';
import AlertModal from '../utility/component/modals/alert';
import { Button, Modal } from '@mui/material';
import CheckEmailModall from '../utility/component/modals/resetPassword';
import TailringLogo from '../utility/images/tailiringLogo.svg';
import EmailVerify from '../utility/component/modals/verifyEmailModal';
import EmailVerified from '../utility/component/modals/emailVerified';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { UserLogin } from '../utility/component/buttons';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AlertModal2 from '../utility/component/modals/alertWithTitle';

import { emailRegex } from '../utility/common';
import { runLoginFunc } from './func';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';

export const Login = () => {
  const [remember, setRember] = useState(false);

  const [passRef, setPasswordFocus] = useFocus();
  const [hidden, sethidden] = useState(true);
  const [email, setEmailLogin] = useState('');
  const [password, setpassword] = useState('');
  const [forget, setForget] = useState(false);
  const [emailBordered, setEmailLoginBordered] = useState('gray');
  const [PasswordBordered, setPasswordBordered] = useState('gray');
  const move = useNavigate();
  const location = useLocation();
  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const initialValues = {
    Email: '',
    login_password: '',
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem('rememberd'));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem('rememberd');
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setEmailLogin(decEmail);
        setpassword(decPass);
        setRember(true);
      }
    } else {
      setEmailLogin('');
      setpassword('');
      setRember(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => sethidden(true), 1000);
    return () => clearTimeout(timer);
  }, [hidden]);

  useEffect(() => {
    localStorage.removeItem('userName');
    localStorage.removeItem('count');
    localStorage.removeItem('tkn');
    localStorage.removeItem('company');
    localStorage.removeItem('histryId');
    localStorage.removeItem('prefix');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('orderdItemList');
    localStorage.removeItem('userType');
    localStorage.removeItem('companyDetails');
  }, []);

  const loginApi = () => {
    if (emailRegex.test(email)) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      if (password.length >= 5) {
        runLoginFunc(
          dispatch,
          email,
          password,
          hmacString,
          secretPass,
          remember,
          move
        );
      } else {
        if (password) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Incorrect password',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Please enter password',
            })
          );
        }
      }
    } else {
      // invalid email, maybe show an error to the user.

      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Invalid email address.',
        })
      );
    }
  };

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(e)) {
      setForget(true);
    } else {
      dispatch(
        setAlertMsg('Enter valid user email address for change password')
      );
      dispatch(setALertStatus(true));
    }
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(emailCheck)) {
      dispatch(setloading(true));
      axios
        .post(
          '/api/users/userInfo',
          {
            user_email: emailCheck,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          }
        )
        .then((res) => {
          if (res.data.response[0]) {
            axios
              .post(
                `/api/users/resetUserPasswordEmail`,
                {
                  user_email: emailCheck,
                  user_name: res.data.response[0].user_name,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hmacString}`,
                  },
                }
              )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + ' ' + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setloading(false));
              });
          } else {
            dispatch(setAlertMsg('Not registered user'));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(setAlertMsg('Something wrong'));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Invalid email address.',
        })
      );
    }
  };
  useEffect(() => {
    if (indexedDB.databases) {
      indexedDB
        .databases()
        .then((databases) => {
          databases.forEach((dbInfo) => {
            if (dbInfo.name !== 'SaveIssueCart') {
              if (dbInfo.name !== 'Calculations') {
                indexedDB.deleteDatabase(dbInfo.name);
              }
            }
          });
        })
        .catch((error) => {
          console.error('Error listing databases:', error);
        });
    } else {
      // Fallback method for browsers that do not support indexedDB.databases()
      console.log('indexedDB.databases() is not supported in this browser.');
    }
    localStorage.setItem('bookingDate', moment().format('YYYY-MM-DD'));
  }, []);
  return (
    <div
      className="flex bg-white justify-center items-center text-[14px]"
      style={
        window.innerWidth <= 500
          ? { padding: '85px 0px' }
          : window.innerWidth <= 768
          ? { padding: '180px 0px' }
          : window.innerWidth <= 1025
          ? { padding: '125px 0px' }
          : window.innerWidth <= 1300
          ? { padding: '140px 0px' }
          : { height: '100vh' }
      }
    >
      <div
        style={{
          borderRadius: '4px',
        }}
        className=" w-full flex flex-col  h-[100%]   justify-center items-center"
      >
        <div>
          <div className="  h-[110%] t:hidden ">
            <div className="flex   h-full">
              <div className=" flex flex-col  justify-center items-center   h-full w-full ">
                <div
                  style={{
                    height: '150px',
                    width: '150px',
                  }}
                >
                  <img src={TailringLogo} alt="TailoringX" />
                </div>
                <div className="flex  justify-center  ">
                  <div className="  flex justify-center  ">
                    <div className="w-full   flex justify-center">
                      <div
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          height: 'auto',
                          width: 164,
                          backgroundColor: 'white',
                          borderRadius: 100,
                        }}
                      >
                        <img src={talringX} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  justify-center items-center">
          <div className="border-r mr-5 pr-5  h-[110%] hidden t:block ">
            <div className="flex   h-full">
              <div className=" flex flex-col  justify-center items-center   h-full w-full ">
                <div
                  style={{
                    height: '150px',
                    width: '150px',
                  }}
                >
                  <img src={TailringLogo} />
                </div>
                <div className="flex  justify-center  ">
                  <div className="  flex justify-center  ">
                    <div>
                      <div className="w-full   flex justify-center">
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            height: 'auto',
                            width: 164,
                            backgroundColor: 'white',
                            borderRadius: 100,
                          }}
                        >
                          <img src={talringX} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{ fontSize: 20 }}
              className="   flex justify-center t:justify-start font-semibold h-fit mt-3 t:mt-0 items-center"
            >
              Login
            </div>

            <div className="mt-2 ">
              <div>
                <div className=" flex justify-between  items-center">
                  <div
                  //  bbbbb
                  >
                    <div
                      style={{
                        border: `0.5px solid ${emailBordered}`,
                        borderRadius: '3px',
                        overflow: 'hidden',
                        width: '320px',
                      }}
                    >
                      <input
                        autoSave={initialValues.Email}
                        onFocus={() => setEmailLoginBordered('#383535')}
                        onBlur={() => setEmailLoginBordered('gray')}
                        size={'small'}
                        onKeyDown={(event) => {
                          if (
                            event.key === 'Enter' ||
                            event.key === 'NumpadEnter'
                          ) {
                            setPasswordFocus();
                          }
                        }}
                        style={{
                          fontSize: '15px',
                          borderRadius: '3px',
                          overflow: 'hideen',
                        }}
                        placeholder="Email Address"
                        name="Email"
                        id="Email"
                        onChange={(t) => {
                          setEmailLogin(t.target.value.toLowerCase());
                        }}
                        value={email}
                        className="outline-0 w-full py-2 pl-3 "
                      />
                    </div>
                  </div>
                </div>

                <div className=" justify-between  mt-3  items-center ">
                  <div className="flex items-center justify-between  ">
                    <div
                    // style={{
                    //   width:
                    //     window.innerWidth <= 768
                    //       ? window.innerWidth * 0.7
                    //       : window.innerWidth <= 1280
                    //       ? window.innerWidth * 0.22
                    //       : window.innerWidth * 0.15,
                    // }}
                    >
                      <div
                        style={{
                          border: `0.5px solid ${PasswordBordered}`,
                          borderRadius: '3px',
                          overflow: 'hideen',
                          width: '320px',
                        }}
                        className="flex w-full  items-center justify-between "
                      >
                        <input
                          size={'small'}
                          //  autoSave={remember}
                          onFocus={() => setPasswordBordered('#383535')}
                          onBlur={() => {
                            setPasswordBordered('gray');
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.code === 'Enter' ||
                              e.code === 'NumpadEnter'
                            ) {
                              loginApi();
                            }
                          }}
                          ref={passRef}
                          className="outline-0 bg-white w-full pl-3"
                          type={hidden ? 'password' : 'name'}
                          name="login_password"
                          id="login_password"
                          placeholder="Password"
                          // error={touched.login_password ? errors.login_password:null}
                          style={{
                            fontSize: '15px',
                            borderRadius: '3px',
                            overflow: 'hideen',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            outline: 'none',
                          }}
                          onChange={(p) => {
                            setpassword(p.target.value);
                          }}
                          value={password}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex  items-center justify-between mt-3">
                  <div className="flex items-center">
                    <div>
                      <label htmlFor="checkbox">
                        {!remember && (
                          <CheckBoxOutlineBlankIcon
                            size={12}
                            onClick={() => {
                              setRember(true);
                            }}
                            style={{
                              color: '#DBD6D6',
                            }}
                          />
                        )}
                        {remember && (
                          <BsCheckSquare
                            fill={'#f5874f'}
                            size={12}
                            onClick={() => {
                              setRember(false);
                            }}
                          />
                        )}
                      </label>
                    </div>{' '}
                    <div
                      style={{ fontSize: 12 }}
                      className=" text-slate-500 pl-1  flex whitespace-nowrap  items-center"
                    >
                      Remember me
                    </div>
                  </div>
                  {/* <div
                    style={{
                      fontSize: '11px',
                    }}
                    className="w-full text-slate-500 flex items-center justify-end pr-1 "
                  >
                    <div>
                      {hidden ? (
                        <button
                          className="text-slate-500"
                          fontSize={'small'}
                          onClick={() => {
                            sethidden(false);
                          }}
                        >
                          Show{' '}
                        </button>
                      ) : (
                        <button
                          fontSize={'small'}
                          className="text-slate-500"
                          onClick={() => {
                            sethidden(true);
                          }}
                        >
                          Hide
                        </button>
                      )}
                    </div>
                  </div> */}
                </div>

                <div
                  className="w-full mt-3 flex items-center justify-center "
                  style={{ width: '320px' }}
                >
                  <UserLogin
                    onClick={() => {
                      loginApi();
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-3 justify-between">
                <div
                  style={{
                    fontSize: '13px',
                  }}
                  className="flex  justify-center "
                >
                  New User ?{' '}
                  <button
                    onClick={() => {
                      move('/auth/signup');
                    }}
                  >
                    {' '}
                    <label className="pl-1  hover:cursor-pointer text-orange">
                      SignUp
                    </label>
                  </button>
                </div>
                <div className=" flex justify-end">
                  <button
                    onClick={() => {
                      if (email) {
                        forgetModel(email);
                      } else {
                        dispatch(
                          setAlertMsg(
                            'Please enter user email address for change password'
                          )
                        );
                        dispatch(setALertStatus(true));
                      }
                    }}
                  >
                    <div
                      style={{ fontSize: 13 }}
                      className="  flex justify-center whitespace-nowrap     text-slate-500"
                    >
                      Forgot
                      <label className="pl-1 font-medium  text-brown">
                        Password ?
                      </label>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 text-[13px] w-full justify-center text- text-brown flex">
          A product of{' '}
          <div className="pl-1 flex items-center">
            <img
              src={Gicon}
              style={{
                width: '14px',
                height: '14px',
              }}
            />
          </div>
          <label className="font-semibold pl-1 pr-1"> genius office</label>
          2023 All rights reserved.
        </div>
      </div>

      <Modal open={forget} onClose={() => setForget(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            height: 'auto',

            boxShadow: 24,
          }}
          className="rounded-md overflow-hidden"
        >
          <div className=" text-white flex justify-start font-normal py-2 pl-3    bg-brown ">
            genius office
          </div>
          <div className="justify-center bg-white py-3 ">
            <div className="flex  w-full px-5 pt-4   items-center ">
              <img
                src={require('../utility/images/ico.png')}
                className="mr-3 w-10 h-10 "
                alt=" "
              />
              <div>
                <div className="text-brown text-md  mb-4 ">
                  <div> Click Continue to reset your Password.</div>
                  <div>
                    Reset Password link has been sent on your Email :
                    <div className="font-semibold">{email}</div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  justify-end  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={() => {
                  forgetPassword(email);
                  setForget(false);
                }}
                color="secondary"
                style={{ marginRight: 4 }}
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Continue
                </div>
              </Button>

              <Button
                size={'small'}
                onClick={() => setForget(false)}
                color="secondary"
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Cancel
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <AlertModal />
      <CheckEmailModall />
      <EmailVerify email={location.state} />
      <EmailVerified />
      <AlertModal2 />
      <SpinnerLoading />
    </div>
  );
};
