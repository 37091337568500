import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { StyleColums } from './datagridCol';
import { MeasurmentStyleHeader } from '../utility/component/datagrid.js/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomerPrefix,
  selectEditType,
  selectLowerBookingItemDetails,
  selectLowerDate,
  selectLowerMeasuremnt,
  selectLowerMeasuremntList,
  selectLowerQnty,
  selectModifiedBookingStatus,
  selectModyingBookingID,
  selectRemarks,
  selectUpperBookingItemDetails,
  setEditType,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setLowerQnty,
  setRemarks,
  setSelectedBooingLowerItemDetails,
} from '../utility/Redux/booking';

import moment from 'moment';
import { selectToken } from '../utility/Redux/security';

import { GetItemDetails, getLowerMeasuremntDetails } from './list';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';

import { setAlertWithTitle } from '../utility/Redux/modal';
import { selectModifyBillingCartlist } from '../utility/Redux/billing';
import CapitalizeProperNouns from '../utility/component/functions/sentenceFormater';
import MeasuremtTags from './tags';
import OpenItemModal from '../help/openItemModal';
import { changeItemParameterLower } from './ItemDetails';
import { Skeleton } from '@mui/material';

const LowerStyle = ({
  SetItemTabScreen,
  mrcNumber,
  parametrInput,
  setParameterInput,
  setStatus,
  setStyleType,
  bookingDate,
  alertTwoButtonModal,
  setAlertTwoButtonModal,
  bookingCustomerDetails,
  triggerAnimationLower,
  animationWidthLower,
  isAnimatingLower,
}) => {
  const selectedLowerItemDetails = useSelector(selectLowerBookingItemDetails);
  const selectedUpperItemDetails = useSelector(selectUpperBookingItemDetails);
  const LowerMeasuremntDetails = useSelector(selectLowerMeasuremnt);
  const token = useSelector(selectToken);
  const [editCellValues, setEditCellValues] = useState({
    field: '',
    id: '',
    value: '',
  });
  const [openItemModalStatus, setOpenModalStatus] = useState(false);
  const modifiedOrderStatus = useSelector(selectModifiedBookingStatus);
  const SelectedLowerItem = useSelector(selectLowerBookingItemDetails);

  const LowerQnty = useSelector(selectLowerQnty);
  const modifiedBookingID = useSelector(selectModyingBookingID);
  const remarks = useSelector(selectRemarks);
  const lowerDate = useSelector(selectLowerDate);

  const width = window.innerWidth <= 1024 ? '20px' : '30px';
  const dispatch = useDispatch();
  const measurmentList = useSelector(selectLowerMeasuremntList);
  const modifyBillCartList = useSelector(selectModifyBillingCartlist);
  const editCell = ({ field, id, value }) => {
    const i = LowerMeasuremntDetails.findIndex((i) => i.CODE === id);

    const parameter = LowerMeasuremntDetails[i].PARAMETER;
    const PCODE = LowerMeasuremntDetails[i].CODE;
    const size = LowerMeasuremntDetails[i].size;
    const isParameter = measurmentList.some(
      (item) => item.PARAMETER === parameter
    );

    if (!size) {
      let data = LowerMeasuremntDetails.map((i, index) => {
        if (PCODE === i.CODE) {
          const row = {
            ...LowerMeasuremntDetails[index],
            size: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
          };

          return row;
        } else {
          if (parameter !== i.PARAMETER) {
            return LowerMeasuremntDetails[index];
          }
        }
      });

      dispatch(setLowerMeasuremnt(data));
    } else {
      if (size && value) {
        let list = LowerMeasuremntDetails;
        // let list2 = list.filter((item) => item.CODE !== PCODE);
        let list3 = list.map((item) => {
          if (item.CODE !== PCODE) {
            return item;
          } else {
            let data = {
              ...item,
              size: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
            };
            return data;
          }
        });
        // dispatch(
        //   setLowerMeasuremnt([
        //     ...list2,
        //     {
        //       ...LowerMeasuremntDetails[i],
        //       size: value,
        //     },
        //   ])
        // );
        dispatch(setLowerMeasuremnt(list3));
      } else {
        if (size && !value) {
          let list = LowerMeasuremntDetails;
          // let list2 = list.filter((item) => item.CODE !== PCODE);
          let list3 = list.map((item) => {
            if (item.CODE !== PCODE) {
              return item;
            } else {
              let data = {
                ...item,
                size: value
                  .replace(/\/2/g, '½')
                  .replace(/\/3/g, '¾')
                  .replace(/\/4/g, '¼')
                  .replace(/\//g, '½'),
              };
              return data;
            }
          });
          dispatch(setLowerMeasuremnt(list3));
        }
      }
    }

    if (!isParameter) {
      dispatch(
        setLowerMeasuremntList([
          ...measurmentList,
          {
            BOOKING_ORDER_NO: '',
            INVNO: '0',
            DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: mrcNumber,
            ICODE: selectedLowerItemDetails.icode,
            ITEM_NAME: selectedLowerItemDetails.item_name,
            PARAMETER: parameter,
            SIZE: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
            LOWER_ICODE: selectedUpperItemDetails
              ? selectedUpperItemDetails.lower_icode
              : 0,
            REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
            SECTION_NAME: selectedLowerItemDetails.section_name,
            UPPER_LOWER: selectedLowerItemDetails.upper_lower,
            CODE: bookingCustomerDetails?.code
              ? bookingCustomerDetails.code
              : '',
            CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
            MOBILE: bookingCustomerDetails?.mobile,
            MOBILE_2: bookingCustomerDetails?.mobile_2,
            MOBILE_3: bookingCustomerDetails?.mobile_3,
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            PHONE: bookingCustomerDetails?.company_phone,
            ADDRESS: bookingCustomerDetails?.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails?.city
              ? bookingCustomerDetails?.city
              : '',
            STATE: bookingCustomerDetails?.state
              ? bookingCustomerDetails?.state
              : '',
            COUNTRY: bookingCustomerDetails?.country,
            TRY_DATE: lowerDate?.try
              ? moment(lowerDate?.try).format('YYYY-MM-DD')
              : '',
            DEL_DATE: moment(lowerDate?.del).format('YYYY-MM-DD'),
            REMARKS: remarks?.lower,
          },
        ])
      );
    } else {
      if (isParameter && value) {
        let list = measurmentList;
        list = list.filter((item) => item.PARAMETER !== parameter);

        dispatch(
          setLowerMeasuremntList([
            ...list,
            {
              BOOKING_ORDER_NO: '',
              INVNO: '0',
              DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
              MRC_NO: mrcNumber,
              ICODE: selectedLowerItemDetails.icode,
              ITEM_NAME: selectedLowerItemDetails.item_name,
              PARAMETER: parameter,
              SIZE: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
              LOWER_ICODE: selectedUpperItemDetails
                ? selectedUpperItemDetails.lower_icode
                : 0,
              REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
              SECTION_NAME: selectedLowerItemDetails.section_name,
              UPPER_LOWER: selectedLowerItemDetails.upper_lower,
              CODE: bookingCustomerDetails?.code
                ? bookingCustomerDetails.code
                : '',
              CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
              FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                ? bookingCustomerDetails?.family_mem_name
                : '',
              MOBILE: bookingCustomerDetails?.mobile,
              MOBILE_2: bookingCustomerDetails?.mobile_2,
              MOBILE_3: bookingCustomerDetails?.mobile_3,
              PHONE: bookingCustomerDetails?.company_phone,
              ADDRESS: bookingCustomerDetails?.address
                ? bookingCustomerDetails.address
                : '',
              CITY: bookingCustomerDetails?.city
                ? bookingCustomerDetails?.city
                : '',
              STATE: bookingCustomerDetails?.state
                ? bookingCustomerDetails?.state
                : '',
              COUNTRY: bookingCustomerDetails?.country,
              TRY_DATE: lowerDate?.try
                ? moment(lowerDate?.try).format('YYYY-MM-DD')
                : '',
              DEL_DATE: moment(lowerDate?.del).format('YYYY-MM-DD'),
              REMARKS: remarks?.lower,
            },
          ])
        );
      } else {
        if (isParameter && !value) {
          let list = measurmentList;
          list = list.filter((item) => item.PARAMETER !== parameter);
          dispatch(setLowerMeasuremntList([...list]));
          // setMeasurmentList([...list]);
        }
      }
    }
  };

  const MasterPrifix = useSelector(selectTableMasterPrefix);
  const prefix = useSelector(selectPrefix);
  const customerPrefix = useSelector(selectCustomerPrefix);
  useEffect(() => {
    if (
      selectedLowerItemDetails &&
      !LowerMeasuremntDetails[0] &&
      !modifiedBookingID
    ) {
      getLowerMeasuremntDetails(
        MasterPrifix,
        token,
        dispatch,
        bookingCustomerDetails,
        prefix,
        mrcNumber,
        selectedLowerItemDetails,
        '',
        lowerDate?.try,
        lowerDate?.del,
        remarks,
        bookingDate,
        '',
        '',
        '',
        setAlertTwoButtonModal,
        alertTwoButtonModal,
        selectedUpperItemDetails,
        customerPrefix,
        triggerAnimationLower
      );
    }
  }, [selectedLowerItemDetails]);

  const clear = () => {
    if (!modifyBillCartList[0]) {
      const emptyParametr = LowerMeasuremntDetails.map((i, index) => {
        const data = {
          ...i,
          size: '',
        };
        return data;
      });

      dispatch(setLowerMeasuremnt([...emptyParametr]));
      dispatch(setLowerMeasuremntList([]));

      dispatch(setLowerQnty(1));
    }
  };

  const editCell2 = (field, id, value) => {
    const i = LowerMeasuremntDetails.findIndex((i) => i.CODE === id);

    const parameter = LowerMeasuremntDetails[i].PARAMETER;
    const PCODE = LowerMeasuremntDetails[i].CODE;
    const size = LowerMeasuremntDetails[i].size;
    const isParameter = measurmentList.some(
      (item) => item.PARAMETER === parameter
    );

    if (!size) {
      let data = LowerMeasuremntDetails.map((i, index) => {
        if (PCODE === i.CODE) {
          const row = {
            ...LowerMeasuremntDetails[index],
            size: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
          };

          return row;
        } else {
          if (parameter !== i.PARAMETER) {
            return LowerMeasuremntDetails[index];
          }
        }
      });

      dispatch(setLowerMeasuremnt(data));
    } else {
      if (size && value) {
        let list = LowerMeasuremntDetails;
        // let list2 = list.filter((item) => item.CODE !== PCODE);
        let list3 = list.map((item) => {
          if (item.CODE !== PCODE) {
            return item;
          } else {
            let data = {
              ...item,
              size: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
            };
            return data;
          }
        });
        // dispatch(
        //   setLowerMeasuremnt([
        //     ...list2,
        //     {
        //       ...LowerMeasuremntDetails[i],
        //       size: value,
        //     },
        //   ])
        // );
        dispatch(setLowerMeasuremnt(list3));
      } else {
        if (size && !value) {
          let list = LowerMeasuremntDetails;
          // let list2 = list.filter((item) => item.CODE !== PCODE);
          let list3 = list.map((item) => {
            if (item.CODE !== PCODE) {
              return item;
            } else {
              let data = {
                ...item,
                size: value
                  .replace(/\/2/g, '½')
                  .replace(/\/3/g, '¾')
                  .replace(/\/4/g, '¼')
                  .replace(/\//g, '½'),
              };
              return data;
            }
          });
          dispatch(setLowerMeasuremnt(list3));
        }
      }
    }

    if (!isParameter) {
      dispatch(
        setLowerMeasuremntList([
          ...measurmentList,
          {
            BOOKING_ORDER_NO: '',
            INVNO: '0',
            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: mrcNumber,
            ICODE: selectedLowerItemDetails.icode,
            ITEM_NAME: selectedLowerItemDetails.item_name,
            PARAMETER: parameter,
            SIZE: value
              .replace(/\/2/g, '½')
              .replace(/\/3/g, '¾')
              .replace(/\/4/g, '¼')
              .replace(/\//g, '½'),
            LOWER_ICODE: selectedUpperItemDetails
              ? selectedUpperItemDetails.lower_icode
              : 0,
            REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
            SECTION_NAME: selectedLowerItemDetails.section_name,
            UPPER_LOWER: selectedLowerItemDetails.upper_lower,
            CODE: bookingCustomerDetails?.code
              ? bookingCustomerDetails.code
              : '',
            CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
            MOBILE: bookingCustomerDetails?.mobile,
            MOBILE_2: bookingCustomerDetails?.mobile_2,
            MOBILE_3: bookingCustomerDetails?.mobile_3,
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            PHONE: bookingCustomerDetails?.company_phone,
            ADDRESS: bookingCustomerDetails?.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails?.city
              ? bookingCustomerDetails?.city
              : '',
            STATE: bookingCustomerDetails?.state
              ? bookingCustomerDetails?.state
              : '',
            COUNTRY: bookingCustomerDetails?.country,
            TRY_DATE: lowerDate?.try
              ? moment(lowerDate?.try).format('YYYY-MM-DD')
              : '',
            DEL_DATE: moment(lowerDate?.del).format('YYYY-MM-DD'),
            REMARKS: remarks?.lower,
          },
        ])
      );
    } else {
      if (isParameter && value) {
        let list = measurmentList;
        list = list.filter((item) => item.PARAMETER !== parameter);

        dispatch(
          setLowerMeasuremntList([
            ...list,
            {
              BOOKING_ORDER_NO: '',
              INVNO: '0',
              DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
              MRC_NO: mrcNumber,
              ICODE: selectedLowerItemDetails.icode,
              ITEM_NAME: selectedLowerItemDetails.item_name,
              PARAMETER: parameter,
              SIZE: value
                .replace(/\/2/g, '½')
                .replace(/\/3/g, '¾')
                .replace(/\/4/g, '¼')
                .replace(/\//g, '½'),
              LOWER_ICODE: selectedUpperItemDetails
                ? selectedUpperItemDetails.lower_icode
                : 0,
              REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
              SECTION_NAME: selectedLowerItemDetails.section_name,
              UPPER_LOWER: selectedLowerItemDetails.upper_lower,
              CODE: bookingCustomerDetails?.code
                ? bookingCustomerDetails.code
                : '',
              CUSTOMER_NAME: bookingCustomerDetails?.customer_name,
              FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
                ? bookingCustomerDetails?.family_mem_name
                : '',
              MOBILE: bookingCustomerDetails?.mobile,
              MOBILE_2: bookingCustomerDetails?.mobile_2,
              MOBILE_3: bookingCustomerDetails?.mobile_3,
              PHONE: bookingCustomerDetails?.company_phone,
              ADDRESS: bookingCustomerDetails?.address
                ? bookingCustomerDetails.address
                : '',
              CITY: bookingCustomerDetails?.city
                ? bookingCustomerDetails?.city
                : '',
              STATE: bookingCustomerDetails?.state
                ? bookingCustomerDetails?.state
                : '',
              COUNTRY: bookingCustomerDetails?.country,
              TRY_DATE: lowerDate?.try
                ? moment(lowerDate?.try).format('YYYY-MM-DD')
                : '',
              DEL_DATE: moment(lowerDate?.del).format('YYYY-MM-DD'),
              REMARKS: remarks?.lower,
            },
          ])
        );
      } else {
        if (isParameter && !value) {
          let list = measurmentList;
          list = list.filter((item) => item.PARAMETER !== parameter);
          dispatch(setLowerMeasuremntList([...list]));
          // setMeasurmentList([...list]);
        }
      }
    }
  };
  const editType = useSelector(selectEditType);

  useEffect(() => {
    if (editType === 'LOWER') {
      if (editCellValues.field) {
        const field = editCellValues.field;
        const id = editCellValues.id;

        const value = parametrInput;

        editCell2(field, id, value);
      }
    }
  }, [parametrInput]);

  return (
    <div className=" h-full flex flex-col  w-full">
      <div className="flex w-full">
        {isAnimatingLower ? (
          <Skeleton
            width={'100%'}
            variant="rounded"
            height={40}
            animation={'wave'}
          ></Skeleton>
        ) : (
          <div
            onClick={() => {
              setStyleType('LOWER');
              setStatus(true);
            }}
            style={{
              border: isAnimatingLower ? '2px dotted gray' : '1px solid gray',
              backgroundColor: '#f8e4dd',
              position: 'relative',
            }}
            className="w-full  p-1 rounded-sm flex"
          >
            <i className="bi bi-list text-[18px] text-black"></i>
            <div className="flex  flex-1 justify-center text-gray-700 items-center font-semibold">
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '70%',
                }}
              >
                {selectedLowerItemDetails.item_name
                  ? selectedLowerItemDetails.item_name
                  : 'LOWER'}
              </div>
            </div>
            {selectedLowerItemDetails ? (
              <div className="hidden d:flex">
                <img
                  src={require(`../utility/images/3.png`)}
                  alt=""
                  style={{
                    height: width,
                    width: width,
                  }}
                />
              </div>
            ) : null}
            {isAnimatingLower && (
              <button
                className={`bounceButton ${
                  isAnimatingLower ? 'bounce' : ''
                } text-sm`}
              >
                +1
              </button>
            )}
            <style jsx>{`
              @keyframes borderAnimation {
                0% {
                  border-color: gray;
                }
                50% {
                  border-color: gray;
                }
                100% {
                  border-color: gray;
                }
              }
            `}</style>
          </div>
        )}
        <button
          onClick={() => {
            setOpenModalStatus(true);
          }}
          className="flex items-center justify-center bg-[#f8e4dd] border  ml-2 rounded-sm text-sm px-2"
        >
          Create
        </button>
        <div
          style={{
            border: '1px solid #e8e2db',
            borderRight: '1px solid #e8e2db',
            borderTop: '1px solid #e8e2db',
          }}
          className="flex h-full ml-2 items-center rounded-sm  "
        >
          <div className="flex  h-full items-center overflow-hidden">
            <div
              className=" px-2 h-full flex items-center "
              style={{
                borderRight: '1px solid #e8e2db',
              }}
              onClick={() => {
                if (LowerQnty > 1) {
                  dispatch(setLowerQnty(LowerQnty - 1));
                }
              }}
            >
              <i className="bi bi-dash"></i>
            </div>
            <input
              type={'number'}
              maxLength={2}
              style={{
                outline: '0px',
                textAlign: 'center',
              }}
              value={LowerQnty}
              onChange={(e) => {
                let length = e.target.value.length;
                if (length < 3) {
                  if (e.target.value >= 1) {
                    dispatch(setLowerQnty(Number(LowerQnty) + 1));
                    // setQnty(e.target.value);
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Qnty',
                      msg: 'Maximum 2 characters allowed',
                      lottie: 'reject',
                    })
                  );
                }
              }}
              className=" flex w-8 h-full "
            />
            <div
              className="hover:cursor-pointer h-full flex items-center  "
              style={{
                borderLeft: '1px solid #d1d1d1',
              }}
            >
              <div
                onClick={() => {
                  dispatch(setLowerQnty(LowerQnty + 1));
                }}
                className=" px-2 flex justify-center items-center"
              >
                <i className="bi bi-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          border: '1px solid #e8e2db',
          height: `calc(100% - 165px)`,
        }}
        className="flex-col  mt-2 rounded-sm overflow-hidden"
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
          }}
          rowHeight={33}
          headerHeight={0}
          columns={StyleColums}
          onCellClick={(e) => {
            if (e.field === 'size') {
              dispatch(setEditType('LOWER'));
              setEditCellValues({
                field: e.field,
                id: e.id,
                value: e.value,
              });
              setParameterInput(e.value);
              if (window.innerWidth <= 1280) {
                SetItemTabScreen(2);
              }
            }
          }}
          rows={LowerMeasuremntDetails}
          hideFooter
          getRowId={(i) => i.CODE}
          scrollbarSize={0}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          onCellEditCommit={editCell}
          disableColumnMenu={true}
          components={{
            Header: () => (
              <MeasurmentStyleHeader clear={clear} columns={StyleColums} />
            ),

            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                LOWER Parameter
              </div>
            ),
          }}
        />
      </div>
      <div
        style={{
          border: '1px solid #fad9cd',
        }}
        className=" flex   mt-3  h-[104px] rounded-sm "
      >
        <div className="w-full   flex flex-col   ">
          <div className="-mt-[10px] ml-4  w-fit bg-white px-3 whitespace-nowrap  text-md text-gray-600">
            Lower Remarks
          </div>

          <textarea
            type="text"
            className="outline-0 pl-3 pt-2 flex flex-col grow  w-full  text-sm md:text-md rounded-sm overflowY-scroll  "
            maxLength={500}
            rows={window.innerWidth > 1600 ? 4 : 1}
            style={{
              resize: 'none',
            }}
            onClick={() => {
              dispatch(setEditType('L-REMARKS'));
              setParameterInput(remarks?.lower);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevent the default behavior
                const newValue = remarks.lower + '\n'; // Add a new line to the existing value
                dispatch(
                  setRemarks({
                    ...remarks,
                    lower: newValue,
                  })
                );
              }
            }}
            value={remarks?.lower}
            onChange={(e) => {
              dispatch(
                setRemarks({
                  ...remarks,
                  lower: CapitalizeProperNouns(e.target.value),
                })
              );
            }}
          />

          <MeasuremtTags
            onSelect={(e) => {
              dispatch(
                setRemarks({
                  ...remarks,
                  lower: remarks?.lower + '' + e + '',
                })
              );
            }}
          />
          <OpenItemModal
            status={openItemModalStatus}
            onclose={() => {
              setOpenModalStatus(false);
            }}
            UPPER_LOWER={'LOWER'}
            afterSave={(res) => {
              setOpenModalStatus(false);
              GetItemDetails(token, dispatch, MasterPrifix).then((list) => {
                const savedItem = list.filter(
                  (o) => o.icode === res.data.result.insertId
                );
                if (!modifiedOrderStatus || !SelectedLowerItem.icode) {
                  if (!modifiedBookingID) {
                    if (
                      bookingCustomerDetails?.customer_name &&
                      bookingCustomerDetails?.mobile
                    ) {
                      if (
                        LowerMeasuremntDetails[0] &&
                        SelectedLowerItem.icode !== savedItem[0].icode
                      ) {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: true,
                          title: 'Parameter!',
                          msg: 'Do you want to clear all parameters and create new ?',
                          button1: 'Yes',
                          button2: 'No',
                          button1Click: () => {
                            changeItemParameterLower(
                              savedItem[0],
                              selectedUpperItemDetails,
                              dispatch,
                              remarks,
                              setAlertTwoButtonModal,
                              alertTwoButtonModal
                            );
                          },
                        });
                      } else {
                        dispatch(
                          setSelectedBooingLowerItemDetails(savedItem[0])
                        );
                      }
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Customer Mobile!',
                          msg: 'Please select Customer Mobile to continue.',
                        })
                      );
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Error!',
                        msg: 'Can not change item in Modify',
                      })
                    );
                  }
                }
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LowerStyle;
