import React, { useEffect, useState } from 'react';
import {
  selectPrefix,
  selectTableMasterPrefix,
  selectWidth,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  selectSpinnerLoading,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { Button, Skeleton } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import washing from '../utility/svg/washing-machine-svgrepo-com.svg';
import cutting from '../utility/svg/scissors-cutting-svgrepo-com.svg';
import embroidery from '../utility/svg/craft-embroidery-crafts-svgrepo-com.svg';
import sttiching from '../utility/svg/stiching-fabric-svgrepo-com.svg';
import buttonstype from '../utility/svg/button-svgrepo-com.svg';
import press from '../utility/svg/ironing-svgrepo-com.svg';
import ready from '../utility/svg/man-getting-dressed-in-front-of-mirror-svgrepo-com.svg';
import delivery from '../utility/svg/deliver-svgrepo-com.svg';
import database from '../utility/component/functions/firebase';
import { child, get, onValue, ref } from 'firebase/database';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SupervisorDashboard = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const getProduction = () => {
    axios
      .post(
        '/api/production/productionStatus',
        {
          table_prefix: prefix,
          STAGE_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if (p.data.response[0]) {
          const data = p.data.response;
          setList(data);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };
  useEffect(() => {
    getProduction();
  }, [prefix, token, dispatch]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const details = {};
      for (const stage of list) {
        const response = await axios
          .post(
            '/api/production/OrdersInProduction',
            {
              table_prefix: prefix,
              STAGE_NAME: stage.STAGE_NAME,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((p) => {
            return p.data.response;
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
        details[stage.STAGE_NAME] = response;
      }
      setLoading(false);

      setOrderDetails(details);
    };
    if (list.length > 0) {
      setLoading(true);
      fetchOrderDetails();
    }
  }, [list, prefix, token]);

  const chartData = {
    labels: list.map((stage) => stage.STAGE_NAME),
    datasets: [
      {
        label: '# of Orders',
        data: list.map((stage) => stage.NO_OF_ORDERS),
        backgroundColor: [
          '#FFC1CC', // Powder Pink
          '#C1E1C1', // Powder Green
          '#C1D4E1', // Powder Blue
          '#E1C1B3', // Powder Beige
          '#E1E1C1', // Powder Yellow
        ],
        hoverBackgroundColor: [
          '#FFB3C1', // Bold Powder Pink
          '#A3D1A3', // Bold Powder Green
          '#A3C4D1', // Bold Powder Blue
          '#D4A3A3', // Bold Powder Beige
          '#D4D4A3', // Bold Powder Yellow
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        color: 'black',
        formatter: (value, context) => {
          return `${list[context.dataIndex].STAGE_NAME} : ${value}`;
        },
      },
    },
  };

  const spinnerLoading = useSelector(selectSpinnerLoading);
  const width = useSelector(selectWidth);

  useEffect(() => {
    const connectedRef = ref(database, `loginAuth/${masterPrefix}`);
    const connectedRef2 = ref(database, `loginAuth/${masterPrefix}/production`);
    get(child(connectedRef, `/production`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.exists()) {
          getProduction();
        }
      });
    });
  }, []);
  return (
    <div
      style={{
        height: window.innerHeight - 180,
      }}
      className="w-full  p-3 flex  overflow-y-auto"
    >
      <div
        style={{
          width: '100%',
        }}
        className="md:flex md:justify-between h-full     "
      >
        {spinnerLoading || loading ? (
          <SkeletonLoader />
        ) : (
          <div
            style={{
              height: window.innerWidth < 768 ? `calc(100% - 0px)` : '100%',
            }}
            className="overflow-y-auto  md:2/3 w-full  "
          >
            {list.map((stage) => (
              <div key={stage.STAGE_NAME} className="w-full  mb-3 pr-2">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    fontWeight: '500',
                  }}
                  className="w-fit flex px-3 py-1 text-white rounded-br-3xl text-[15px] bg-[#634258]"
                >
                  {stage.STAGE_NAME}
                  {' : '} {stage.NO_OF_ORDERS}
                </div>
                <div className="grid max-sm:grid-cols-1 tab:grid-cols-1 t:grid-cols-2 d:grid-cols-3 gap-2 mt-2">
                  {orderDetails[stage.STAGE_NAME]?.map((e, index) => {
                    const dep = stage.STAGE_NAME.toLowerCase();
                    const typeOfStage = dep.includes('wa')
                      ? washing
                      : dep.includes('cu')
                      ? cutting
                      : dep.includes('emb')
                      ? embroidery
                      : dep.includes('st')
                      ? sttiching
                      : dep.includes('bu')
                      ? buttonstype
                      : dep.includes('pr')
                      ? press
                      : dep.includes('re')
                      ? ready
                      : dep.includes('del')
                      ? delivery
                      : sttiching;
                    return (
                      <Button
                        key={index}
                        variant={'contained'}
                        style={{
                          textTransform: 'none',
                          backgroundColor: 'transparent',
                          padding: '0px',
                          marginBottom: '8px',
                          boxShadow: 'none',
                        }}
                      >
                        <div
                          style={{
                            boxShadow: '1px 1px 1px gray',
                            backgroundColor:
                              e.UPPER_LOWER === 'UPPER' ? '#e8e2db' : '#f8e4dd',
                          }}
                          className="flex justify-between h-full w-full pl-1 pr-2 pt-2 pb-2 rounded-sm"
                        >
                          <div className="rounded-sm w-full p-1 px-2">
                            <div className=" w-full">
                              <div className="font-semibold flex justify-between text-black">
                                <div>
                                  #{e.BOOKING_ORDER_NO} {e.ITEM_NAME} -{' '}
                                  {e.UPPER_LOWER === 'UPPER' ? '👕' : '👖'}
                                </div>
                                <div>
                                  <img src={typeOfStage} className="w-6" />
                                </div>
                              </div>
                              <div className="font-semibold text-start text-black">
                                MRC - {e.MRC_NO}
                              </div>
                              <div className="font-semibold text-start text-black">
                                {`${e.CUSTOMER_NAME} - [ ${e.MOBILE} ]`}
                              </div>
                              <div className="font-semibold text-start text-black">
                                {`( Emp. Name : ${e.CURRENT_EMPLOYEE} - ${e.CURRENT_EMP_MOBILE} )`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex md:w-1/3 w-full  flex-col items-center">
          <div
            style={{
              width: width > 768 ? '350px' : '200px',
              height: width > 768 ? '350px' : '200px',
            }}
            className="w-full  h-full mb-5"
          >
            <Pie data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorDashboard;

const SkeletonLoader = () => {
  return (
    <div className="w-full">
      {[...Array(5)].map((_, index) => (
        <div key={index} mb={2} className="mb-4">
          <Skeleton
            variant="rectangular"
            height={30}
            width="140px"
            sx={{
              borderBottomRightRadius: '24px',
            }}
          />
          <div className="grid max-sm:grid-cols-1 tab:grid-cols-2 t:grid-cols-3 d:grid-cols-5 gap-2 mt-2">
            {[...Array(Math.floor(Math.random() * 3) + 1)].map(() => {
              return (
                <div>
                  <Skeleton
                    sx={{
                      borderRadius: '10px',
                    }}
                    variant="rectangular"
                    height={50}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
