import { useDispatch, useSelector } from 'react-redux';
import { FormatNumber2 } from '../tools';
import {
  selectAdvanceVoucherDetails,
  selectBillSettlement,
  selectBillingAdvanceAmount,
  selectBillingCartList,
  selectBillingDiscountP,
  selectModifyBillingCartlist,
  setAdvanceVoucherDetails,
  setBillSettlement,
  setBillingAdvanceAmount,
  setBillingCartList,
  setModifyBillcartList,
} from '../../Redux/billing';

import ModItemMasterHelp from '../help/itemMasterHelp';
import { useRef, useState } from 'react';

import {
  selectCustomerPrefix,
  selectHistoryOrderDetails,
  selectModyingBookingID,
} from '../../Redux/booking';
import uniqid from 'uniqid';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../Redux/modal';
import { Button } from '@mui/material';
import CreateBillingCartWithTax from '../functions/createBillingCartWithTax';
import { useEffect } from 'react';
import ModEPayment from '../modals/ePaymentType';
import { useFocus } from '../../hooks/hooks';
import { Save_button } from '../buttons';
import { selectToken } from '../../Redux/security';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserType,
} from '../../Redux/profile';
import moment from 'moment';
import axios from 'axios';
import AlertModalTwoButton from '../modals/alertWithTwoButton';
import { useReactToPrint } from 'react-to-print';
import ViewBillImage from '../modals/viewBill';
import { getMessageWallet, low_Bal_alert } from '../../func/common';
import { useNavigate } from 'react-router-dom';
export const OrderHistryFooter = (props) => {
  const { columns } = props;

  return (
    <div style={{ width: '100%' }} className="  bg-[#e6e6e3] flex ">
      <div className=" bg-white flex w-full ">
        {' '}
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className=" text-black  flex justify-end  items-center "
              style={item.flex ? { flex: item.flex } : { width: item.width }}
            >
              {header === 'Date' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderRight: '0px solid #b5b3b3',
                    borderBottomLeftRadius: '3px',
                  }}
                  className="w-full text-white p-2"
                >
                  {' s '}
                </div>
              ) : null}
              {header === 'ITEM' ? (
                <div
                  style={{
                    border: '1px dotted black',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  {' Total Qnty.'}
                </div>
              ) : null}
              {header === 'QNTY' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full  p-2 text-gray-500"
                >
                  22
                </div>
              ) : null}
              {header === 'RATE' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderRightWidth: '0px',
                    borderLeftWidth: '0px',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  Total Amount Rs.
                </div>
              ) : null}
              {header === 'AMOUNT' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderBottomRightRadius: '3px',
                  }}
                  className="w-full p-2 font-semibold text-end"
                >
                  10,500.00
                </div>
              ) : null}
            </div>
          );
        })}{' '}
      </div>
    </div>
  );
};

export const BillingCardFooter = (props) => {
  const {
    rows,
    columns,
    setVoucherModStatus,
    bookingCustomerDetails,
    mrcNumber,
    isDelivery,
    state,
    orderID,
    setAlertTwoButtonModal,
    alertTwoButtonModal,
    saveStage,
    isMakeBillApiRun,
    isDeliveredApiRun,
    employee,
    qrBillList,
    bill_delevery_cart,
    setBill_delevery_cart,
    customerPrefixState,
  } = props;
  const [ePaymentModStatus, setEPaymentModStatus] = useState(false);
  const [ePaymentTypeState, setEPaymentTypeState] = useState('');
  const [cardNumberref, setCardNumberFocus] = useFocus();
  const [saveBillCartList, setsaveBillCartList] = useState([]);
  const [billNumber, SetBillnumber] = useState('');
  const billCartList = useSelector(selectBillingCartList);

  const [printerType, setPrinterType] = useState('Thermal');
  const [itemMasterStatus, setItemmasterStatus] = useState(false);
  const customerPrefix = useSelector(selectCustomerPrefix);
  const billingCartList = useSelector(selectBillingCartList);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  const modifyBillingCart = useSelector(selectModifyBillingCartlist);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const advanceVoucherDetails = useSelector(selectAdvanceVoucherDetails);
  const thermalRef = useRef();
  const billSettelmentFields = useSelector(selectBillSettlement);
  //test
  const totalQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.QNTY),
    0
  );
  const saveStageWithEmployee = (inv) => {
    bill_delevery_cart.map((r, i) => {
      if (r.delivery) {
        saveStage(orderID, {
          table_prefix: prefix,
          SERIAL_NO: '',
          DATE: moment().format('YYYY-MM-DD'),
          BOOKING_ORDER_NO: orderID,
          STAGE_NAME: 'Delivery',
          EMPLOYEE_CODE: employee.code,
          EMPLOYEE_MOBILE: employee.mobile,
          EMPLOYEE_NAME: employee.employee_name,
          CURRENT_EMPLOYEE: employee.employee_name,
          DEPARTMENT_NAME: 'Delivery',
          ICODE: r.ICODE,
          ITEM_NAME: r.ITEM_NAME,
          UPPER_LOWER: r.UPPER_LOWER,
          QR_CODE: r.ID,
          CURRENT_EMP_MOBILE: employee.mobile,
        });
      }

      return;
    });
    if (billCartList[0]) {
      SetBillnumber(inv);
    } else {
      dispatch(
        setBillSettlement({
          cashAmount: '',
          btcAmount: '',
          epaymentAmount: '',
          epaymentType: '',
          cardAmount: '',
          cardNumber: '',
          Remarks: '',
          clothAmount: '',
          DISCOUNT_RATE: 0,
          DISCOUNT_AMOUNT: 0,
        })
      );
      SetBillnumber('');
      dispatch(setBillingCartList([]));
      setBill_delevery_cart([]);
      dispatch(setBillingAdvanceAmount(''));
      dispatch(setAdvanceVoucherDetails(''));
    }
  };

  const handleThermal = useReactToPrint({
    content: () => thermalRef.current,
    onAfterPrint: () => {
      dispatch(
        setBillSettlement({
          cashAmount: '',
          btcAmount: '',
          epaymentAmount: '',
          epaymentType: '',
          cardAmount: '',
          cardNumber: '',
          Remarks: '',
          clothAmount: '',
          DISCOUNT_RATE: 0,
          DISCOUNT_AMOUNT: 0,
        })
      );
      SetBillnumber('');
      dispatch(setBillingCartList([]));
      setBill_delevery_cart([]);
      dispatch(setBillingAdvanceAmount(''));
      dispatch(setAdvanceVoucherDetails(''));
    },
  });

  ///its only run in case delevery
  const billSettleMentAPI = (invNo, date) => {
    const data = {
      table_prefix: prefix,
      INVNO: invNo,
      MRC_NO: mrcNumber,
      PREFIX: customerPrefixState
        ? customerPrefixState
        : customerPrefix
        ? customerPrefix
        : '',
      BILL_AMOUNT: Math.round(netAmount),
      CASH_AMOUNT: Number(billSettelmentFields.cashAmount),
      CARD_AMOUNT: Number(billSettelmentFields.cardAmount),
      EPAYMENT: Number(billSettelmentFields.epaymentAmount),
      BTC_AMOUNT: Number(billSettelmentFields.btcAmount),
      EPAYMENT_TYPE: billSettelmentFields.epaymentType,
      CARD_NO: billSettelmentFields.cardNumber,
      ADVANCE_AMT: Number(BillingAdvanceAmount),
      DATE: date
        ? moment(date).format('YYYY-MM-DD')
        : moment(date).format('YYYY-MM-DD'),
      CLOTH_BALANCE: Number(billSettelmentFields.clothAmount),
    };
    axios
      .post(`/api/billing/saveBillSettlement`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          if (advanceVoucherDetails) {
            saveAdvanceVoucherAPI(invNo);
          } else {
            if (saveStageWithEmployee) {
              saveStageWithEmployee(invNo);
            } else {
              SetBillnumber(invNo);
            }
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error code: 103',
              msg: 'Something went wrong in saving bill settlement data.',
              lottie: 'reject',
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error code: 104',
              msg: 'Something went wrong in saving bill settlement data.',
              lottie: 'reject',
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const saveAdvanceVoucherAPI = (invNo) => {
    const data = {
      ...advanceVoucherDetails,
      INVNO: invNo,
      LATITUDE: '',
      LONGITUDE: '',
      table_prefix: prefix,
      ADDRESS: bookingCustomerDetails.address
        ? bookingCustomerDetails.address
        : '',
      APPROVED: 'NO',
      AMOUNT_DESC: `Bill No.${invNo}`,
      MRC_NO: mrcNumber,
      PREFIX: customerPrefixState ? customerPrefixState : customerPrefix,
    };
    axios
      .post(`/api/vouchers/saveVoucher`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (response.data.result.affectedRows === 1) {
          if (saveStageWithEmployee) {
            saveStageWithEmployee(invNo);
          } else {
            SetBillnumber(invNo);
          }
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data saved',
            })
          );
          // setAlertTwoButtonModal({
          //   ...alertTwoButtonModal,
          //   status: true,
          //   title: 'Success',
          //   msg: 'Data saved successfully.',
          //   button1: 'Ok',

          //   button1Click: () => {
          //     setAlertTwoButtonModal({
          //       ...alertTwoButtonModal,
          //       status: false,
          //     });
          //   },
          // });
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setAdvanceVoucherDetails(''));
      });
  };

  ///its only run in case delevery
  const saveBillAPI = () => {
    const totalPaidAmount =
      Number(billSettelmentFields.cashAmount) +
      Number(billSettelmentFields.btcAmount) +
      Number(billSettelmentFields.epaymentAmount) +
      Number(billSettelmentFields.cardAmount);
    if (billingCartList[0]) {
      if (Number(totalPaidAmount) === Number(netAmount)) {
        if (
          (billSettelmentFields.cardAmount &&
            !billSettelmentFields.cardNumber) ||
          (!billSettelmentFields.cardAmount && billSettelmentFields.cardNumber)
        ) {
          dispatch(
            setAlertWithTitle({
              title: 'Oops!',
              msg: 'Card amount or card Number error!',
            })
          );
          setCardNumberFocus();
        } else {
          if (
            (billSettelmentFields.epaymentAmount &&
              !billSettelmentFields.epaymentType) ||
            (billSettelmentFields.epaymentType &&
              !billSettelmentFields.epaymentAmount)
          ) {
            dispatch(
              setAlertWithTitle({
                title: 'Oops!',
                msg: `ePayment error!`,
              })
            );
          } else {
            if (saveBillCartList[0].CUSTOMER_NAME) {
              const isCustomerType = saveBillCartList.some(
                (item) => item.CUSTOMER_TYPE === ''
              );

              if (!isCustomerType) {
                dispatch(setSpinnerLoading('Saving bill'));
                axios
                  .post(
                    `/api/billing/saveBill`,
                    {
                      table_prefix: prefix,
                      cart_item: saveBillCartList,
                    },

                    {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(function (response) {
                    if (response.data.affectedRows === billingCartList.length) {
                      billSettleMentAPI(
                        response.data.response.INVNO,
                        billingCartList[0].DATE
                      );
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Error code: 101',
                          msg: 'Something went wrong while saving your bill',
                        })
                      );
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                    dispatch(setSpinnerLoading(false));

                    if (error.response.data.name === 'TokenExpiredError') {
                      dispatch(setSessionExpired(true));
                    }
                    dispatch(
                      setAlertWithTitle({
                        title: 'Error code: 102',
                        msg: 'Something went wrong while saving your bill',
                      })
                    );
                  });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Error code: 116 ',
                    msg: 'Something went wrong with Customer Type',
                  })
                );
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Error code: 110',
                  msg: 'Something wrong with Customer details.',
                })
              );
            }
          }
        }
      } else {
        if (Number(totalPaidAmount) <= Number(netAmount)) {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'The entered amount is less than the actual bill amount. Please enter a valid amount.',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Cannot save Empty Bill.',
        })
      );
    }
  };
  const BillingAdvanceAmount = useSelector(selectBillingAdvanceAmount);

  const totalAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );

  const dispatch = useDispatch();

  const TotalCuttingTax1Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX1_AMOUNT),
    0
  );
  const totalDisqAmt = rows[0] ? rows[0].DISCOUNT_AMOUNT : 0;
  const TotalCuttingTax2Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX2_AMOUNT),
    0
  );

  const TotalCuttingTax3Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX3_AMOUNT),
    0
  );

  const TotalSewingTax1Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX1_AMOUNT),
    0
  );

  const TotalSewingTax2Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX2_AMOUNT),
    0
  );

  const TotalSewingTax3Amount = rows.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX3_AMOUNT),
    0
  );
  const totalBillAmount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );
  const billingAdavanceAmount = useSelector(selectBillingAdvanceAmount);
  console.log(totalAmount);
  console.log(
    TotalCuttingTax1Amount +
      TotalCuttingTax2Amount +
      TotalCuttingTax3Amount +
      TotalSewingTax1Amount +
      TotalSewingTax2Amount +
      TotalSewingTax3Amount
  );
  const netAmount = Math.round(
    totalAmount -
      billingAdavanceAmount +
      TotalCuttingTax1Amount +
      TotalCuttingTax2Amount +
      TotalCuttingTax3Amount +
      TotalSewingTax1Amount +
      TotalSewingTax2Amount +
      TotalSewingTax3Amount -
      totalDisqAmt
  );
  console.log(netAmount);

  const onItemMasterRowClick = async (i) => {
    if (bookingCustomerDetails?.customer_name) {
      if (billCartList[0]?.BOOKING_ORDER_NO) {
        const qnty = 1;
        const remarks = '';
        const p = customerPrefixState ? customerPrefixState : customerPrefix;
        const data = CreateBillingCartWithTax(
          i.row,
          bookingCustomerDetails,
          qnty,
          billCartList[0]?.BOOKING_ORDER_NO,
          remarks,
          mrcNumber,
          p
        );

        const id = uniqid() + 'fabric';

        if (billingCartList[0]) {
          dispatch(
            setBillingCartList([
              ...billingCartList,
              {
                ...data,
                ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
              },
            ])
          );
          setItemmasterStatus(false);
        } else {
          dispatch(
            setBillingCartList([
              {
                ...data,
                ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
              },
            ])
          );
          setItemmasterStatus(false);
        }
      } else {
        if (modifyBillingCart[0]?.BOOKING_ORDER_NO) {
          const qnty = 1;
          const remarks = '';
          const id = uniqid();
          const data = CreateBillingCartWithTax(
            i.row,
            bookingCustomerDetails,
            qnty,
            modifyBillingCart[0]?.BOOKING_ORDER_NO,
            remarks,
            mrcNumber,
            customerPrefix
          );
          if (modifyBillingCart[0]) {
            dispatch(
              setModifyBillcartList([
                ...modifyBillingCart,
                {
                  ...data,
                  ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
                  INVNO: historyOrderDetails[0].INVNO,
                },
              ])
            );

            setItemmasterStatus(false);
          } else {
            dispatch(
              setModifyBillcartList([
                {
                  ...data,
                  ID: id + '-' + i.row.upper_lower + '-' + i.row.icode,
                  INVNO: historyOrderDetails[0].INVNO,
                },
              ])
            );
            setItemmasterStatus(false);
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Oops!',
              msg: `You cannot generate a bill without Measurements `,
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Customer Mobile!',
          msg: 'Please select Customer Mobile to continue.',
        })
      );
    }
  };

  useEffect(() => {
    if (billingAdavanceAmount) {
      const total =
        totalAmount +
        TotalCuttingTax1Amount +
        TotalCuttingTax2Amount +
        TotalCuttingTax3Amount +
        TotalSewingTax1Amount +
        TotalSewingTax2Amount +
        TotalSewingTax3Amount -
        totalDisqAmt;
      dispatch(
        setBillSettlement({
          ...billSettelmentFields,
          btcAmount: Math.round(Number(total)) - billingAdavanceAmount,
          cashAmount: '',
          cardAmount: '',
          epaymentAmount: '',
        })
      );
    }
  }, [billingAdavanceAmount]);

  useEffect(() => {
    if (isDelivery) {
      let rowList = [];
      for (let index = 0; index < billingCartList.length; index++) {
        rowList.push({
          ...billingCartList[index],
          GTOTAL:
            totalBillAmount -
            BillingAdvanceAmount +
            (TotalCuttingTax1Amount +
              TotalCuttingTax2Amount +
              TotalCuttingTax3Amount +
              TotalSewingTax1Amount +
              TotalSewingTax2Amount +
              TotalSewingTax3Amount -
              totalDisqAmt),
          ADVANCE_AMT: Number(BillingAdvanceAmount),
          TOTAL: totalBillAmount,
          FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
            ? bookingCustomerDetails.family_mem_name
            : '',
        });
      }

      setsaveBillCartList(rowList);
    }
  }, [BillingAdvanceAmount, billingCartList]);
  useEffect(() => {
    if (billNumber && isDelivery) {
      handleThermal();
    }
  }, [billNumber]);
  return (
    <div className="w-full mt-2">
      <div style={{ width: '100%' }} className="flex">
        <div className=" flex w-full  ">
          {' '}
          {columns.map((item, index) => {
            const header = item.field;
            return (
              <div
                key={index}
                className="  text-gray-700 flex justify-end  items-end "
                style={
                  item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
                }
              >
                {header === 'BOOKING_ORDER_NO' ? (
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                    }}
                    className=" text-orange  h-full "
                  >
                    <div className=" h-full  w-full">
                      <Button
                        // ref={updateRef}
                        onClick={() => {
                          setItemmasterStatus(true);
                        }}
                        variant="contained"
                        style={{
                          textTransform: 'none',
                          letterSpacing: '1px',
                          backgroundColor: '#f8e4dd',
                          width: '100%',
                          borderRadius: '0px',
                          border: '1px solid black',
                          borderRight: '0px solid black',
                          borderBottom: '0px solid black',
                          color: '#383535',
                          height: '100% ',
                          padding: '0px',
                          outline: 'none',
                          boxShadow: '0px 0px 0px  #cfccca',
                        }}
                      >
                        <div className="  font-sans"> + Add Item </div>
                      </Button>
                    </div>
                  </div>
                ) : null}

                {header === 'QNTY' ? (
                  <div
                    style={{
                      border: '1px solid black',
                      borderRight: '0px solid black',
                      borderLeft: '1px solid black',
                      borderBottom: '0px dashed black',
                      width: columns[index].width,
                    }}
                    className="   h-full p-1 flex justify-center "
                  >
                    {totalQnty}
                  </div>
                ) : null}
                {header === 'RATE' ? (
                  <div
                    style={{
                      borderTop: '1px solid black',
                      borderRight: '0px solid #b5b3b3',
                      borderBottom: '0px dashed black',
                      borderLeft: '1px solid black',
                      width: columns[index].width,
                    }}
                    className=" p-1 h-full  text-end text-gray-600 pr-3"
                  >
                    Total Rs.
                  </div>
                ) : null}
                {header === 'AMOUNT' ? (
                  <div
                    style={{
                      border: '1px solid black',
                      borderRight: '1px solid black',
                      borderTop: '1px solid black',
                      borderBottom: '0px solid black',
                      flex: 1,
                    }}
                    className=" p-1 font-semibold text-end l:pr-3"
                  >
                    {FormatNumber2(totalAmount)}
                  </div>
                ) : null}
              </div>
            );
          })}{' '}
        </div>
      </div>

      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    border: '1px dotted black',
                    borderTop: '1px solid black',
                    borderBottom: '0px dotted black',
                    borderLeft: '1px solid black',
                    borderRight: '0px solid black',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className=" text-black h-full "
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px dotted white',
                      }}
                      className="w-full flex justify-start  p-1 pl-3"
                    >
                      <div className="rounded-sm overflow-hidden  w-full ">
                        <input
                          type="text"
                          className="w-full text-blue"
                          value={billSettelmentFields.Remarks}
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                Remarks: e.target.value,
                              })
                            );
                          }}
                          placeholder="Remarks"
                          style={{
                            outline: 'none',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                    borderLeft: '1px solid black',
                    borderTop: '1px dashed black',
                  }}
                  className=" h-full flex justify-end items-center "
                >
                  <div className="px-3 text-gray-700 p-1 ">Advance Amt.</div>
                </div>
              ) : null}

              {header === 'AMOUNT' ? (
                <div
                  onClick={() => {
                    setVoucherModStatus(true);
                  }}
                  style={{
                    borderLeft: '1px solid black',
                    borderTop: '1px solid black ',
                    borderBottom: '0px solid #b5b3b3',
                    borderRight: '1px solid black',
                    // borderBottomRightRadius: '3px',
                    flex: 1,
                  }}
                  className=" p-1 font-semibold text-end h-full bg-gray-100 pr-3"
                >
                  <input
                    type="number"
                    value={billingAdavanceAmount}
                    readOnly
                    className="text-right bg-gray-100 w-full "
                    style={{
                      outline: 'none',
                      alignSelf: 'end',
                    }}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    border: '1px solid #383535',
                    borderTop: '1px solid #383535',
                    borderLeft: '1px solid #383535',
                    borderRight: '0px solid #383535',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className={`${
                    isDelivery ? '' : 'rounded-bl-sm'
                  } text-[#383535]`}
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px solid #383535',
                      }}
                      className="w-[60%] flex justify-start  p-1 pl-3"
                    >
                      Balance Due
                    </div>

                    <div className="flex p-1 grow justify-end font-semibold pr-3"></div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                  }}
                  className=" h-full items-center flex justify-end "
                >
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                      border: '1px solid #383535',
                      borderRight: '0px solid #383535',
                      borderLeft: '1px solid #383535',
                    }}
                    className="pl-4 h-full flex items-center justify-end pr-3 font-semibold   "
                  >
                    Net Amount{' '}
                  </div>
                </div>
              ) : null}

              {header === 'AMOUNT' ? (
                <div
                  style={{
                    border: '1px solid #383535',
                    borderTop: '1px solid #383535  ',
                    borderRight: '1px solid #383535',

                    flex: 1,
                  }}
                  className={`${
                    isDelivery ? '' : 'rounded-br-sm '
                  } p-1   font-semibold text-end l:pr-3`}
                >
                  {FormatNumber2(netAmount)}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {isDelivery && (
        <div className=" w-full flex ">
          {columns.map((item, index) => {
            const header = item.field;
            return (
              <div
                key={index}
                className="  text-black flex justify-end  items-center "
                style={
                  item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
                }
              >
                {header === 'BOOKING_ORDER_NO' ? (
                  <div
                    style={{
                      border: '1px solid #383535',
                      borderLeft: '1px solid #383535',
                      borderRight: '0px solid #383535',
                      borderTop: '0px solid #383535',

                      width: columns[index + 1].width + columns[index].width,
                    }}
                    className=" text-[#383535] "
                  >
                    <div className="w-full flex justify-between">
                      <div
                        style={{
                          borderRight: '1px solid #383535',
                        }}
                        className="w-[60%] flex justify-start  p-1 pl-3"
                      >
                        <input
                          onDoubleClick={() => {
                            if (!BillingAdvanceAmount) {
                              dispatch(
                                setBillSettlement({
                                  ...billSettelmentFields,
                                  cashAmount: netAmount,
                                })
                              );
                            }
                          }}
                          type="text"
                          placeholder="Cash Amount"
                          value={
                            billSettelmentFields.cashAmount === 0
                              ? ''
                              : billSettelmentFields.cashAmount
                          }
                          readOnly={BillingAdvanceAmount ? true : false}
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                cashAmount: e.target.value,
                              })
                            );
                            if (
                              Number(billSettelmentFields.btcAmount) +
                                Number(billSettelmentFields.epaymentAmount) +
                                Number(billSettelmentFields.cardAmount) +
                                Number(e.target.value) -
                                Number(netAmount) >
                              0
                            ) {
                              dispatch(
                                setBillSettlement({
                                  ...billSettelmentFields,
                                  cashAmount: '',
                                })
                              );
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Error',
                                  msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                                })
                              );
                            }
                          }}
                          style={{
                            outline: 'none',
                          }}
                          className="w-full"
                        />
                      </div>

                      <div className="flex p-1 flex-1 justify-end  pr-3">
                        <input
                          type="text"
                          value={
                            billSettelmentFields.btcAmount === 0
                              ? ''
                              : billSettelmentFields.btcAmount
                          }
                          readOnly={BillingAdvanceAmount ? true : false}
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                btcAmount: e.target.value,
                              })
                            );
                            if (
                              Number(billSettelmentFields.cashAmount) +
                                Number(billSettelmentFields.epaymentAmount) +
                                Number(billSettelmentFields.cardAmount) +
                                Number(e.target.value) -
                                Number(netAmount) >
                              0
                            ) {
                              dispatch(
                                setBillSettlement({
                                  ...billSettelmentFields,
                                  btcAmount: '',
                                })
                              );
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Error',
                                  msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                                })
                              );
                            }
                          }}
                          placeholder="BTC Amount"
                          style={{
                            outline: 'none',
                          }}
                          className="w-full "
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

                {header === 'QNTY' ? (
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                    }}
                    className=" h-full items-center flex justify-end "
                  >
                    <div
                      style={{
                        width: columns[index].width + columns[index + 1].width,
                        border: '1px solid #383535',
                        borderRight: '0px solid #383535',
                        borderLeft: '1px solid #383535',
                        borderTop: '0px solid #383535',
                      }}
                      className="pl-3 h-full flex items-center  "
                    >
                      <input
                        type="text"
                        className="w-full"
                        value={
                          billSettelmentFields.epaymentAmount === 0
                            ? ''
                            : billSettelmentFields.epaymentAmount
                        }
                        readOnly={BillingAdvanceAmount ? true : false}
                        onChange={(e) => {
                          dispatch(
                            setBillSettlement({
                              ...billSettelmentFields,
                              epaymentAmount: e.target.value,
                            })
                          );
                          if (
                            Number(billSettelmentFields.btcAmount) +
                              Number(billSettelmentFields.cashAmount) +
                              Number(billSettelmentFields.cardAmount) +
                              Number(e.target.value) -
                              Number(netAmount) >
                            0
                          ) {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                epaymentAmount: '',
                              })
                            );
                            dispatch(
                              setAlertWithTitle({
                                title: 'Error',
                                msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                              })
                            );
                          }
                        }}
                        placeholder="ePayment"
                        style={{
                          outline: 'none',
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                {header === 'AMOUNT' ? (
                  <div
                    style={{
                      border: '1px solid #383535',
                      borderTop: '0px solid #383535  ',
                      borderRight: '1px solid #383535',

                      flex: 1,
                    }}
                    className={`${
                      isDelivery ? '' : 'rounded-br-sm '
                    } p-1  flex  justify-end text-end l:pr-3`}
                  >
                    {billSettelmentFields.epaymentType ? (
                      billSettelmentFields.epaymentType
                    ) : (
                      <div className="text-[#a0a1a1]">ePayment Type</div>
                    )}
                    {billSettelmentFields.epaymentType ? (
                      <button
                        style={{
                          border: '1px dotted orange',
                        }}
                        onClick={() => {
                          dispatch(
                            setBillSettlement({
                              ...billSettelmentFields,
                              epaymentType: '',
                            })
                          );
                          setEPaymentTypeState('');
                        }}
                        className="text-[10px] ml-2 px-1 rounded-sm l:text-sm text-orange flex items-center"
                      >
                        <i className="bi bi-x text-[16px] flex items-center"></i>
                      </button>
                    ) : null}
                    <button
                      style={{
                        border: '1px dotted orange ',
                      }}
                      onClick={() => {
                        if (!BillingAdvanceAmount) {
                          setEPaymentModStatus(true);
                        }
                      }}
                      className="flex ml-3 rounded-sm pl-2"
                    >
                      <i
                        style={{
                          fontSize: '13px',
                        }}
                        // onClick={() => setCustomerModalStatus(true)}
                        className="bi text-red  font-semibold  mr-2 bi-search"
                      ></i>
                    </button>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      )}

      {isDelivery && (
        <div className=" w-full flex ">
          {columns.map((item, index) => {
            const header = item.field;
            return (
              <div
                key={index}
                className="  text-black flex justify-end  items-center "
                style={
                  item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
                }
              >
                {header === 'BOOKING_ORDER_NO' ? (
                  <div
                    style={{
                      border: '1px solid #383535',
                      borderTop: '0px solid #383535',
                      borderLeft: '1px solid #383535',
                      borderRight: '0px solid #383535',

                      width: columns[index + 1].width + columns[index].width,
                    }}
                    className=" text-[#383535] rounded-bl-sm"
                  >
                    <div className="w-full flex justify-between">
                      <div
                        style={{
                          borderRight: '1px solid #383535',
                        }}
                        className="w-[60%] flex justify-start  p-1 pl-3"
                      >
                        <input
                          className="w-full"
                          type="text"
                          readOnly={BillingAdvanceAmount ? true : false}
                          value={
                            billSettelmentFields.cardAmount === 0
                              ? ''
                              : billSettelmentFields.cardAmount
                          }
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                cardAmount: e.target.value,
                              })
                            );
                            if (
                              Number(billSettelmentFields.btcAmount) +
                                Number(billSettelmentFields.epaymentAmount) +
                                Number(billSettelmentFields.cashAmount) +
                                Number(e.target.value) -
                                Number(netAmount) >
                              0
                            ) {
                              dispatch(
                                setBillSettlement({
                                  ...billSettelmentFields,
                                  cardAmount: '',
                                })
                              );
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Error',
                                  msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                                })
                              );
                            }
                          }}
                          placeholder="Card Amount"
                          style={{
                            outline: 'none',
                          }}
                        />
                      </div>

                      <div className="flex p-1 flex-1 justify-end  pr-3">
                        <input
                          type="text"
                          ref={cardNumberref}
                          placeholder="Card No."
                          readOnly={BillingAdvanceAmount ? true : false}
                          value={
                            billSettelmentFields.cardNumber === 0
                              ? ''
                              : billSettelmentFields.cardNumber
                          }
                          onChange={(e) => {
                            dispatch(
                              setBillSettlement({
                                ...billSettelmentFields,
                                cardNumber: e.target.value,
                              })
                            );
                          }}
                          style={{
                            outline: 'none',
                          }}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* {header === 'ITEM_NAME' ? (
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="w-full text-right text-red font-semibold p-2"
                >
                  1300.00
                </div>
              ) : null} */}

                {header === 'QNTY' ? (
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                    }}
                    className=" h-full items-center flex justify-end "
                  >
                    <div
                      style={{
                        width: columns[index].width + columns[index + 1].width,
                        borderRight: '0px solid #383535',
                        borderLeft: '1px solid #383535',
                        borderBottom: '1px solid #383535',
                      }}
                      className=" h-full flex items-center justify-end font-semibold   "
                    >
                      <button
                        variant="contained"
                        onClick={() => {
                          if (billCartList[0]) {
                            saveBillAPI();
                          } else {
                            const isAnyDataToSave = bill_delevery_cart.filter(
                              (o) => o.delivery === true
                            );
                            if (isAnyDataToSave[0]) {
                              saveStageWithEmployee();
                            } else {
                              if (bill_delevery_cart[0]) {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: 'Please select action to perform',
                                  })
                                );
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: 'No data found to save',
                                  })
                                );
                              }
                            }
                          }
                        }}
                        className="bg-green"
                        style={{
                          textTransform: 'none',
                          letterSpacing: '1px',
                          padding: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <div className="   ">Save </div>
                      </button>
                    </div>
                  </div>
                ) : null}

                {header === 'AMOUNT' ? (
                  <div
                    style={{
                      border: '1px solid #383535',
                      borderTop: '0px solid #383535  ',
                      borderRight: '1px solid #383535',

                      flex: 1,
                    }}
                    className="  rounded-br-sm font-semibold text-end h-full "
                  ></div>
                ) : null}
              </div>
            );
          })}
        </div>
      )}

      {isDelivery && (
        <div className="hidden print:flex">
          {billNumber && (
            <ViewBillImage
              ref={thermalRef}
              billInvoice={billNumber}
              list={saveBillCartList}
              billSettlement={[
                {
                  cash_amount: billSettelmentFields.cashAmount,
                  card_amount: billSettelmentFields.cardAmount,
                  btc_amount: billSettelmentFields.btcAmount,
                  epayment: billSettelmentFields.epaymentAmount,
                },
              ]}
              date={billingCartList[0].DATE}
            />
          )}
        </div>
      )}

      {isDelivery && (
        <ModEPayment
          status={ePaymentModStatus}
          setStatus={setEPaymentModStatus}
          ePaymentType={ePaymentTypeState}
          setEPaymentType={setEPaymentTypeState}
        />
      )}

      <ModItemMasterHelp
        status={itemMasterStatus}
        setStatus={setItemmasterStatus}
        onItemMasterRowClick={onItemMasterRowClick}
      />
    </div>
  );
};

export const VoucherSummaryFooter = (props) => {};
