import React, { useEffect, useRef, useState } from 'react';

const PlaceHolderTextInput = ({
  onKeyDown,
  align,
  autoFill,
  fontWieght,
  onFocus,
  onBlur,
  capture,
  onClick,
  reffrence,
  id,
  type,
  placeholder,
  value,
  onChange,
  icon,
  onDoubleClick,
  rightIcon,
  borderColor,
  readOnly,
  autoFocus,
  onLostFocus,
  onClear,
}) => {
  const [border, setBorder] = useState('#A09E9E');
  const [bgcolor, setBgcolor] = useState('white');
  const [isFocus, setIsFocus] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(null);
  useEffect(() => {
    if (onLostFocus) {
      return () => {
        if (typingTimeout) clearTimeout(typingTimeout);
      };
    }
    // Cleanup function to clear timeout if component unmounts
  }, [typingTimeout]);
  const inputRef = useRef();
  return (
    <div
      style={{
        border: borderColor
          ? `1px solid ${borderColor}`
          : `1px solid ${border}`,
        backgroundColor: bgcolor,
      }}
      onClick={() => {
        if (inputRef?.current) {
          inputRef.current.focus();
        }
      }}
      className="p-2 rounded-sm flex-1 flex items-center  "
    >
      {!icon && (
        <div className="mr-1  w-[110px] text-[10px] t:text-[14px] flex justify-between">
          <div>
            {`${
              placeholder.includes('Name')
                ? 'Name'
                : placeholder.includes('Mobile')
                ? 'Mobile'
                : placeholder.includes('Cash')
                ? 'Cash'
                : placeholder
            }   ${' '} `}
          </div>
          <div>:</div>
        </div>
      )}
      {icon && icon}
      <div
        style={{
          width: `calc(100% - 100px)`,
        }}
        className="flex ml-1"
      >
        <input
          style={{
            textAlign: isFocus ? (align ? align : 'left') : 'left',
            // width: `calc(100% - ${rightIcon ? 20 : 0}px`,
            fontWeight: fontWieght ? fontWieght : 'normal',
          }}
          onClick={onClick ? onClick : () => console.log('')}
          autoFocus={autoFocus ? true : false}
          id={id ? id : ''}
          onDoubleClick={onDoubleClick}
          onBlur={(t) => {
            setBorder('#A09E9E');
            setBgcolor('white');
            setIsFocus(false);
            if (onBlur) {
              onBlur(t);
            }
          }}
          onFocus={(t) => {
            setIsFocus(true);
            setBorder('#43bff7');
            setBgcolor('white');
            if (onFocus) {
              onFocus(t);
            }
          }}
          capture={capture}
          ref={reffrence ? reffrence : inputRef}
          className="outline-none flex-1 text-[12px] t:text-[14px] flex items-center"
          onKeyDown={
            onKeyDown
              ? (e) => {
                  onKeyDown(e);
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                  }
                }
              : (e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                  }
                }
          }
          readOnly={readOnly ? readOnly : false}
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          onChange={(t) => {
            if (onLostFocus) {
              if (typingTimeout) {
                clearTimeout(typingTimeout);
              }

              setTypingTimeout(
                setTimeout(() => {
                  onLostFocus(t);
                }, 1000)
              );
            }

            onChange(t);
          }}
        />
        <div className="w-[40px]">
          {onClear && value && (
            <button onClick={onClear}>
              <i className="bi bi-x-lg text-red"></i>
            </button>
          )}
        </div>
      </div>

      {rightIcon && rightIcon}
    </div>
  );
};

export default PlaceHolderTextInput;
