import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Close, Remove } from '@mui/icons-material';

import { Button } from '@mui/material';

import { useEffect } from 'react';
import {
  selectIssueItem,
  selectNumerickeyboardStatus,
  selectNumerickeyboardType,
  setnumericKeyboardStatus,
} from '../../Redux/modal';

const NumericKeyboard = ({ onChange, mode, extraButtons }) => {
  const defaultQnty = [
    '1',
    '2',
    '3',
    'Clear',
    '4',
    '5',
    '6',
    'Ok',
    '7',
    '8',
    '9',
    'Del',
    '',
    '0',
    '.',
    '',
  ];

  const numericType = useSelector(selectNumerickeyboardType);
  const issueItemDetails = useSelector(selectIssueItem);
  const [text, setText] = useState('');
  const [defaultText, setDefaultText] = useState('0');
  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultText(issueItemDetails.value);
  }, []);

  const modalStatus = useSelector(selectNumerickeyboardStatus);

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key;

      // Handle numeric keys, '.', 'Backspace', and 'Enter'
      if (/^\d$/.test(key) || key === '.' || key === 'Backspace') {
        // Handle Backspace separately
        if (key === 'Backspace') {
          setText((p) => {
            if (p !== '0') {
              if (p.toString().length === 1) {
                return '0';
              } else {
                return p.toString().slice(0, -1);
              }
            } else {
              return '0';
            }
          });
        } else {
          setText((prevText) => prevText + key);
        }
      }
    };

    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (modalStatus) {
      const textInput = document.getElementById('text');
      console.log(textInput);
      setTimeout(() => {
        if (textInput) {
          textInput.select();
          textInput.blur();
        }
      }, 300);
    }
  }, [modalStatus]);
  return (
    <div
      className={` pb-2   ${mode === 'dark' ? 'bg-[#181717]' : 'bg-white'}  `}
    >
      <div className="flex  justify-between ">
        <div
          className={`w-full text-[14px] flex justify-start pl-2 items-center ${
            mode === 'dark' ? 'text-white bg-[#242323] ' : 'text-red-500'
          } font-semibold`}
        >
          {issueItemDetails.title}
        </div>
        <button
          onClick={() => {
            dispatch(setnumericKeyboardStatus(false));
          }}
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
          className={` ${mode === 'dark' ? 'bg-white' : 'bg-red-500'}`}
        >
          <Close
            style={{
              color: mode === 'dark' ? 'black' : 'white',
            }}
          />
        </button>
      </div>
      {/* <div className="text-sm text-[#fcf8f885] pt-1 pl-2 pb-3">
        {numericType}
      </div> */}
      <div className=" flex  justify-center  pb-3 pt-3">
        <div className="flex items-center min-w-[300px] rounded-sm  ">
          <div className=" items-center px-2  rounded-sm   hover:cursor-pointer font-semibold">
            <Remove
              fontSize="medium"
              sx={{
                color: mode === 'dark' ? '#FFFFFF' : 'orange',
              }}
              onClick={() => {
                setText((p) => {
                  if (Number(p) > 0) {
                    const t = Number(p) - Number(1);
                    return t.toString();
                  } else {
                    return p.toString();
                  }
                });
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: '#242323',
              boxShadow: '0px 0px 1px #5A5858',
              padding: '0px',
              width: '100%',
            }}
          >
            {' '}
            <div className=" px-2 py-1 text-[20px] justify-center flex w-full   text-center text-white">
              <input
                onFocus={(e) => e.preventDefault()}
                value={text ? text : defaultText}
                className="w-full text-center bg-transparent"
                type={'number'}
                id={'text'}
                onChange={(e) => {
                  setText(e.target.value);
                  setDefaultText('');
                }}
              />
            </div>
          </div>
          <div className=" border-slate-200 px-2 rounded-sm  hover:cursor-pointer">
            <Add
              fontSize="medium"
              sx={{
                color: mode === 'dark' ? '#FFFFFF' : 'orange',
              }}
              onClick={() => {
                setDefaultText('');
                setText((p) => {
                  const t = Number(p) + Number(1);
                  return t.toString();
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2  px-2">
        {defaultQnty.map((item, index) => {
          return (
            <div key={index} className="w-full">
              {item !== 'Clear' &&
              item !== 'Ok' &&
              item !== '.' &&
              item !== '' &&
              item !== 'Del' ? (
                <Button
                  style={{
                    backgroundColor: mode === 'dark' ? '#242323' : 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    padding: '0px',
                    color: mode === 'dark' ? 'white' : 'black',
                  }}
                  onClick={() => {
                    setDefaultText('');

                    setText((p) => {
                      if (p === '0' || p === 0) {
                        return item;
                      } else {
                        return p + item;
                      }
                    });
                  }}
                >
                  <div className=" px-1 py-1  justify-between flex w-full text-center">
                    <div className="text-center  w-full text-[23px]">
                      {' '}
                      {item}{' '}
                    </div>
                  </div>
                </Button>
              ) : item === 'Clear' ? (
                <Button
                  style={{
                    backgroundColor: mode === 'dark' ? '#171616' : 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    height: '100%',

                    padding: '0px',
                    textTransform: 'none',
                    textDecoration: 'none',
                  }}
                  onClick={() => {
                    setDefaultText('');

                    setText((p) => {
                      if (p !== '0') {
                        if (p.toString().length === 1) {
                          return '0';
                        } else {
                          return p.toString().slice(0, -1);
                        }
                      } else {
                        return '0';
                      }
                    });
                  }}
                >
                  <div className="  py-1  justify-between flex w-full text-center">
                    <div
                      className={`text-center  w-full text-[18px]  ${
                        mode === 'dark' ? 'text-white' : 'text-red-500'
                      }`}
                    >
                      {' '}
                      <i className="bi bi-backspace"></i>
                    </div>
                  </div>
                </Button>
              ) : item === 'Ok' ? (
                <Button
                  style={{
                    backgroundColor: mode === 'dark' ? '#171616' : 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    height: '100%',

                    padding: '0px',
                    textTransform: 'none',
                    textDecoration: 'none',
                  }}
                  onClick={() => {
                    onChange({
                      field: numericType,
                      value: text ? text : 0,
                      id: issueItemDetails.id,
                    });
                    dispatch(setnumericKeyboardStatus(false));
                  }}
                >
                  <div className="  py-1  justify-between flex w-full text-center">
                    <div
                      className={`text-center  w-full text-[18px]  ${
                        mode === 'dark' ? 'text-green' : 'text-red-500'
                      }`}
                    >
                      {' '}
                      Ok
                    </div>
                  </div>
                </Button>
              ) : item === '.' ? (
                <Button
                  style={{
                    backgroundColor: mode === 'dark' ? '#171616' : 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    height: '100%',

                    padding: '0px',
                    textTransform: 'none',
                    textDecoration: 'none',
                  }}
                  onClick={() => {
                    setDefaultText('');

                    setText((p) => {
                      if (p === '0' || p === 0) {
                        return item;
                      } else {
                        return p + item;
                      }
                    });
                  }}
                >
                  <div className="  py-1  justify-between flex w-full text-center">
                    <div
                      className={`text-center  w-full text-[18px]  ${
                        mode === 'dark' ? 'text-white' : 'text-red-500'
                      }`}
                    >
                      {' '}
                      .
                    </div>
                  </div>
                </Button>
              ) : item === '' ? (
                <Button
                  style={{
                    backgroundColor: mode === 'dark' ? '#171616' : 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    height: '100%',

                    padding: '0px',
                    textTransform: 'none',
                    textDecoration: 'none',
                  }}
                >
                  <div className="  py-1  justify-between flex w-full text-center">
                    <div
                      className={`text-center  w-full text-[18px]  ${
                        mode === 'dark' ? 'text-white' : 'text-red-500'
                      }`}
                    >
                      {' '}
                    </div>
                  </div>
                </Button>
              ) : (
                item === 'Del' && (
                  <Button
                    style={{
                      backgroundColor: mode === 'dark' ? '#171616' : 'white',
                      boxShadow: '0px 0px 1px #5A5858',
                      height: '100%',

                      padding: '0px',
                      textTransform: 'none',
                      textDecoration: 'none',
                    }}
                    onClick={() => {
                      setDefaultText('');

                      setText(0);
                    }}
                  >
                    <div className="  py-1  justify-between flex w-full text-center">
                      <div
                        className={`text-center  w-full text-[18px]  ${
                          mode === 'dark' ? 'text-white' : 'text-red-500'
                        }`}
                      >
                        {' '}
                        <i className="bi bi-x-square"></i>
                      </div>
                    </div>
                  </Button>
                )
              )}
            </div>
          );
        })}
      </div>
      {/* <div className="w-full flex justify-center py-5 ">
        <OkNumericButton
          onClick={() => {
            dispatch(setnumericKeyboardStatus(false));
            onChange({
              field: editType,
              value: text,
              id: issueItemDetails.ICODE,
            });
          }}
        />
      </div> */}
      <div className="px-2">
        {numericType?.toLowerCase().includes('qnty') && extraButtons}
        {numericType?.toLowerCase().includes('disq') &&
          !numericType?.toLowerCase().includes('all') &&
          extraButtons}
        {numericType?.toLowerCase().includes('sale') && extraButtons}
      </div>
    </div>
  );
};

export default NumericKeyboard;
