import React, { useEffect } from 'react';
import { useState } from 'react';
import CustomerInfo from './customerInfo';
import Measurements from './measurements';
import Billing from '../billing/billing';
import ModSlipMeasuremnt from '../utility/component/modals/slipMeasurment';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import moment from 'moment';
import {
  selectCompany,
  selectCompanyAddress,
  selectIsProductionModuleAllwed,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useFocus } from '../utility/hooks/hooks';
import { CallAPI } from '../utility/func/func';
import { selectToken } from '../utility/Redux/security';
import { selectModyingBookingID } from '../utility/Redux/booking';
import { ProductionStatus } from './productionStatus';
import {
  selectBillSettlement,
  setBillSettlement,
} from '../utility/Redux/billing';

const Dashobard = () => {
  const localDate = localStorage.getItem('bookingDate');
  const isMergeHistory = localStorage.getItem('mergeHistory');
  const [tabScreen, SetTabScreen] = useState(1);
  const [mrcNumber, setMrcNumber] = useState('');
  const [isMrcDuplicate, setIsMrcDuplicate] = useState(false);
  const [bookingDate, setBookingDate] = useState(
    localDate
      ? moment(localDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  );
  const [history, setCustomerHistory] = useState([]);
  const [bookingOrderNumber, setBookingOrderNumber] = useState('');
  const [OrderNumberList, setOrderNumberList] = useState([]);
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [mobileRef, setMobileFocus] = useFocus();
  const [mobileRef2, setMobileFocus2] = useFocus();
  const [mobileRef3, setMobileFocus3] = useFocus();
  const [custmoerList, setCustomerlist] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [path, setpath] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const [editMobileEnabled, setEditMobileEnabled] = useState(true);
  const [isMrcFound, setIsMrcFound] = useState(false);
  const [openYear, setOpenYear] = useState(moment().format('YYYY'));
  const [mergehistory, setMergeHistory] = useState(
    isMergeHistory ? isMergeHistory : 'Y'
  );
  const ModifiedBookingId = useSelector(selectModyingBookingID);

  const [bookingCustomerDetails, setBookingCustomerDetails] = useState({
    code: '',
    customer_name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    credit_limit: 0,
    company_name: '',
    company_phone: '',
    company_email: '',
    company_address: '',
    company_city: '',
    company_state: '',
    company_country: '',
    company_pincode: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    status: 'Good',
    remarks: '',
    prefix: '',
    mrc_no: '',
    old_mobile: '',
  });

  const [customerInfo, setCustomerInfo] = useState({
    mobile: '',
    CusName: '',
    CusStatus: 'NO',
    Email: '',
    Phone: '',
    Address: '',
    City: '',
    State: '',
    Country: '',
    PinCode: '',
    CredLimit: '',
    Disq: '',
  });
  const isProductionAllow = useSelector(selectIsProductionModuleAllwed);
  const [customerPrifixList, setCustomerPrrefixList] = useState([]);
  const dispatch = useDispatch();

  const masterprefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);
  useEffect(() => {
    dispatch(setSpinnerLoading('Loading prefix'));
    CallAPI(
      '/api/masters/listPrefixMaster',
      {
        table_prefix_master: masterprefix,
      },
      token,
      (e) => {
        if (!e.data.error) {
          setCustomerPrrefixList(
            e.data.response.map((e) => {
              return e.PREFIX_NAME;
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  }, []);
  return (
    <div className=" w-full h-full flex flex-col bg-transparent p-4">
      <div className="flex w-full   ">
        <div
          onClick={() => {
            SetTabScreen(1);
            setBookingOrderNumber('');
            setOrderNumberList([]);
          }}
          style={{
            borderLeft:
              tabScreen === 1 ? '1px solid #b5b3b3' : '1px solid #f2f5f3',
            borderTop:
              tabScreen === 1 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
            borderBottom:
              tabScreen === 1 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
            borderRight:
              tabScreen === 1 ? '1px solid #b5b3b3' : '0px solid #c8ccc9',
            color: tabScreen === 1 ? 'black' : '#a8a3a3',
            fontWeight: tabScreen === 1 ? 600 : 400,
            backgroundColor: tabScreen === 1 ? 'white' : '#faf4ed',
            marginTop: tabScreen === 1 ? '0px' : '4px',
          }}
          className="w-1/4  hover:cursor-pointer font-roboto flex justify-center p-2 rounded-t-sm text-[16px] items-center  "
        >
          <div className="w-[90%] flex justify-center"> Customer Info</div>
          <div className="flex w-[10%] justify-end mr-5">
            {' '}
            <i className="bi bi-1-circle-fill"></i>
          </div>
        </div>

        <div
          onClick={() => {
            if (isMrcDuplicate) {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Duplicate Mrc No. not allowed.',
                })
              );
            } else {
              if (invalidMobile) {
                setMobileFocus();

                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Invalid mobile number',
                  })
                );
              } else {
                if (bookingCustomerDetails.mobile?.length >= 10) {
                  if (!bookingCustomerDetails?.customer_name) {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Please enter customer name',
                      })
                    );
                    setMobileFocus();
                  } else {
                    SetTabScreen(2);
                    setBookingOrderNumber('');
                  }
                } else {
                  setMobileFocus();
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Please enter valid mobile number to continue',
                    })
                  );
                }
              }
            }
          }}
          style={{
            borderLeft:
              tabScreen === 2 ? '1px solid #b5b3b3' : '1px solid #f2f5f3',
            borderTop:
              tabScreen === 2 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
            borderBottom:
              tabScreen === 2 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
            borderRight:
              tabScreen === 2 ? '1px solid #b5b3b3' : '1px solid #e0dede',
            color: tabScreen === 2 ? 'black' : '#a8a3a3',
            fontWeight: tabScreen === 2 ? 600 : 400,
            backgroundColor: tabScreen === 2 ? 'white' : '#faf4ed',
            marginTop: tabScreen === 2 ? '0px' : '4px',
            lineHeight: '20px',
            letterSpacing: '0.5px',
          }}
          className="w-1/4   hover:cursor-pointer flex font-roboto justify-center p-2 rounded-t-sm text-[16px]"
        >
          <div className="w-[90%] flex justify-center"> Measurements</div>
          <div className="flex w-[10%] justify-end mr-5">
            {' '}
            <i className="bi bi-2-circle-fill"></i>
          </div>
        </div>
        <div
          onClick={() => {
            if (mrcNumber) {
              SetTabScreen(3);
              if (!ModifiedBookingId) {
                dispatch(
                  setBillSettlement({
                    cashAmount: '',
                    btcAmount: '',
                    epaymentAmount: '',
                    epaymentType: '',
                    cardAmount: '',
                    cardNumber: '',
                    Remarks: '',
                    clothAmount: '',
                    DISCOUNT_RATE: 0,
                    DISCOUNT_AMOUNT: 0,
                  })
                );
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'MRC not found.',
                })
              );
            }
          }}
          style={{
            borderRight:
              tabScreen === 3 ? '1px solid #b5b3b3' : '0px solid #f2f5f3',
            borderLeft:
              tabScreen === 3 ? '1px solid #b5b3b3' : '0px solid #c8ccc9',
            borderTop:
              tabScreen === 3 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
            borderBottom:
              tabScreen === 3 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
            color: tabScreen === 3 ? 'black' : '#a8a3a3',
            fontWeight: tabScreen === 3 ? 600 : 400,
            backgroundColor: tabScreen === 3 ? 'white' : '#faf4ed',
            marginTop: tabScreen === 3 ? '0px' : '4px',
            lineHeight: '20px',
            letterSpacing: '0.5px',
            // borderTopRightRadius: tabScreen === 1 ? '7px' : '0px',
            // marginRight: tabScreen === 3 ? '0px' : '4px',
          }}
          className="flex hover:cursor-pointer grow justify-center  p-2 text-[16px] font-roboto rounded-t-sm  "
        >
          <div className="w-[90%] flex justify-center"> Billing</div>
          <div className="flex w-[10%] justify-end mr-5">
            {' '}
            <i className="bi bi-3-circle-fill"></i>
          </div>
        </div>

        {isProductionAllow && (
          <div
            onClick={() => {
              if (mrcNumber) {
                SetTabScreen(4);
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'MRC not found',
                  })
                );
              }
            }}
            style={{
              borderRight:
                tabScreen === 4 ? '1px solid #b5b3b3' : '0px solid #f2f5f3',
              borderLeft:
                tabScreen === 4 ? '1px solid #b5b3b3' : '0px solid #c8ccc9',
              borderTop:
                tabScreen === 4 ? '1px solid #b5b3b3' : '0px solid #dee3e0',
              borderBottom:
                tabScreen === 4 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',
              color: tabScreen === 4 ? 'black' : '#a8a3a3',
              fontWeight: tabScreen === 4 ? 600 : 400,
              backgroundColor: tabScreen === 4 ? 'white' : '#faf4ed',
              marginTop: tabScreen === 4 ? '0px' : '4px',
              lineHeight: '20px',
              letterSpacing: '0.5px',
              // borderTopRightRadius: tabScreen === 1 ? '7px' : '0px',
              // marginRight: tabScreen === 4? '0px' : '4px',
            }}
            className="flex hover:cursor-pointer grow justify-center  p-2 text-[16px] font-roboto rounded-t-sm  "
          >
            <div className="w-[90%] flex justify-center"> Production</div>
            <div className="flex w-[10%] justify-end mr-5">
              {' '}
              <i className="bi bi-4-circle-fill"></i>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          border: '1px solid #b5b3b3',
          borderTop: '0px solid #b5b3b3',
          // boxShadow: '10px 10px 10px red',
          // borderTopRightRadius: tabScreen === 1 ? '7px' : '0px',
          // borderTopLeftRadius: tabScreen === 2 ? '7px' : '0px',
        }}
        className="flex flex-col rounded-b-sm grow pl-4 pr-4 pt-4 overflow-hidden bg-white "
      >
        {tabScreen === 1 ? (
          <CustomerInfo
            isMrcFound={isMrcFound}
            setIsMrcFound={setIsMrcFound}
            setIsMrcDuplicate={setIsMrcDuplicate}
            isMrcDuplicate={isMrcDuplicate}
            SetTabScreen={SetTabScreen}
            setOpenYear={setOpenYear}
            openYear={openYear}
            history={history}
            setCustomerHistory={setCustomerHistory}
            customerPrifixList={customerPrifixList}
            custmoerList={custmoerList}
            setCustomerlist={setCustomerlist}
            customerInfo={customerInfo}
            mergehistory={mergehistory}
            setMergeHistory={setMergeHistory}
            setCustomerInfo={setCustomerInfo}
            mrcNumber={mrcNumber}
            bookingDate={bookingDate}
            setBookingDate={setBookingDate}
            setMrcNumber={setMrcNumber}
            mobileRef={mobileRef}
            mobileRef2={mobileRef2}
            mobileRef3={mobileRef3}
            editMobileEnabled={editMobileEnabled}
            setEditMobileEnabled={setEditMobileEnabled}
            setMobileFocus={setMobileFocus}
            setMobileFocus2={setMobileFocus2}
            setMobileFocus3={setMobileFocus3}
            setSelectedFile={setSelectedFile}
            setpath={setpath}
            path={path}
            selectedFile={selectedFile}
            capturedImage={capturedImage}
            setCapturedImage={setCapturedImage}
            bookingCustomerDetails={bookingCustomerDetails}
            setBookingCustomerDetails={setBookingCustomerDetails}
          />
        ) : tabScreen === 2 ? (
          <Measurements
            isMrcDuplicate={isMrcDuplicate}
            setMrcNumber={setMrcNumber}
            mrcNumber={mrcNumber}
            setOpenYear={setOpenYear}
            setCustomerHistory={setCustomerHistory}
            SetTabScreen={SetTabScreen}
            bookingDate={bookingDate}
            bookingOrderNumber={bookingOrderNumber}
            setBookingOrderNumber={setBookingOrderNumber}
            setEditMobileEnabled={setEditMobileEnabled}
            setBookingDate={setBookingDate}
            OrderNumberList={OrderNumberList}
            setOrderNumberList={setOrderNumberList}
            mergehistory={mergehistory}
            bookingCustomerDetails={bookingCustomerDetails}
            setBookingCustomerDetails={setBookingCustomerDetails}
          />
        ) : tabScreen === 3 ? (
          <Billing
            mrcNumber={mrcNumber}
            setCustomerHistory={setCustomerHistory}
            SetTabScreen={SetTabScreen}
            setMrcNumber={setMrcNumber}
            setBookingDate={setBookingDate}
            OrderNumberList={OrderNumberList}
            setOrderNumberList={setOrderNumberList}
            bookingOrderNumber={bookingOrderNumber}
            bookingCustomerDetails={bookingCustomerDetails}
            setBookingCustomerDetails={setBookingCustomerDetails}
            setBookingOrderNumber={setBookingOrderNumber}
          />
        ) : (
          <ProductionStatus
            mrcNumber={mrcNumber}
            SetTabScreen={SetTabScreen}
            bookingCustomerDetails={bookingCustomerDetails}
          />
        )}
        <div className="flex justify-end h-[20px] ">
          {/* {tabScreen === 2 && <div></div>} */}
        </div>
      </div>
      <ModSlipMeasuremnt />
    </div>
  );
};

export default Dashobard;
