import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../Redux/modal';

const deleteWholeDatabase = (database) => {
  const deleteData = () => {
    const request = indexedDB.deleteDatabase(database);

    request.onsuccess = function () {
      console.log(`Database "${database}" deleted successfully`);
    };

    request.onerror = function (event) {
      console.error(
        `Error deleting database "${database}":`,
        event.target.error
      );
    };
  };

  deleteData();
};

const HandleIndexedDB = ({
  databaseName,
  storeName,
  dataToSave,
  callback,
  afterUpdate,
  deleteItem,
}) => {
  let version = 1;
  const request = indexedDB.open(databaseName);
  request.onsuccess = function (event) {
    const db = event.target.result;
    version = db.version;
    db.close();

    const openRequest = indexedDB.open(databaseName, version + 1);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        const objectStore = db.createObjectStore(storeName, {
          keyPath: 'id',
          autoIncrement: true,
        });
        if (afterUpdate) {
          afterUpdate();
        }
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      // If dataToSave is provided and the object store was just created, add initial data
      if (dataToSave && version === 1) {
        dataToSave.forEach((data) => {
          const addRequest = objectStore.add(data);
          addRequest.onsuccess = function () {};
          addRequest.onerror = function (error) {
            console.error('Error adding data:', error);
          };
        });
      }

      if (dataToSave && version > 1) {
        dataToSave.forEach((data) => {
          const request = objectStore.get(data.ICODE);

          request.onsuccess = function (event) {
            const existingData = event.target.result;
            if (existingData) {
              const updatedData = { ...existingData, ...data };
              const updateRequest = objectStore.put(updatedData);
              updateRequest.onsuccess = function () {};
              updateRequest.onerror = function (error) {
                console.error('Error updating data:', error);
              };
            } else {
              const addRequest = objectStore.add(data);
              addRequest.onsuccess = function () {};
              addRequest.onerror = function (error) {
                console.error('Error adding data:', error);
              };
            }
          };
        });
      }
      if (deleteItem) {
        const requestDelete = objectStore.delete(deleteItem);
        requestDelete.onsuccess = function () {
          console.log(`${deleteItem} deleted successfully`);
        };

        requestDelete.onerror = function (event) {
          console.error('Error deleting item');
        };
      }

      transaction.oncomplete = function () {
        db.close();
      };

      if (callback) {
        const request = objectStore.getAll();

        request.onsuccess = function (event) {
          const data = event.target.result;
          const retrievedColumns =
            data.length > 0
              ? Object.keys(data[0]).map((key) => ({
                  field: key,
                  headerName: key,
                }))
              : [];

          callback(data, retrievedColumns);

          db.close();
        };
      }
    };

    openRequest.onerror = function (event) {
      console.error('IndexedDB error:', event.target.error);
    };
  };
};

const CallAPI = (path, data, token, then, dispatch, final, c) => {
  axios
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((t) => {
      then(t);
      if (t?.data?.error) {
        console.log('err');
        dispatch(setSpinnerLoading(false));
      }
    })
    .catch((error) => {
      if (c) {
        c(error);
      }
      dispatch(setSpinnerLoading(false));
      console.log(error);

      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .finally(final ? final : () => {});
};

const handleSignout = (dispatch, navigate) => {
  localStorage.removeItem('userType');
  // dispatch(setToken(''));
  // dispatch(setUserType(''));
  // dispatch(setLogin(false));

  localStorage.removeItem('stationName');
  localStorage.removeItem('stationId');
  localStorage.removeItem('billDetails');
  localStorage.removeItem('userName');
  localStorage.removeItem('count');
  localStorage.removeItem('tkn');
  localStorage.removeItem('company');
  localStorage.removeItem('histryId');
  localStorage.removeItem('prefix');
  localStorage.removeItem('expiresAt');
  localStorage.removeItem('orderdItemList');
  localStorage.removeItem('companyDetails');
  localStorage.removeItem('email');
  localStorage.removeItem('dev');
  // dispatch(resetTableOrder());
  // dispatch(resetProfile());
  // dispatch(resetModalReduxState());
  navigate('/auth/login');
  // dispatch(setCustomeTwoButtomAlert(false));
  window.location.reload();
};
export { deleteWholeDatabase, HandleIndexedDB, CallAPI, handleSignout };
