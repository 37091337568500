import { createSlice } from '@reduxjs/toolkit';

export const Modals = createSlice({
  name: 'modals',
  initialState: {
    loading: {
      status: false,
    },
    alert: {
      status: false,
      msg: '',
      okStatus: 0,
      code: '',
    },
    modalMeasurementMaster: {
      status: false,
    },
    isSessionExpired: false,

    checkEmailAlert: {
      status: false,
    },
    itemMaster: {
      status: false,
    },
    numercKeyBoradStatus: false,

    checkVerifyEmailAlert: {
      status: false,
    },
    customeAlertMsg: {
      status: false,
      title: '',
      msg: '',
      buttonText: 'Ok',
    },
    numericKeyBoardType: '',

    hsnCodeHelp: false,
    hsnMaster: {
      status: false,
    },
    issueStockItem: '',

    modUserList: {
      status: false,
    },
    sectionMasterHelp: false,
    modalStyleMaster: {
      status: false,
    },
    customeTwoButtonAlert: {
      status: false,
    },
    customerMaster: {
      status: false,
    },
    customerMasterHelp: {
      status: false,
    },
    itemMasterHelp: false,
    modWorkerMasterHelp: false,

    alertWithTitel: {
      status: false,
      title: '',
      msg: '',
      buttonText: 'Ok',
      lottie: '',
    },
    modalWorkerMaster: {
      status: false,
    },
    spinnerLoading: {
      status: false,
      text: 'loading',
    },
    modifyCustomerMasterModal: false,
    voucherSummary: false,
    modCompanyHelp: false,
    compyHelp: false,
  },
  reducers: {
    setloading(state, action) {
      state.loading.status = action.payload;
    },

    setVoucherSummaryModStatus(state, action) {
      state.voucherSummary = action.payload;
    },
    setModalWorkerMasterStatus(state, action) {
      state.modalWorkerMaster.status = action.payload;
    },
    setModalStyleMasterStatus(state, action) {
      state.modalStyleMaster.status = action.payload;
    },
    setModalMeasurementMasterStatus(state, action) {
      state.modalMeasurementMaster.status = action.payload;
    },
    setModCustomerMasterModifyStatus(state, action) {
      state.modifyCustomerMasterModal = action.payload;
    },
    setSpinnerLoading(state, action) {
      state.spinnerLoading.status = action.payload !== false ? true : false;
      state.spinnerLoading.text = action.payload;
    },
    setItemMasterHelpStatus(state, action) {
      state.itemMasterHelp = action.payload;
    },
    setHsnMasterStatus(state, action) {
      state.hsnMaster.status = action.payload;
    },
    setModUserListStatus(state, action) {
      state.modUserList.status = action.payload;
    },
    setSectionMasterHelpStatus(state, action) {
      state.sectionMasterHelp = action.payload;
    },
    setHsnCodeHelpStatus(state, action) {
      state.hsnCodeHelp = action.payload;
    },
    setModCompanyHelpStatus(state, action) {
      state.modCompanyHelp = action.payload;
    },
    setCompyHelpStatus(state, action) {
      state.compyHelp = action.payload;
    },
    setItemMasterStatus(state, action) {
      state.itemMaster.status = action.payload;
    },

    setModWorkerMasterHelpStatus(state, action) {
      state.modWorkerMasterHelp = action.payload;
    },
    setAlertWithTitle(state, action) {
      state.loading.status = false;
      state.alertWithTitel.title = action.payload.title;
      state.alertWithTitel.msg = action.payload.msg;
      state.alertWithTitel.status = true;
      state.alertWithTitel.lottie = action.payload.lottie;
      state.spinnerLoading.status = false;
      state.alertWithTitel.horizontal = action.payload.horizontal
        ? action.payload.horizontal
        : false;
    },
    setAlertwithTitleModalStatus(state, action) {
      state.loading.status = false;
      state.alertWithTitel.status = action.payload ? action.payload : false;
    },

    setCustomeAlertButtonText(state, action) {
      state.loading.status = false;
      state.customeAlertMsg.buttonText = action.payload;
    },

    setCustomerMasterModStatus(state, action) {
      state.loading.status = false;
      state.customerMaster.status = action.payload;
    },
    setCustomerMasterHelpModStatus(state, action) {
      state.customerMasterHelp.status = action.payload;
    },

    setCheckEmailAlert(state, action) {
      state.loading.status = false;
      state.checkEmailAlert.status = action.payload;
    },
    setCheckVerifyEmailAlert(state, action) {
      state.loading.status = false;
      state.checkVerifyEmailAlert.status = action.payload;
    },
    setnumericKeyboardType(state, action) {
      state.numericKeyBoardType = action.payload;
    },

    setALertStatus(state, action) {
      state.loading.status = false;
      state.alert.status = action.payload;
    },
    setCustomeAlertStatus(state, action) {
      state.customeAlertMsg.status = action.payload;
    },
    setAlertMsg(state, action) {
      state.loading.status = false;
      state.alert.msg = action.payload;
    },
    setCustomeAlertMsg(state, action) {
      state.loading.status = false;
      state.customeAlertMsg.msg = action.payload;
    },
    setCustomeAlertTitle(state, action) {
      state.loading.status = false;
      state.customeAlertMsg.title = action.payload;
    },
    setnumericKeyboardStatus(state, action) {
      state.numercKeyBoradStatus = action.payload;
    },
    setSessionExpired(state, action) {
      state.loading.status = false;

      state.isSessionExpired = action.payload;
    },
    setIssueStockItem(state, action) {
      state.issueStockItem = action.payload;
    },
    setAlertOkStatus(state, action) {
      state.alert.okStatus = action.payload;
    },
    setCustomeTwoButtomAlert(state, action) {
      state.loading.status = false;

      state.customeTwoButtonAlert.status = action.payload;
    },
  },
});

export default Modals.reducer;

export const {
  setCustomeAlertButtonText,
  setloading,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setModCustomerMasterModifyStatus,
  setCustomerMasterModStatus,
  setAlertOkStatus,
  setnumericKeyboardType,
  setSpinnerLoading,
  setModalWorkerMasterStatus,
  setCustomerMasterHelpModStatus,
  setCustomeTwoButtomAlert,
  setSessionExpired,
  setSectionMasterHelpStatus,
  setItemMasterStatus,
  setModCompanyHelpStatus,
  setCompyHelpStatus,
  setModalMeasurementMasterStatus,
  setHsnMasterStatus,
  setModUserListStatus,
  setVoucherSummaryModStatus,
  setCheckEmailAlert,
  setModWorkerMasterHelpStatus,
  setCheckVerifyEmailAlert,
  setHsnCodeHelpStatus,
  setALertStatus,
  setModalStyleMasterStatus,
  setAlertWithTitle,
  setItemMasterHelpStatus,
  setAlertwithTitleModalStatus,
  setAlertMsg,
  setCustomeAlertMsg,
  setCustomeAlertStatus,
  setCustomeAlertTitle,
} = Modals.actions;

export const selectStatus = (state) => state.modals.loading.status;
export const SelectModWorkerMasterHelpStatus = (state) =>
  state.modals.modWorkerMasterHelp;
export const selectCheckEmailStatus = (state) =>
  state.modals.checkEmailAlert.status;
export const selectModalStyleMasterStatus = (state) =>
  state.modals.modalStyleMaster.status;
export const selectNumerickeyboardStatus = (state) =>
  state.modals.numercKeyBoradStatus;
export const selectCheckVerifyEmailStatus = (state) =>
  state.modals.checkVerifyEmailAlert.status;
export const SelectAlertStatus = (state) => state.modals.alert.status;
export const selectAlertMsg = (state) => state.modals.alert.msg;
export const selectHsnMasterStatus = (state) => state.modals.hsnMaster.status;
export const selectModUserListStatus = (state) =>
  state.modals.modUserList.status;
export const selectAlertOkStatus = (state) => state.modals.alert.okStatus;
export const SelectHsnCodeHelpStatus = (state) => state.modals.hsnCodeHelp;
export const selectModCompanyHelpStatus = (state) =>
  state.modals.modCompanyHelp;
export const selectCompyHelpStatus = (state) => state.modals.CompyHelp;
export const selectCustomeAlertTitle = (state) =>
  state.modals.customeAlertMsg.title;
export const selectItemMasterStatus = (state) => state.modals.itemMaster.status;
export const selectModalMeasurementMasterStatus = (state) =>
  state.modals.modalMeasurementMaster.status;
export const selectSpinnerLoading = (state) =>
  state.modals.spinnerLoading.status;
export const selectSpinnerText = (state) => state.modals.spinnerLoading.text;

export const selectCustomeAlertStatus = (state) =>
  state.modals.customeAlertMsg.status;
export const selectCustomeAlertMsg = (state) =>
  state.modals.customeAlertMsg.msg;
export const selectCustomeAlertButton = (state) =>
  state.modals.customeAlertMsg.buttonText;
export const selectCustomeTwoButtomAlert = (state) =>
  state.modals.customeTwoButtonAlert.status;
export const SelectSectionMasterHelpStatus = (state) =>
  state.modals.sectionMasterHelp;
/// work new from here
export const SelectItemMasterHelpStatus = (state) =>
  state.modals.itemMasterHelp;
export const SelectToastStatus = (state) => state.modals.toast.status;

export const SelectToastMsg = (state) => state.modals.toast.msg;
export const SelectIsExpired = (state) => state.modals.isSessionExpired;
export const selectCustomerMasterModStatus = (state) =>
  state.modals.customerMaster.status;
export const selectCustomerMasterHelpModStatus = (state) =>
  state.modals.customerMasterHelp.status;
export const selectModalWorkerMasterStatus = (state) =>
  state.modals.modalWorkerMaster.status;

export const selectAlertWithTitleModalStatus = (state) =>
  state.modals.alertWithTitel.status;

export const selectAlertWithTitleDetails = (state) =>
  state.modals.alertWithTitel;

export const selectModifyCustomerMasterModStatus = (state) =>
  state.modals.modifyCustomerMasterModal;

export const selectVoucherSummaryModStatus = (state) =>
  state.modals.voucherSummary;
export const selectNumerickeyboardType = (state) =>
  state.modals.numericKeyBoardType;
export const selectIssueItem = (state) => state.modals.issueStockItem;
