import React, { useEffect } from 'react';
import { Save_button } from '../utility/component/buttons';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import {
  selectAdvanceVoucherDetails,
  selectBillSettlement,
  selectBillingAdvanceAmount,
  selectBillingCartList,
  selectModifyBillingCartlist,
  setAdvanceVoucherDetails,
  setBillSettlement,
  setBillingAdvanceAmount,
  setBillingCartList,
  setModifyBillcartList,
} from '../utility/Redux/billing';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useState } from 'react';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';

import ModEPayment from '../utility/component/modals/ePaymentType';
import './billsettlement.css';
import { useFocus } from '../utility/hooks/hooks';
import {
  selectCustomerPrefix,
  setCustomerFirebasePic,
  setHistoryOrderDetails,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setModifyingBookingId,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
} from '../utility/Redux/booking';
import moment from 'moment';
import AddToCart from './addToCart';

import ReactToPrint, { useReactToPrint } from 'react-to-print';
import BillDesign from '../utility/component/printBill';
import { useRef } from 'react';

import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { useNavigate } from 'react-router-dom';
import ViewBillImage from '../utility/component/modals/viewBill';
import { getMessageWallet, low_Bal_alert } from '../utility/func/common';
import UpdateProductionFirebase from '../utility/component/updateProductionFirebase';
import ModNumericKeyboard from '../utility/component/modals/modalNumeric';
import { FormatNumber2 } from '../utility/component/tools';
import PlaceHolderTextInput from '../utility/component/placeHolderTextInput';

const BillSettelment = ({
  SetTabScreen,
  setMrcNumber,
  setBookingDate,
  bookingCustomerDetails,
  setBookingCustomerDetails,
  mrcNumber,
  setCustomerHistory,
  orderID,
  bookingOrderNumber,
  setBookingOrderNumber,
  setOrderNumberList,
  OrderNumberList,
}) => {
  const [ePaymentModStatus, setEPaymentModStatus] = useState(false);
  const [saveBillCartList, setsaveBillCartList] = useState([]);
  const [ePaymentTypeState, setEPaymentTypeState] = useState('');
  const [printerType, setPrinterType] = useState('Thermal');
  const [billNumber, SetBillnumber] = useState('');
  const [cardNumberref, setCardNumberFocus] = useFocus();
  const componentRef = useRef();
  const thermalRef = useRef();
  const customerPrefix = useSelector(selectCustomerPrefix);
  const billSettelmentFields = useSelector(selectBillSettlement);
  const modifyBillingCartList = useSelector(selectModifyBillingCartlist);
  const billCart = useSelector(selectBillingCartList);
  const advanceVoucherDetails = useSelector(selectAdvanceVoucherDetails);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  // const [netAmount, setNetAmt] = useState(0);
  const billingCartList = modifyBillingCartList[0]
    ? modifyBillingCartList
    : billCart;
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalBillAmount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );

  const BillingAdvanceAmount = useSelector(selectBillingAdvanceAmount);

  const TotalCuttingTax1Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX1_AMOUNT),
    0
  );

  const TotalCuttingTax2Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX2_AMOUNT),
    0
  );

  const TotalCuttingTax3Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.CUTING_TAX3_AMOUNT),
    0
  );

  const TotalDisqAmt = billingCartList[0]
    ? billingCartList[0].DISCOUNT_AMOUNT
    : 0;
  const TotalSewingTax1Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX1_AMOUNT),
    0
  );

  const TotalSewingTax2Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX2_AMOUNT),
    0
  );

  const TotalSewingTax3Amount = billingCartList.reduce(
    (total, row) => Number(total) + Number(row.SEWING_TAX3_AMOUNT),
    0
  );

  const netAmount =
    totalBillAmount > 0
      ? Math.round(
          totalBillAmount -
            BillingAdvanceAmount +
            TotalCuttingTax1Amount +
            TotalCuttingTax2Amount +
            TotalCuttingTax3Amount +
            TotalSewingTax1Amount +
            TotalSewingTax2Amount +
            TotalSewingTax3Amount -
            TotalDisqAmt
        )
      : 0;

  useEffect(() => {
    if (modifyBillingCartList[0]) {
      let rowList = [];
      for (let index = 0; index < billingCartList.length; index++) {
        rowList.push({
          INVNO: billingCartList[index].INVNO,
          BOOKING_ORDER_NO: billingCartList[index].BOOKING_ORDER_NO,
          DATE: billingCartList[index].DATE
            ? moment(billingCartList[index].DATE).format('YYYY-MM-DD HH:mm:ss')
            : '',
          ICODE: billingCartList[index].ICODE,
          ITEM_NAME: billingCartList[index].ITEM_NAME,
          REGIONAL_INAME: billingCartList[index].REGIONAL_INAME,
          SECTION_NAME: billingCartList[index].SECTION_NAME,
          UPPER_LOWER: billingCartList[index].UPPER_LOWER,
          QNTY: billingCartList[index].QNTY,
          RATE: billingCartList[index].RATE,
          AMOUNT: billingCartList[index].AMOUNT,
          SEWING_HSN_CODE: billingCartList[index].SEWING_HSN_CODE,
          SEWING_TAX1_RATE: billingCartList[index].SEWING_TAX1_RATE,
          SEWING_TAX2_RATE: billingCartList[index].SEWING_TAX2_RATE,
          SEWING_TAX3_RATE: billingCartList[index].SEWING_TAX3_RATE,
          CUTTING_HSN_CODE: billingCartList[index].CUTTING_HSN_CODE,
          CUTING_TAX1_RATE: billingCartList[index].CUTING_TAX1_RATE,
          CUTING_TAX2_RATE: billingCartList[index].CUTING_TAX2_RATE,
          CUTING_TAX3_RATE: billingCartList[index].CUTING_TAX3_RATE,
          SEWING_TAX1_AMOUNT: billingCartList[index].SEWING_TAX1_AMOUNT,
          SEWING_TAX2_AMOUNT: billingCartList[index].SEWING_TAX2_AMOUNT,
          SEWING_TAX3_AMOUNT: billingCartList[index].SEWING_TAX3_AMOUNT,
          CUTING_TAX1_AMOUNT: billingCartList[index].CUTING_TAX1_AMOUNT,
          CUTING_TAX2_AMOUNT: billingCartList[index].CUTING_TAX2_AMOUNT,
          CUTING_TAX3_AMOUNT: billingCartList[index].CUTING_TAX3_AMOUNT,
          GTOTAL: netAmount,
          TOTAL: netAmount - BillingAdvanceAmount,
          MRC_NO: mrcNumber,
          PREFIX: customerPrefix,
          CUSTOMER_NAME: billingCartList[index].CUSTOMER_NAME,
          FAMILY_MEM_NAME: billingCartList[index].FAMILY_MEM_NAME,
          MOBILE: billingCartList[index].MOBILE,
          PHONE: billingCartList[index].PHONE,
          ADDRESS: billingCartList[index].ADDRESS,
          CITY: billingCartList[index].CITY,
          STATE: billingCartList[index].STATE,
          COUNTRY: billingCartList[index].COUNTRY,
          DISCOUNT_RATE: billingCartList[index].DISCOUNT_RATE,
          DISCOUNT_AMOUNT: billingCartList[index].DISCOUNT_AMOUNT,
          COMPANY_NAME: billingCartList[index].COMPANY_NAME,
          DELIVERED_YN: billingCartList[index].DELIVERED_YN,
          PICKUP_DATE: '',
          REMARKS: billingCartList[index].REMARKS,
          CUSTOMER_TYPE: billingCartList[index].CUSTOMER_TYPE
            ? billingCartList[index].CUSTOMER_TYPE
            : 'GOOD',
          LATITUDE: billingCartList[index].LATITUDE,
          LONGITITUDE: billingCartList[index].LONGITITUDE,
          ADVANCE_AMT: Number(BillingAdvanceAmount),
        });
      }

      setsaveBillCartList(rowList);
    } else {
      let rowList = [];
      for (let index = 0; index < billingCartList.length; index++) {
        rowList.push({
          ...billingCartList[index],
          GTOTAL:
            totalBillAmount -
            BillingAdvanceAmount +
            (TotalCuttingTax1Amount +
              TotalCuttingTax2Amount +
              TotalCuttingTax3Amount +
              TotalSewingTax1Amount +
              TotalSewingTax2Amount +
              TotalSewingTax3Amount -
              TotalDisqAmt),
          ADVANCE_AMT: Number(BillingAdvanceAmount),
          TOTAL: totalBillAmount,
          FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
            ? bookingCustomerDetails.family_mem_name
            : '',
        });
      }

      setsaveBillCartList(rowList);
    }
  }, [BillingAdvanceAmount, billingCartList]);
  const saveBillAPI = () => {
    const totalPaidAmount =
      Number(billSettelmentFields.cashAmount) +
      Number(billSettelmentFields.btcAmount) +
      Number(billSettelmentFields.epaymentAmount) +
      Number(billSettelmentFields.cardAmount);
    if (billingCartList[0] || modifyBillingCartList[0]) {
      if (Number(totalPaidAmount) === Number(netAmount)) {
        if (
          (billSettelmentFields.cardAmount &&
            !billSettelmentFields.cardNumber) ||
          (!billSettelmentFields.cardAmount && billSettelmentFields.cardNumber)
        ) {
          dispatch(
            setAlertWithTitle({
              title: 'Oops!',
              msg: 'Card amount or card Number error!',
            })
          );
          setCardNumberFocus();
        } else {
          if (
            (billSettelmentFields.epaymentAmount &&
              !billSettelmentFields.epaymentType) ||
            (billSettelmentFields.epaymentType &&
              !billSettelmentFields.epaymentAmount)
          ) {
            dispatch(
              setAlertWithTitle({
                title: 'Oops!',
                msg: `ePayment error!`,
              })
            );
          } else {
            if (modifyBillingCartList[0]) {
              const isinvoiceCodeEmptyAny = modifyBillingCartList.some(
                (item) => item.INVNO === ''
              );

              // check if incovice no. is not empty in any list its required
              if (!isinvoiceCodeEmptyAny) {
                if (defaultProfile?.BILL_EDITING === 'YES' ? true : false) {
                  modifyBillAPI();
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Not authorized to modify bill',
                    })
                  );
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Oops!',
                    msg: 'Something went wrong with Invoice Id',
                  })
                );
              }
              //
            } else {
              if (saveBillCartList[0].CUSTOMER_NAME) {
                const isCustomerType = saveBillCartList.some(
                  (item) => item.CUSTOMER_TYPE === ''
                );
                if (!isCustomerType) {
                  dispatch(setSpinnerLoading('Saving bill'));
                  axios
                    .post(
                      `/api/billing/saveBill`,
                      {
                        table_prefix: prefix,
                        cart_item: saveBillCartList,
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then(function (response) {
                      if (
                        response.data.affectedRows === billingCartList.length
                      ) {
                        billSettleMentAPI(
                          response.data.response.INVNO,
                          billingCartList[0].DATE
                        );
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Error code: 101',
                            msg: 'Something went wrong while saving your bill',
                          })
                        );
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      dispatch(setSpinnerLoading(false));
                      if (error.response.data.name === 'TokenExpiredError') {
                        dispatch(setSessionExpired(true));
                      }
                      dispatch(
                        setAlertWithTitle({
                          title: 'Error code: 102',
                          msg: 'Something went wrong while saving your bill',
                        })
                      );
                    });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error code: 116 ',
                      msg: 'Something went wrong with Customer Type',
                    })
                  );
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Error code: 110',
                    msg: 'Something wrong with Customer details.',
                  })
                );
              }
            }
          }
        }
      } else {
        if (Number(totalPaidAmount) <= Number(netAmount)) {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'The entered amount is less than the actual bill amount. Please enter a valid amount.',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
            })
          );
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Cannot save Empty Bill.',
        })
      );
    }
  };
  const defaultProfile = useSelector(selectDefultProfileSetting);

  const billSettleMentAPI = (invNo, date) => {
    const data = {
      table_prefix: prefix,
      INVNO: invNo,
      MRC_NO: mrcNumber,
      PREFIX: customerPrefix,
      BILL_AMOUNT: Math.round(netAmount),
      CASH_AMOUNT: Number(billSettelmentFields.cashAmount),
      CARD_AMOUNT: Number(billSettelmentFields.cardAmount),
      EPAYMENT: Number(billSettelmentFields.epaymentAmount),
      BTC_AMOUNT: Number(billSettelmentFields.btcAmount),
      EPAYMENT_TYPE: billSettelmentFields.epaymentType,
      CARD_NO: billSettelmentFields.cardNumber,
      ADVANCE_AMT: Number(BillingAdvanceAmount),
      DATE: date
        ? moment(date).format('YYYY-MM-DD')
        : moment(date).format('YYYY-MM-DD'),
      CLOTH_BALANCE: Number(billSettelmentFields.clothAmount),
    };
    axios
      .post(`/api/billing/saveBillSettlement`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          if (modifyBillingCartList[0]) {
            if (advanceVoucherDetails) {
              saveAdvanceVoucherAPI(invNo);
            } else {
              SetBillnumber(invNo);

              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: true,
                title: 'Success',
                msg: 'Data Modified successfully.',
                button1: 'Ok',

                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  setMrcNumber('');

                  if (printerType === 'Printer') {
                    handlePrint();
                  } else {
                    handleThermal();
                  }
                },
              });
            }
          } else {
            if (advanceVoucherDetails) {
              saveAdvanceVoucherAPI(invNo);
            } else {
              SetBillnumber(invNo);
            }
          }

          setBookingDate(
            moment(localStorage.getItem('bookingDate')).format('YYYY-MM-DD')
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error code: 103',
              msg: 'Something went wrong in saving bill settlement data.',
              lottie: 'reject',
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error code: 104',
              msg: 'Something went wrong in saving bill settlement data.',
              lottie: 'reject',
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const undo = () => {
    dispatch(setBillingCartList([]));
    dispatch(
      setBillSettlement({
        cashAmount: '',
        btcAmount: '',
        epaymentAmount: '',
        epaymentType: '',
        cardAmount: '',
        cardNumber: '',
        Remarks: '',
        clothAmount: '',
        DISCOUNT_RATE: 0,
        DISCOUNT_AMOUNT: 0,
      })
    );
    SetBillnumber('');
    dispatch(setModifyBillcartList([]));
    setCustomerHistory([]);
    dispatch(setModifyingBookingId(''));
    dispatch(setUpperMeasuremnt([]));
    dispatch(setLowerMeasuremnt([]));
    dispatch(setUpperMeasuremntList([]));
    dispatch(setLowerMeasuremntList([]));
    dispatch(setHistoryOrderDetails([]));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setModifyingBookingId(''));
    dispatch(setBillingAdvanceAmount(''));
    dispatch(setCustomerFirebasePic(''));
    dispatch(setAdvanceVoucherDetails(''));
  };

  const modifyBillAPI = () => {
    dispatch(setSpinnerLoading('Modifying'));

    axios
      .post(
        `/api/billing/modifyBill`,
        {
          table_prefix: prefix,
          cart_item: saveBillCartList,
        },

        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          billSettleMentAPI(
            saveBillCartList[0].INVNO,
            saveBillCartList[0].DATE
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        dispatch(
          setAlertWithTitle({
            title: 'Error code: 106',
            msg: 'Something went wrong while Updating your bill',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const handlePrint = useReactToPrint({
    content: () =>
      printerType === 'Printer' ? componentRef.current : thermalRef.current,
    onAfterPrint: () => {
      if (!orderID) {
        undo();
        setBookingCustomerDetails({
          code: '',
          customer_name: '',
          mobile: '',
          mobile_2: '',
          mobile_3: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: '',
          country: '',
          pin_code: '',
          credit_limit: 0,
          company_name: '',
          company_phone: '',
          company_email: '',
          company_address: '',
          company_city: '',
          company_state: '',
          company_country: '',
          company_pincode: '',
          regd_no1: '',
          regd_no2: '',
          regd_no3: '',
          status: '',
          remarks: '',
          prefix: '',
          mrc_no: '',
          old_mobile: '',
        });
        setMrcNumber('');
        dispatch(setHistoryOrderDetails(''));
        setAlertTwoButtonModal({
          title: 'Alert',
          msg: 'Bill Saved',
          status: true,
          setStatus: '',
          button1: 'Ok',
          button1Click: () => {
            SetTabScreen(1);
            setBookingOrderNumber('');
            setOrderNumberList([]);
          },
          button2: '',
          button2Click: '',
        });
      }
    },
  });

  const handleThermal = useReactToPrint({
    content: () => thermalRef.current,
    onAfterPrint: () => {
      undo();
      setBookingCustomerDetails({
        code: '',
        customer_name: '',
        mobile: '',
        mobile_2: '',
        mobile_3: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pin_code: '',
        credit_limit: 0,
        company_name: '',
        company_phone: '',
        company_email: '',
        company_address: '',
        company_city: '',
        company_state: '',
        company_country: '',
        company_pincode: '',
        regd_no1: '',
        regd_no2: '',
        regd_no3: '',
        status: '',
        remarks: '',
        prefix: '',
        mrc_no: '',
        old_mobile: '',
      });
      setMrcNumber('');
      dispatch(setHistoryOrderDetails(''));
      SetTabScreen(1);
      setBookingOrderNumber('');
      setOrderNumberList([]);
    },
  });

  const saveAdvanceVoucherAPI = (invNo) => {
    const data = {
      ...advanceVoucherDetails,
      INVNO: invNo,
      LATITUDE: '',
      LONGITUDE: '',
      table_prefix: prefix,
      ADDRESS: bookingCustomerDetails.address
        ? bookingCustomerDetails.address
        : '',
      APPROVED: 'NO',
      AMOUNT_DESC: `Bill No.${invNo}`,
      MRC_NO: mrcNumber,
      PREFIX: customerPrefix,
    };
    axios
      .post(`/api/vouchers/saveVoucher`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (response.data.result.affectedRows === 1) {
          SetBillnumber(invNo);
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'Success',
            msg: 'Data saved successfully.',
            button1: 'Ok',

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              if (printerType === 'Printer') {
                handlePrint();
              } else {
                handleThermal();
              }
            },
          });
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setAdvanceVoucherDetails(''));
      });
  };

  useEffect(() => {
    if (billNumber) {
      handlePrint();
    }
  }, [billNumber]);

  return (
    <div className="w-full rounded-sm h-full flex flex-col ">
      <div className="w-full flex flex-col grow ">
        <div className="w-full  h-full ">
          <AddToCart
            bookingCustomerDetails={bookingCustomerDetails}
            mrcNumber={mrcNumber}
            OrderNumberList={OrderNumberList}
            bookingOrderNumber={bookingOrderNumber}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            border: '1px solid #a6a5a2',
          }}
          className="bg-[#e8e2db] p-1 d:p-2 mt-1  rounded-sm flex justify-center w-full"
        >
          Bill Settlement [
          {netAmount -
            billSettelmentFields.cashAmount -
            billSettelmentFields.btcAmount -
            billSettelmentFields.epaymentAmount -
            billSettelmentFields.cardAmount}
          ]
        </div>
        <div className="flex w-full mt-2">
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className=" w-[49.5%]  flex rounded-sm px-1 dx:p-2 items-center"
          >
            <input
              className="w-[30%] py-1 d:py-2"
              type="text"
              readOnly={true}
              value={
                Number(billSettelmentFields?.DISCOUNT_RATE) === 0
                  ? ''
                  : billSettelmentFields?.DISCOUNT_RATE
                  ? 'Disq : ' + billSettelmentFields?.DISCOUNT_RATE + '%'
                  : ''
              }
              onClick={() => {
                if (!advanceVoucherDetails) {
                  dispatch(
                    setIssueStockItem({
                      title: 'Discount %',
                      id: '1',
                      value:
                        billSettelmentFields?.DISCOUNT_RATE !== 0
                          ? billSettelmentFields?.DISCOUNT_RATE
                          : 0,
                    })
                  );
                  dispatch(setnumericKeyboardType('DISCOUNT_RATE'));

                  dispatch(setnumericKeyboardStatus(true));
                }
              }}
              placeholder="Disq. %"
              style={{
                outline: 'none',
              }}
            />

            <input
              className="w-[70%] pl-2 border-l py-1 d:py-2"
              type="text"
              readOnly={true}
              value={
                Number(billSettelmentFields?.DISCOUNT_RATE) === 0
                  ? ''
                  : billSettelmentFields?.DISCOUNT_RATE
                  ? 'Disq. Amt. : ' + billSettelmentFields?.DISCOUNT_AMOUNT
                  : ''
              }
              onClick={() => {
                if (!advanceVoucherDetails) {
                  dispatch(
                    setIssueStockItem({
                      title: 'Discount Amt.',
                      id: '1',
                      value:
                        billSettelmentFields?.DISCOUNT_AMOUNT !== 0
                          ? billSettelmentFields?.DISCOUNT_AMOUNT
                          : 0,
                    })
                  );
                  dispatch(setnumericKeyboardType('DISCOUNT_AMOUNT'));

                  dispatch(setnumericKeyboardStatus(true));
                }
              }}
              placeholder="Disq. Amt."
              style={{
                outline: 'none',
              }}
            />
            <button
              onClick={() => {
                if (!advanceVoucherDetails) {
                  dispatch(
                    setIssueStockItem({
                      title: 'Discount Amt.',
                      id: '1',
                      value:
                        billSettelmentFields?.DISCOUNT_AMOUNT !== 0
                          ? billSettelmentFields?.DISCOUNT_AMOUNT
                          : 0,
                    })
                  );
                  dispatch(setnumericKeyboardType('DISCOUNT_AMOUNT'));

                  dispatch(setnumericKeyboardStatus(true));
                }
              }}
            >
              <i className="bi bi-calculator"></i>
            </button>
          </div>

          <div className=" w-[49.5%] ml-2  rounded-sm">
            <PlaceHolderTextInput
              type={'number'}
              placeholder="Cash Amount"
              value={
                billSettelmentFields.cashAmount === 0
                  ? ''
                  : billSettelmentFields.cashAmount
              }
              onDoubleClick={() => {
                if (!BillingAdvanceAmount) {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      cashAmount: netAmount,
                    })
                  );
                }
              }}
              readOnly={BillingAdvanceAmount ? true : false}
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cashAmount: e.target.value,
                  })
                );
                if (
                  Number(billSettelmentFields.btcAmount) +
                    Number(billSettelmentFields.epaymentAmount) +
                    Number(billSettelmentFields.cardAmount) +
                    Number(e.target.value) -
                    Number(netAmount) >
                  0
                ) {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      cashAmount: '',
                    })
                  );
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error',
                      msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                    })
                  );
                }
              }}
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cashAmount: '',
                  })
                );
              }}
            />
          </div>
        </div>

        <div className="flex w-full mt-2">
          <div className=" w-[49.5%]   rounded-sm">
            <PlaceHolderTextInput
              type={'number'}
              value={
                billSettelmentFields.epaymentAmount === 0
                  ? ''
                  : billSettelmentFields.epaymentAmount
              }
              borderColor={
                !billSettelmentFields.epaymentAmount &&
                billSettelmentFields.epaymentType
                  ? 'red'
                  : '#A09E9E'
              }
              readOnly={BillingAdvanceAmount ? true : false}
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    epaymentAmount: e.target.value,
                  })
                );
                if (
                  Number(billSettelmentFields.btcAmount) +
                    Number(billSettelmentFields.cashAmount) +
                    Number(billSettelmentFields.cardAmount) +
                    Number(e.target.value) -
                    Number(netAmount) >
                  0
                ) {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      epaymentAmount: '',
                    })
                  );
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error',
                      msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                    })
                  );
                }
              }}
              placeholder="ePayment"
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    epaymentAmount: '',
                  })
                );
              }}
            />
          </div>
          <div
            // style={{
            //   border:
            //     billSettelmentFields.epaymentAmount &&
            //     !billSettelmentFields.epaymentType
            //       ? '1px solid #f5874f'
            //       : '1px solid #b5b3b3',
            // }}
            className=" flex w-[49.5%] ml-2   rounded-sm "
          >
            <PlaceHolderTextInput
              type={'text'}
              onClick={() => {
                if (!BillingAdvanceAmount) {
                  setEPaymentModStatus(true);
                }
              }}
              borderColor={
                billSettelmentFields.epaymentAmount &&
                !billSettelmentFields.epaymentType
                  ? 'red'
                  : '#A09E9E'
              }
              value={billSettelmentFields.epaymentType}
              placeholder="ePayment Type"
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    epaymentType: '',
                  })
                );
                setEPaymentTypeState('');
              }}
            />
            {/* <div className=" w-full flex justify-between items-center ">
              {billSettelmentFields.epaymentType ? (
                billSettelmentFields.epaymentType
              ) : (
                <div className="text-[#a0a1a1]">ePayment Type</div>
              )}
              <div className=" flex justify-end ">
                <button
                  onClick={() => {
                    if (!BillingAdvanceAmount) {
                      setEPaymentModStatus(true);
                    }
                  }}
                  className="flex mr-1 rounded-sm p-2 justify-center  items-center"
                >
                  <i
                    style={{
                      fontSize: '13px',
                    }}
                    // onClick={() => setCustomerModalStatus(true)}
                    className="bi text-red  font-semibold flex items-center   bi-search"
                  ></i>
                </button>
                {billSettelmentFields.epaymentType ? (
                  <button
                    style={{
                      border: '1px dotted orange',
                    }}
                    onClick={() => {
                      dispatch(
                        setBillSettlement({
                          ...billSettelmentFields,
                          epaymentType: '',
                        })
                      );
                      setEPaymentTypeState('');
                    }}
                    className="text-[10px] px-1 rounded-sm l:text-sm text-orange flex items-center"
                  >
                    <i className="bi bi-x text-[16px] flex items-center"></i>
                  </button>
                ) : null}
              </div>
            </div> */}
          </div>
        </div>

        <div className="flex w-full mt-2">
          <div className=" w-[49.5%]   rounded-sm">
            <PlaceHolderTextInput
              type={'number'}
              readOnly={BillingAdvanceAmount ? true : false}
              value={
                billSettelmentFields.cardAmount === 0
                  ? ''
                  : billSettelmentFields.cardAmount
              }
              borderColor={
                !billSettelmentFields.cardAmount &&
                billSettelmentFields.cardNumber
                  ? 'red'
                  : '#A09E9E'
              }
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cardAmount: e.target.value,
                  })
                );
                if (
                  Number(billSettelmentFields.btcAmount) +
                    Number(billSettelmentFields.epaymentAmount) +
                    Number(billSettelmentFields.cashAmount) +
                    Number(e.target.value) -
                    Number(netAmount) >
                  0
                ) {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      cardAmount: '',
                    })
                  );
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error',
                      msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                    })
                  );
                }
              }}
              placeholder="Card Amount"
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cardAmount: '',
                  })
                );
              }}
            />
          </div>
          <div
            // style={{
            //   border:
            //     billSettelmentFields.cardAmount &&
            //     !billSettelmentFields.cardNumber
            //       ? '1px solid #f5874f'
            //       : '1px solid #b5b3b3',
            // }}
            className=" flex w-[49.5%] ml-2   rounded-sm"
          >
            <PlaceHolderTextInput
              type="text"
              ref={cardNumberref}
              placeholder="Card No."
              readOnly={BillingAdvanceAmount ? true : false}
              value={
                billSettelmentFields.cardNumber === 0
                  ? ''
                  : billSettelmentFields.cardNumber
              }
              borderColor={
                billSettelmentFields.cardAmount &&
                !billSettelmentFields.cardNumber
                  ? 'red'
                  : '#A09E9E'
              }
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cardNumber: e.target.value,
                  })
                );
              }}
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    cardNumber: '',
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="flex w-full mt-2">
          <div className=" w-[49.5%]  rounded-sm">
            <PlaceHolderTextInput
              type="number"
              readOnly={BillingAdvanceAmount ? true : false}
              value={
                billSettelmentFields.clothAmount === 0
                  ? ''
                  : billSettelmentFields.clothAmount
              }
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    clothAmount: e.target.value,
                  })
                );
              }}
              placeholder="Cloth Amount"
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    clothAmount: '',
                  })
                );
              }}
            />
          </div>
          <div className=" flex w-[49.5%] ml-2   rounded-sm">
            <PlaceHolderTextInput
              type="number"
              value={
                billSettelmentFields.btcAmount === 0
                  ? ''
                  : billSettelmentFields.btcAmount
              }
              readOnly={BillingAdvanceAmount ? true : false}
              onChange={(e) => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    btcAmount: e.target.value,
                  })
                );
                if (
                  Number(billSettelmentFields.cashAmount) +
                    Number(billSettelmentFields.epaymentAmount) +
                    Number(billSettelmentFields.cardAmount) +
                    Number(e.target.value) -
                    Number(netAmount) >
                  0
                ) {
                  dispatch(
                    setBillSettlement({
                      ...billSettelmentFields,
                      btcAmount: '',
                    })
                  );
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error',
                      msg: 'The entered amount is greater than the actual bill amount. Please enter a valid amount.',
                    })
                  );
                }
              }}
              placeholder="BTC Amount"
              onClear={() => {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    btcAmount: '',
                  })
                );
              }}
            />
          </div>
        </div>

        <div className="flex justify-between w-full mt-2 ">
          <select
            id="selectOption"
            value={printerType}
            style={{
              outline: '0px',
              border: '1px dotted #b5b3b3 ',
            }}
            className="rounded-sm px-2"
            onChange={(e) => {
              setPrinterType(e.target.value);
            }}
          >
            <option value="option1">Thermal</option>
            <option value="option2">Laser</option>
          </select>

          <div className="flex ml-2">
            <Save_button onClick={saveBillAPI} />
            <Button
              // ref={updateRef}
              onClick={undo}
              variant="contained"
              style={{
                textTransform: 'none',
                letterSpacing: '1px',
                backgroundColor: '#616161',
                marginLeft: '8px',
              }}
            >
              <div className="text-white font-sans px-3 ">Undo </div>
            </Button>
          </div>
        </div>
        <ModEPayment
          status={ePaymentModStatus}
          setStatus={setEPaymentModStatus}
          ePaymentType={ePaymentTypeState}
          setEPaymentType={setEPaymentTypeState}
        />
      </div>
      <div>
        <ReactToPrint
          trigger={() => {
            return (
              <div className="hidden">
                <button
                  style={{
                    width: '100%',
                    borderRadius: '7px',
                    height: '100%',
                    backgroundColor: '#bad4f9',
                    color: 'black',
                  }}
                >
                  Print Report
                </button>
              </div>
            );
          }} // This is a dummy trigger since you don't want a button
          content={() => componentRef.current} // Reference to the component you want to print
        />
        <div className="hidden print:flex">
          {billNumber && (
            <ViewBillImage
              ref={thermalRef}
              billInvoice={billNumber}
              list={saveBillCartList}
              billSettlement={[
                {
                  cash_amount: billSettelmentFields.cashAmount,
                  card_amount: billSettelmentFields.cardAmount,
                  btc_amount: billSettelmentFields.btcAmount,
                  epayment: billSettelmentFields.epaymentAmount,
                },
              ]}
              date={saveBillCartList[0].DATE}
            />
            // <BillDesign
            //   ref={componentRef}
            //   billNumber={billNumber}
            //   itemList={
            //     modifyBillingCartList[0]
            //       ? modifyBillingCartList
            //       : billingCartList
            //   }
            //   bill={billSettelmentFields}
            //   total={netAmount}
            //   bookingCustomerDetails={bookingCustomerDetails}
            // />
          )}
        </div>
      </div>

      <div>
        <ReactToPrint
          trigger={() => {
            return (
              <div className="hidden">
                <button
                  style={{
                    width: '100%',
                    borderRadius: '7px',
                    height: '100%',
                    backgroundColor: '#bad4f9',
                    color: 'black',
                  }}
                >
                  Print thermal
                </button>
              </div>
            );
          }} // This is a dummy trigger since you don't want a button
          content={() => thermalRef.current}
        />
        <div className="hidden print:flex">
          {billNumber && (
            <ViewBillImage
              ref={thermalRef}
              billInvoice={billNumber}
              list={saveBillCartList}
              billSettlement={[
                {
                  cash_amount: billSettelmentFields.cashAmount,
                  card_amount: billSettelmentFields.cardAmount,
                  btc_amount: billSettelmentFields.btcAmount,
                  epayment: billSettelmentFields.epaymentAmount,
                },
              ]}
              date={billingCartList[0].DATE}
            />
          )}
        </div>
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ModNumericKeyboard
        onChange={(e) => {
          if (e.field === 'DISCOUNT_RATE') {
            const am = totalBillAmount;
            const disqAmount = (am * Number(e.value)) / 100;
            if (am > disqAmount) {
              dispatch(
                setBillSettlement({
                  ...billSettelmentFields,
                  DISCOUNT_RATE: e.value,
                  DISCOUNT_AMOUNT: disqAmount,
                })
              );
              if (!modifyBillingCartList[0]) {
                dispatch(
                  setBillingCartList(
                    billingCartList.map((o) => {
                      return {
                        ...o,
                        DISCOUNT_RATE: e.value,
                        DISCOUNT_AMOUNT: disqAmount,
                      };
                    })
                  )
                );
              } else {
                dispatch(
                  setModifyBillcartList(
                    modifyBillingCartList.map((o) => {
                      return {
                        ...o,
                        DISCOUNT_RATE: e.value,
                        DISCOUNT_AMOUNT: disqAmount,
                      };
                    })
                  )
                );
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'You Cannot give discount more than amount.',
                  horizontal: true,
                })
              );
            }
          } else {
            if (e.field === 'DISCOUNT_AMOUNT') {
              const am = totalBillAmount;
              const disqAmount = e.value;
              const disqRate = (e.value / totalBillAmount) * 100;
              if (am > disqAmount) {
                dispatch(
                  setBillSettlement({
                    ...billSettelmentFields,
                    DISCOUNT_RATE: disqRate.toFixed(2),
                    DISCOUNT_AMOUNT: disqAmount,
                  })
                );
                if (!modifyBillingCartList[0]) {
                  dispatch(
                    setBillingCartList(
                      billingCartList.map((o) => {
                        return {
                          ...o,
                          DISCOUNT_RATE: disqRate.toFixed(2),
                          DISCOUNT_AMOUNT: disqAmount,
                        };
                      })
                    )
                  );
                } else {
                  dispatch(
                    setModifyBillcartList(
                      modifyBillingCartList.map((o) => {
                        return {
                          ...o,
                          DISCOUNT_RATE: disqRate.toFixed(2),
                          DISCOUNT_AMOUNT: disqAmount,
                        };
                      })
                    )
                  );
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'You Cannot give discount more than amount.',
                    horizontal: true,
                  })
                );
              }
            }
          }
        }}
        editType={''}
        mode={'dark'}
      />
    </div>
  );
};

export default BillSettelment;
