import moment from 'moment';

import React from 'react';
import { useSelector } from 'react-redux';

import { selectCompanyAddress } from '../../Redux/profile';
import '../../../utility/styles/print.css';

import { FormatNumber2 } from '../tools';
import { toProperCase } from '../format';
import NumberToWords3 from '../functions/numbertToWords';

const ViewBillImage = React.forwardRef((props, ref) => {
  const { billSettlement, list, date, billInvoice } = props;
  const comapnyAddress = useSelector(selectCompanyAddress);
  const { address, city, companyName, email, mobile, state } = comapnyAddress;
  const totalSew1Tax = list.reduce(
    (acc, row) => acc + Number(row.SEWING_TAX1_AMOUNT),
    0
  );
  const totalSew2Tax = list.reduce(
    (acc, row) => acc + Number(row.SEWING_TAX2_AMOUNT),
    0
  );
  const totalSew3Tax = list.reduce(
    (acc, row) => acc + Number(row.SEWING_TAX3_AMOUNT),
    0
  );

  const cuting1Tax = list.reduce(
    (acc, row) => acc + Number(row.CUTING_TAX1_AMOUNT),
    0
  );
  const cuting2Tax = list.reduce(
    (acc, row) => acc + Number(row.CUTING_TAX2_AMOUNT),
    0
  );
  const cuting3Tax = list.reduce(
    (acc, row) => acc + Number(row.CUTING_TAX3_AMOUNT),
    0
  );
  const SewingTax = FormatNumber2(totalSew1Tax + totalSew2Tax + totalSew3Tax);
  const CuttingTax = FormatNumber2(cuting3Tax + cuting2Tax + cuting1Tax);

  return (
    <div className="h-full bg-white overflow-y-scroll  p-4" ref={ref}>
      <div id="print-component" className="w-full h-full ">
        <div className="w-full flex justify-center text-[25px] font-semibold">
          {companyName}
        </div>

        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {address},{city}
        </div>

        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {state} , {mobile}
        </div>

        <div style={{ fontSize: 12 }} className="flex">
          {' '}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {email}
          </div>{' '}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full   border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-t-black "
        ></div>

        <div
          style={{ fontSize: 16 }}
          className="w-full text-sm mt-2 mb-2 font-semibold flex items-center justify-center"
        >
          {' '}
          <div className="whitespace-nowrap">TAX INVOICE</div>
        </div>

        <div className="w-full flex justify-between">
          <div className="w-full">
            <div
              style={{ fontSize: 12 }}
              className="w-full  flex justify-between"
            >
              <div className="whitespace-nowrap">Bill No. : {billInvoice}</div>
              <div style={{ fontSize: 12 }} className="whitespace-nowrap">
                {' '}
                {date
                  ? `Date: ${moment.utc(date).format('YYYY-MM-DD : hh.mm A')}`
                  : null}
              </div>
            </div>
            <div
              style={{ borderTopWidth: '1px' }}
              className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-t-black  "
            />
            <div className="flex pb-2">
              <div>
                <div style={{ fontSize: 12 }} className={''}>
                  Customer Name: {list[0]?.CUSTOMER_NAME}
                </div>
                {list[0]?.ADDRESS && (
                  <div style={{ fontSize: 12 }} className={''}>
                    Address: {list[0]?.ADDRESS}
                  </div>
                )}
              </div>

              <div className="pl-5">
                {list[0]?.COMPANY_NAME && (
                  <div style={{ fontSize: 12 }} className={' '}>
                    Company: {list[0]?.COMPANY_NAME}
                  </div>
                )}

                <div style={{ fontSize: 12 }} className={''}>
                  Mobile: {list[0]?.MOBILE}
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className="w-full">
          <thead
            style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
            className="w-full  border-black h-6"
          >
            <tr className="w-full   ">
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '30%',
                }}
                className=" whitespace-nowrap  border-t-black  "
              >
                <div>Item Name</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '30%',
                }}
                className=" whitespace-nowrap border-t-black  "
              >
                <div>Description</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '25%',
                }}
                className="   border-t-black "
              >
                <div> Rate</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '15%',
                }}
                className="  border-t-black  "
              >
                <div> Qnty</div>
              </th>
              <th style={{ fontSize: 12, width: '25%' }}>
                <div> Amount</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    className=" border-black border-dotted pl-2"
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.ITEM_NAME}
                  </td>

                  <td
                    className=" border-black border-dotted pl-2"
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.REMARKS}
                  </td>
                  <td
                    className=" pr-2  border-dotted border-black text-right "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {FormatNumber2(item.RATE)}
                  </td>
                  <td
                    className=" pr-2 border-dotted text-right  border-black "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.QNTY}
                  </td>
                  <td className="text-right pr-2 " style={{ fontSize: 10 }}>
                    {FormatNumber2(item.RATE * item.QNTY)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ borderTopWidth: '1px' }} className=" border-black ">
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                Total :
              </td>
              <td
                className="pr-2 font-semibold border-black "
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(
                  list.reduce((acc, row) => acc + Number(row.AMOUNT), 0)
                )}
              </td>
            </tr>
            {list[0]?.ADVANCE_AMT > 0 && (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: 'right',
                    fontSize: 11,
                    borderRightWidth: '1px',
                  }}
                >
                  Advance :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: 'right' }}
                >
                  {FormatNumber2(list[0]?.ADVANCE_AMT)}
                </td>
              </tr>
            )}

            {list[0]?.DISCOUNT_AMOUNT > 0 && (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: 'right',
                    fontSize: 11,
                    borderRightWidth: '1px',
                  }}
                >
                  Discount :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: 'right' }}
                >
                  {FormatNumber2(list[0]?.DISCOUNT_AMOUNT)}
                </td>
              </tr>
            )}

            {CuttingTax > 0 && (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: 'right',
                    fontSize: 11,
                    borderRightWidth: '1px',
                  }}
                >
                  Cutting Tax (
                  {list[0]?.CUTING_TAX1_RATE +
                    list[0]?.CUTING_TAX2_RATE +
                    list[0]?.CUTING_TAX3_RATE}
                  %) :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: 'right' }}
                >
                  {CuttingTax}
                </td>
              </tr>
            )}

            {SewingTax > 0 && (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: 'right',
                    fontSize: 11,
                    borderRightWidth: '1px',
                  }}
                >
                  Sewing Tax (
                  {list[0]?.SEWING_TAX1_RATE +
                    list[0]?.SEWING_TAX2_RATE +
                    list[0]?.SEWING_TAX3_RATE}
                  %) :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: 'right' }}
                >
                  {SewingTax}
                </td>
              </tr>
            )}

            <tr
              style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
              className=" border-black "
            >
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                PLEASE PAY
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(list[0]?.GTOTAL)}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex justify-center mt-1'}
        >
          {toProperCase(NumberToWords3(list[0]?.GTOTAL))}
        </div>
        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-1 border-t-black "
        ></div>

        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex font-semibold justify-center'}
        >
          Amount Received
        </div>

        <div className="flex w-full justify-center items-center ">
          {billSettlement[0]?.cash_amount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={'flex justify-center'}
            >
              {' '}
              Cash Amount : {billSettlement[0]?.cash_amount}
            </div>
          ) : null}

          {billSettlement[0]?.card_amount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {billSettlement[0]?.card_amount ? (
                <div className="px-2"> | </div>
              ) : null}
              Card Amount :{billSettlement[0]?.card_amount}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center">
          {billSettlement[0]?.btc_amount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              BTC Amount : {billSettlement[0]?.btc_amount}
            </div>
          ) : null}
          {billSettlement[0]?.btc_amount && billSettlement[0]?.epayment ? (
            <div className="px-2">|</div>
          ) : null}

          {billSettlement[0]?.epayment ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              ePayment Amount : {billSettlement[0]?.epayment}{' '}
            </div>
          ) : null}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-t-black "
        ></div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex text-center  justify-center mt-1'}
        >
          All Disputes Subjected to Jalandhar Jurisdiction.
        </div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex justify-center'}
        >
          Powered by : www.geniusoffice.com
        </div>
      </div>
    </div>
  );
});

export default ViewBillImage;
