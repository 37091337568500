import { child, get, onValue, ref, set } from 'firebase/database';
import database from '../utility/component/functions/firebase';
import { handleSignout } from '../utility/func/func';
import { isFireBaseLoginOn } from '../utility/common';

export const getExpireAt = () => {
  const expire = localStorage.getItem('expiresAt');
  return expire;
};
export function detectIsAnyOtherUserTryToLogin(
  prefix,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  dispatch,
  navigate,
  userCode,
  statusField
) {
  console.log('f');
  const connectedRef = ref(database, `loginAuth/${prefix}/user/${userCode}/`);
  const connectedMsgRef = ref(
    database,
    `loginAuth/${prefix}/user/${userCode}/${statusField}/msg`
  );
  get(child(connectedRef, `/${statusField}/connection`))
    .then((sn) => {
      if (sn.exists()) {
        const data = sn.val();

        if (data.includes(getExpireAt())) {
          get(child(connectedRef, `/${statusField}/msg`)).then((msg) => {
            if (msg.exists()) {
              onValue(connectedMsgRef, (sn) => {
                if (sn.exists()) {
                  const data = sn.val();
                  if (
                    data === 'isConnected' &&
                    document.visibilityState === 'visible'
                  ) {
                    set(connectedMsgRef, `rejected`);
                    console.log('get lost');
                  }
                }
              });
            }
          });
        } else {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'Alert',
            msg: 'This account is onnected E-1',
            button1: 'Signout',

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              handleSignout(dispatch, navigate);
            },
          });
        }
      } else {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: true,
          title: 'Alert',
          msg: 'This account is already logged in.',
          button1: 'Signout',

          button1Click: () => {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: false,
            });
            handleSignout(dispatch, navigate);
          },
        });
      }
    })
    .catch((e) => {
      setAlertTwoButtonModal({
        ...alertTwoButtonModal,
        status: true,
        title: 'Alert',
        msg: 'This account is already logged in.',
        button1: 'Signout',

        button1Click: () => {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
          handleSignout(dispatch, navigate);
        },
      });
    });
}

export function isAnyAlreadyOneLogin(
  prefix,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  dispatch,
  navigate,
  userCode,
  statusField
) {
  const loginPath = `loginAuth/${prefix}/user/${userCode}/${statusField}/connection`;
  const connectedRef = ref(database, loginPath);
  const connectedMsgRef = ref(
    database,
    `loginAuth/${prefix}/user/${userCode}/${statusField}/msg`
  );
  onValue(connectedRef, (sn) => {
    if (sn.exists()) {
      const data = sn.val();
      if (!data.includes(getExpireAt())) {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: true,
          title: 'Alert',
          msg: 'This account is already logged in.',
          button1: 'Signout',

          button1Click: () => {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: false,
            });
            handleSignout(dispatch, navigate);
          },
        });
      }
    } else {
      setAlertTwoButtonModal({
        ...alertTwoButtonModal,
        status: true,
        title: 'Alert',
        msg: 'This account is already logged in.',
        button1: 'Signout',

        button1Click: () => {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
          handleSignout(dispatch, navigate);
        },
      });
    }
  });
}
