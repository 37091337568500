import React, { useEffect, useRef, useState } from 'react';
import ItemDetails from './ItemDetails';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';

import {
  selectBookingAttachImage,
  selectCustomerPrefix,
  selectHistoryOrderDetails,
  selectLowerBookingItemDetails,
  selectLowerDate,
  selectLowerMeasuremnt,
  selectLowerMeasuremntList,
  selectLowerQnty,
  selectModyingBookingID,
  selectRemarks,
  selectUpperBookingItemDetails,
  selectUpperDate,
  selectUpperMeasurementList,
  selectUpperMeasuremnt,
  selectUpperQnty,
  setBookingAttachImage,
  setBookingModifiedStatus,
  setBookingNumber,
  setCustomerFirebasePic,
  setHistoryOrderDetails,
  setLowerDate,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setLowerQnty,
  setModifyingBookingId,
  setRemarks,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
  setUpperDate,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
  setUpperQnty,
} from '../utility/Redux/booking';
import UpperStyle from './upperStyle';
import Remarks from './remarks';
import moment from 'moment';
import LowerStyle from './lowerStyle';
import axios from 'axios';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
} from '../utility/Redux/modal';
import ModStyleMaster from '../help/styleMasterHelp';
import MeasurmentSlip from '../masters/measurmentSlip';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { UploadFile } from '../utility/component/functions/firebase';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import ModAttach4Image from '../utility/component/modals/attach4Image';
import ReactDatePicker from 'react-datepicker';
import QrCodeSlip from '../masters/qrSlip';
import {
  setAdvanceVoucherDetails,
  setBillingAdvanceAmount,
  setBillSettlement,
} from '../utility/Redux/billing';
const Measurements = ({
  SetTabScreen,
  isMrcDuplicate,
  mrcNumber,
  bookingOrderNumber,
  setBookingOrderNumber,
  setMrcNumber,
  bookingDate,
  setBookingDate,
  setOpenYear,
  OrderNumberList,
  setOrderNumberList,
  bookingCustomerDetails,
  setCustomerHistory,
  setBookingCustomerDetails,
}) => {
  const [upperList, SetUpperList] = useState([]);
  const [lowerList, SetLowerList] = useState([]);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [upperTryDateFocus, setUpperTryDateFocus] = useState(false);
  const [upperCuttingDateFocus, setUpperCuttingDateFocus] = useState(false);
  const [upperDeliveryDateFocus, setUpperDeliveryDateFocus] = useState(false);
  const [lowerTryDate, setLowerTryDate] = useState(
    moment(bookingDate).add(5, 'days').format('YYYY-MM-DD')
  );

  const [isAnimatingUpper, setIsAnimatingUpper] = useState(false); // Animation trigger
  const [animationWidthUpper, setAnimationWidthUpper] = useState(50); // Image width for demonstration
  const triggerAnimationUpper = () => {
    setIsAnimatingUpper(true);
    // Optionally, reset animation after it's finished
    setTimeout(() => setIsAnimatingUpper(false), 3000); // Adjust time based on animation length
  };

  const [isAnimatingLower, setIsAnimatingLower] = useState(false); // Animation trigger
  const [animationWidthLower, setAnimationWidthLower] = useState(50); // Image width for demonstration
  const triggerAnimationLower = () => {
    setIsAnimatingLower(true);
    // Optionally, reset animation after it's finished
    setTimeout(() => setIsAnimatingLower(false), 3000); // Adjust time based on animation length
  };
  const [lowerTryDateFocus, setLowerTryDateFocus] = useState(false);
  const [lowerCuttingDateFocus, setLowerCuttingDateFocus] = useState(false);
  const [lowerDeleveryDate, setlowerDeleveryDate] = useState(
    moment(bookingDate).add(8, 'days').format('YYYY-MM-DD')
  );
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const [selectedRow, setSelectedRow] = useState('');
  const [lowerDeliveryDateFocus, setLowerDeliveryDateFocus] = useState(false);
  const [styleMasterStatus, setStyleMasterStatus] = useState(false);
  const [styleType, setStyleType] = useState('UPPER');
  const [attachImage, setAttachImage] = useState('');
  const [attachImageModStatus, setAttachImageModStatus] = useState(false);
  const [attachImageFolder, setAttachImageFolder] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const upperMeasrment = useSelector(selectUpperMeasuremnt);
  const lowerMeasrment = useSelector(selectLowerMeasuremnt);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const upperMeasuremntList = useSelector(selectUpperMeasurementList);
  const lowerMeasuremntList = useSelector(selectLowerMeasuremntList);
  const remarks = useSelector(selectRemarks);
  const [capturedImage, setCapturedImage] = useState(null);
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [bookingDateFocus, setBookingDateFocus] = useState(false);
  const [capturedImage2, setCapturedImage2] = useState(null);
  const [webcamStatus2, setWebcamStatus2] = useState(false);
  const [selectedFile2, setSelectedFile2] = useState('');

  const [capturedImage3, setCapturedImage3] = useState(null);
  const [webcamStatus3, setWebcamStatus3] = useState(false);
  const [selectedFile3, setSelectedFile3] = useState('');

  const [capturedImage4, setCapturedImage4] = useState(null);
  const [webcamStatus4, setWebcamStatus4] = useState(false);
  const [selectedFile4, setSelectedFile4] = useState('');

  const selectedUpperItemDetails = useSelector(selectUpperBookingItemDetails);
  const selectedLowerItemDetails = useSelector(selectLowerBookingItemDetails);
  const isModifying = useSelector(selectModyingBookingID);
  const modifyBookingId = useSelector(selectModyingBookingID);
  const upperMeasuremnt = useSelector(selectUpperMeasuremnt);
  const lowerMeasuremnt = useSelector(selectLowerMeasuremnt);
  const historyOrderDetails = useSelector(selectHistoryOrderDetails);
  const lowerQnty = useSelector(selectLowerQnty);
  const upperQnty = useSelector(selectUpperQnty);
  const lowerDate = useSelector(selectLowerDate);
  const upperDate = useSelector(selectUpperDate);
  const [ItemtabScreen, SetItemTabScreen] = useState(1);
  const [qrCodeValue, setQrCodeValue] = useState(''); //
  const [parametrInput, setParameterInput] = useState('');
  const bookingAttachImage = useSelector(selectBookingAttachImage);
  const masterprefix = useSelector(selectTableMasterPrefix);
  const [editCellValues, setEditCellValues] = useState({
    field: '',
    id: '',
    value: '',
  });
  const customerPrefix = useSelector(selectCustomerPrefix);
  const componentRef = useRef();
  const getHistory = () => {
    axios
      .post(
        `/api/booking/bookingHistory`,
        {
          table_prefix: prefix,
          CUSTOMER_NAME: bookingCustomerDetails?.customer_name
            ? bookingCustomerDetails.customer_name
            : '',
          MOBILE: !mrcNumber ? bookingCustomerDetails.mobile : '',
          MRC_NO: '',
          PREFIX: customerPrefix,

          // SHOW_LINKED_ORDER: mergehistory,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setOpenYear(Number(moment().format('YYYY')));
          dispatch(setBookingModifiedStatus(false));
          setCustomerHistory(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  const saveBooking = () => {
    const isupper = upperMeasuremnt.filter((item) => item.size !== '');
    const isLower = lowerMeasrment.filter((item) => item.size !== '');
    const customerMobile = bookingCustomerDetails?.mobile
      ? bookingCustomerDetails?.mobile
      : '';
    if (isupper[0] || isLower[0]) {
      if (!isModifying) {
        if (bookingCustomerDetails?.customer_name && customerMobile) {
          const parameters = [];

          try {
            const UpperRowList = [];
            const LowerRowList = [];
            const LowerMeasuremntListDetails = lowerMeasuremntList;
            const upperMeasuremntListDetails = upperMeasuremntList;

            for (
              let index = 0;
              index < upperMeasuremntListDetails.length;
              index++
            ) {
              UpperRowList.push({
                ...upperMeasuremntListDetails[index],
                REMARKS: remarks?.upper,
                TRY_DATE: upperDate.try
                  ? moment(upperDate.try).format('YYYY-MM-DD')
                  : '',
                DEL_DATE: moment(upperDate.del).format('YYYY-MM-DD'),
                QNTY: upperQnty,
                PREFIX: customerPrefix,
                CUTTING_DATE: upperDate.cutting
                  ? moment(upperDate.cutting).format('YYYY-MM-DD')
                  : '',
                DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
                EMAIL: bookingCustomerDetails?.email
                  ? bookingCustomerDetails.email
                  : '',
                CUSTOMER_REMARKS: bookingCustomerDetails?.customer_remarks
                  ? bookingCustomerDetails?.customer_remarks
                  : '',
                COMPANY_NAME: bookingCustomerDetails?.company_name
                  ? bookingCustomerDetails.company_name
                  : '',
                COMPANY_PHONE: bookingCustomerDetails?.company_phone
                  ? bookingCustomerDetails.company_phone
                  : '',
                COMPANY_ADDRESS: bookingCustomerDetails?.company_address
                  ? bookingCustomerDetails.company_address
                  : '',
                COMPANY_EMAIL: bookingCustomerDetails?.company_email
                  ? bookingCustomerDetails.company_email
                  : '',
                COMPANY_CITY: '',
                COMPANY_STATE: '',
                COMPANY_COUNTRY: bookingCustomerDetails?.company_country
                  ? bookingCustomerDetails.company_country
                  : '',
                COMPANY_PINCODE: bookingCustomerDetails?.company_pincode
                  ? bookingCustomerDetails.company_pincode
                  : '',
                REGD_NO1: bookingCustomerDetails?.regd_no1
                  ? bookingCustomerDetails.regd_no1
                  : '',
                REGD_NO2: bookingCustomerDetails?.regd_no2
                  ? bookingCustomerDetails.regd_no2
                  : '',
                REGD_NO3: bookingCustomerDetails?.regd_no3
                  ? bookingCustomerDetails.regd_no3
                  : '',
                STATUS: bookingCustomerDetails?.status
                  ? bookingCustomerDetails.status
                  : 'Good',
              });
            }

            for (
              let index = 0;
              index < LowerMeasuremntListDetails.length;
              index++
            ) {
              LowerRowList.push({
                ...LowerMeasuremntListDetails[index],
                REMARKS: remarks?.lower,
                TRY_DATE: lowerDate.try
                  ? moment(lowerDate.try).format('YYYY-MM-DD')
                  : '',
                DEL_DATE: moment(lowerDate.del).format('YYYY-MM-DD'),
                CUTTING_DATE: lowerDate.cutting
                  ? moment(lowerDate.cutting).format('YYYY-MM-DD')
                  : '',
                DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
                EMAIL: bookingCustomerDetails?.email
                  ? bookingCustomerDetails.email
                  : '',
                CUSTOMER_REMARKS: bookingCustomerDetails?.customer_remarks
                  ? bookingCustomerDetails?.customer_remarks
                  : '',
                QNTY: lowerQnty,
                PREFIX: customerPrefix,
                COMPANY_NAME: bookingCustomerDetails?.company_name
                  ? bookingCustomerDetails.company_name
                  : '',
                COMPANY_PHONE: bookingCustomerDetails?.company_phone
                  ? bookingCustomerDetails.company_phone
                  : '',
                COMPANY_ADDRESS: bookingCustomerDetails?.company_address
                  ? bookingCustomerDetails.company_address
                  : '',
                COMPANY_EMAIL: bookingCustomerDetails?.company_email
                  ? bookingCustomerDetails.company_email
                  : '',
                COMPANY_CITY: '',
                COMPANY_STATE: '',
                COMPANY_COUNTRY: bookingCustomerDetails?.company_country
                  ? bookingCustomerDetails.company_country
                  : '',
                COMPANY_PINCODE: bookingCustomerDetails?.company_pincode
                  ? bookingCustomerDetails.company_pincode
                  : '',
                REGD_NO1: bookingCustomerDetails?.regd_no1
                  ? bookingCustomerDetails.regd_no1
                  : '',
                REGD_NO2: bookingCustomerDetails?.regd_no2
                  ? bookingCustomerDetails.regd_no2
                  : '',
                REGD_NO3: bookingCustomerDetails?.regd_no3
                  ? bookingCustomerDetails.regd_no3
                  : '',
                STATUS: bookingCustomerDetails?.status
                  ? bookingCustomerDetails.status
                  : 'Good',
              });
            }

            parameters.push(...UpperRowList);
            parameters.push(...LowerRowList);
          } catch (e) {
            console.error(e);
          }

          if (parameters[0]) {
            const isFamilyMemExtra = parameters.some(
              (item) => item.familyMemberName === ''
            );

            if (!isFamilyMemExtra) {
              savebookingCustomerDetailsAPI(parameters);
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Failed!',
                  msg: 'Something went wrong in Familymember Name',
                  lottie: 'reject',
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Measurements!',
                msg: 'Please add Measurements Details to continue.',
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Customer Mobile!',
              msg: 'Please Enter Customer Name & Mobile to continue.',
            })
          );
        }
      } else {
        savebookingCustomerDetailsAPI('', true);
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error!',
          msg: 'Please fill Measurements.',
        })
      );
    }
  };

  const afterPrintEnd = () => {
    clearBookingFields();

    setAlertTwoButtonModal({
      title: 'Successfull',
      msg: 'Do you want to save another measuremnets ?',
      status: true,
      button1: 'YES',
      button1Click: () => {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: false,
        });
        setBookingNumber('');
        setBookingOrderNumber('');
      },
      button2: 'NO',
      button2Click: () => {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: false,
        });
        setAlertTwoButtonModal({
          title: 'Create Bill',
          msg: 'Do you want to Create Bill ?',
          status: true,
          button1: 'YES',
          button1Click: () => {
            clearBookingFields();
            SetTabScreen(3);
            dispatch(
              setBillSettlement({
                cashAmount: '',
                btcAmount: '',
                epaymentAmount: '',
                epaymentType: '',
                cardAmount: '',
                cardNumber: '',
                Remarks: '',
                clothAmount: '',
                DISCOUNT_RATE: 0,
                DISCOUNT_AMOUNT: 0,
              })
            );
          },
          button2: 'NO',
          button2Click: () => {
            dispatch(setloading(false));
            setBookingCustomerDetails({
              code: '',
              customer_name: '',
              mobile: '',
              mobile_2: '',
              mobile_3: '',
              phone: '',
              email: '',
              address: '',
              city: '',
              state: '',
              country: '',
              pin_code: '',
              credit_limit: 0,
              company_name: '',
              company_phone: '',
              company_email: '',
              company_address: '',
              company_city: '',
              company_state: '',
              company_country: '',
              company_pincode: '',
              regd_no1: '',
              regd_no2: '',
              regd_no3: '',
              status: '',
              remarks: '',
              prefix: '',
              mrc_no: '',
              old_mobile: '',
            });
            dispatch(setCustomerFirebasePic(''));
            dispatch(setHistoryOrderDetails(''));
            setCustomerHistory([]);
            clearBookingFields();
            SetTabScreen(1);
            setBookingOrderNumber('');

            setMrcNumber('');
            setBookingDate(
              moment(localStorage.getItem('bookingDate')).format('YYYY-MM-DD')
            );
          },
        });
      },
    });
  };

  const clearBookingFields = () => {
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setUpperMeasuremnt([]));
    dispatch(setBookingAttachImage(''));
    dispatch(setLowerMeasuremnt([]));
    dispatch(setUpperMeasuremntList([]));
    dispatch(setLowerMeasuremntList([]));
    setBookingDate(
      moment(localStorage.getItem('bookingDate')).format('YYYY-MM-DD')
    );
    dispatch(
      setRemarks({
        upper: '',
        lower: '',
      })
    );
    dispatch(
      setUpperDate({
        try: '',
        del: moment().add(8, 'days').format('YYYY-MM-DD'),
        cutting: '',
      })
    );
    dispatch(
      setLowerDate({
        try: '',
        del: moment().add(8, 'days').format('YYYY-MM-DD'),
        cutting: '',
      })
    );
    dispatch(setModifyingBookingId(''));
    dispatch(setHistoryOrderDetails(''));
    dispatch(setUpperQnty(1));
    dispatch(setLowerQnty(1));
  };

  const modifyBooking = async () => {
    const upperMeasurementModifyList = [];
    const lowerMeasurementModifyList = [];
    dispatch(setSpinnerLoading('Modifying'));
    if (upperMeasuremnt[0]) {
      upperMeasuremnt.map((i) => {
        if (i.size) {
          upperMeasurementModifyList.push({
            BOOKING_ORDER_NO: modifyBookingId,
            INVNO: historyOrderDetails[0]?.INVNO
              ? historyOrderDetails[0].INVNO
              : 0,

            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            PREFIX: customerPrefix,

            MRC_NO: historyOrderDetails[0]?.MRC_NO
              ? historyOrderDetails[0].MRC_NO
              : '',
            ICODE: selectedUpperItemDetails.icode,
            LOWER_ICODE: selectedUpperItemDetails.lower_icode,

            ITEM_NAME: selectedUpperItemDetails.item_name,
            QNTY: upperQnty,
            PARAMETER: i.PARAMETER,
            SIZE: i.size,
            REGIONAL_INAME: selectedUpperItemDetails.regional_iname,
            SECTION_NAME: selectedUpperItemDetails.section_name,
            UPPER_LOWER: selectedUpperItemDetails.upper_lower,
            // CODE: bookingCustomerDetails.code
            //   ? bookingCustomerDetails.code
            //   : '',
            CUSTOMER_NAME: bookingCustomerDetails.customer_name,
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            MOBILE: bookingCustomerDetails.mobile
              ? bookingCustomerDetails.mobile
              : '',
            MOBILE_2: bookingCustomerDetails.mobile_2
              ? bookingCustomerDetails.mobile_2
              : '',
            MOBILE_3: bookingCustomerDetails.mobile_3
              ? bookingCustomerDetails.mobile_3
              : '',

            PHONE: bookingCustomerDetails.phone
              ? bookingCustomerDetails.phone
              : '',
            ADDRESS: bookingCustomerDetails.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails.city
              ? bookingCustomerDetails.city
              : '',
            STATE: bookingCustomerDetails.state
              ? bookingCustomerDetails.state
              : '',
            COUNTRY: bookingCustomerDetails.country
              ? bookingCustomerDetails.country
              : '',
            TRY_DATE: upperDate.try
              ? moment(upperDate.try).format('YYYY-MM-DD')
              : '',
            CUSTOMER_REMARKS: bookingCustomerDetails.customer_remarks
              ? bookingCustomerDetails.customer_remarks
              : '',
            STATUS: bookingCustomerDetails?.status
              ? bookingCustomerDetails.status
              : 'Good',

            EMAIL: bookingCustomerDetails?.email
              ? bookingCustomerDetails.email
              : '',
            COMPANY_NAME: bookingCustomerDetails?.company_name
              ? bookingCustomerDetails.company_name
              : '',
            COMPANY_PHONE: bookingCustomerDetails?.company_phone
              ? bookingCustomerDetails.company_phone
              : '',
            COMPANY_ADDRESS: bookingCustomerDetails?.company_address
              ? bookingCustomerDetails.company_address
              : '',
            COMPANY_EMAIL: bookingCustomerDetails?.company_email
              ? bookingCustomerDetails.company_email
              : '',
            COMPANY_CITY: '',
            COMPANY_STATE: '',
            COMPANY_COUNTRY: bookingCustomerDetails?.company_country
              ? bookingCustomerDetails.company_country
              : '',
            COMPANY_PINCODE: bookingCustomerDetails?.company_pincode
              ? bookingCustomerDetails.company_pincode
              : '',
            REGD_NO1: bookingCustomerDetails?.regd_no1
              ? bookingCustomerDetails.regd_no1
              : '',
            REGD_NO2: bookingCustomerDetails?.regd_no2
              ? bookingCustomerDetails.regd_no2
              : '',
            REGD_NO3: bookingCustomerDetails?.regd_no3
              ? bookingCustomerDetails.regd_no3
              : '',

            DEL_DATE: moment(upperDate.del).format('YYYY-MM-DD'),
            CUTTING_DATE: upperDate.cutting
              ? moment(upperDate.cutting).format('YYYY-MM-DD')
              : '',
            REMARKS: remarks ? remarks.upper : '',
          });
        }
      });
    }
    if (lowerMeasuremnt[0]) {
      lowerMeasuremnt.map((i) => {
        if (i.size) {
          lowerMeasurementModifyList.push({
            BOOKING_ORDER_NO: modifyBookingId,
            INVNO: historyOrderDetails[0]?.INVNO
              ? historyOrderDetails[0].INVNO
              : 0,
            DATE: moment(bookingDate).format('YYYY-MM-DD HH:mm:ss'),
            MRC_NO: historyOrderDetails[0]?.MRC_NO
              ? historyOrderDetails[0].MRC_NO
              : '',
            LOWER_ICODE: selectedUpperItemDetails
              ? selectedUpperItemDetails.lower_icode
              : 0,
            ICODE: selectedLowerItemDetails.icode,
            ITEM_NAME: selectedLowerItemDetails.item_name,
            QNTY: lowerQnty,
            PARAMETER: i.PARAMETER,
            SIZE: i.size,
            REGIONAL_INAME: selectedLowerItemDetails.regional_iname,
            SECTION_NAME: selectedLowerItemDetails.section_name,
            UPPER_LOWER: selectedLowerItemDetails.upper_lower,
            CODE: bookingCustomerDetails.code
              ? bookingCustomerDetails.code
              : '',
            STATUS: bookingCustomerDetails?.status
              ? bookingCustomerDetails.status
              : 'Good',

            CUSTOMER_NAME: bookingCustomerDetails.customer_name
              ? bookingCustomerDetails.customer_name
              : '',
            FAMILY_MEM_NAME: bookingCustomerDetails?.family_mem_name
              ? bookingCustomerDetails?.family_mem_name
              : '',
            MOBILE: bookingCustomerDetails.mobile
              ? bookingCustomerDetails.mobile
              : '',
            MOBILE_2: bookingCustomerDetails.mobile_2
              ? bookingCustomerDetails.mobile_2
              : '',
            MOBILE_3: bookingCustomerDetails.mobile_3
              ? bookingCustomerDetails.mobile_3
              : '',
            PHONE: bookingCustomerDetails.phone
              ? bookingCustomerDetails.phone
              : '',
            ADDRESS: bookingCustomerDetails.address
              ? bookingCustomerDetails.address
              : '',
            CITY: bookingCustomerDetails.city
              ? bookingCustomerDetails.city
              : '',
            STATE: bookingCustomerDetails.state
              ? bookingCustomerDetails.state
              : '',
            COUNTRY: bookingCustomerDetails.country
              ? bookingCustomerDetails.country
              : '',
            TRY_DATE: lowerDate.try
              ? moment(lowerDate.try).format('YYYY-MM-DD')
              : '',
            DEL_DATE: moment(lowerDate.del).format('YYYY-MM-DD'),
            CUTTING_DATE: lowerDate.cutting
              ? moment(lowerDate.cutting).format('YYYY-MM-DD')
              : '',
            REMARKS: remarks ? remarks?.lower : '',
            PREFIX: customerPrefix,
            CUSTOMER_REMARKS: bookingCustomerDetails.customer_remarks
              ? bookingCustomerDetails.customer_remarks
              : '',
            EMAIL: bookingCustomerDetails?.email
              ? bookingCustomerDetails.email
              : '',
            COMPANY_NAME: bookingCustomerDetails?.company_name
              ? bookingCustomerDetails.company_name
              : '',
            COMPANY_PHONE: bookingCustomerDetails?.company_phone
              ? bookingCustomerDetails.company_phone
              : '',
            COMPANY_ADDRESS: bookingCustomerDetails?.company_address
              ? bookingCustomerDetails.company_address
              : '',
            COMPANY_EMAIL: bookingCustomerDetails?.company_email
              ? bookingCustomerDetails.company_email
              : '',
            COMPANY_CITY: '',
            COMPANY_STATE: '',
            COMPANY_COUNTRY: bookingCustomerDetails?.company_country
              ? bookingCustomerDetails.company_country
              : '',
            COMPANY_PINCODE: bookingCustomerDetails?.company_pincode
              ? bookingCustomerDetails.company_pincode
              : '',
            REGD_NO1: bookingCustomerDetails?.regd_no1
              ? bookingCustomerDetails.regd_no1
              : '',
            REGD_NO2: bookingCustomerDetails?.regd_no2
              ? bookingCustomerDetails.regd_no2
              : '',
            REGD_NO3: bookingCustomerDetails?.regd_no3
              ? bookingCustomerDetails.regd_no3
              : '',
          });
        }
      });
    }

    const modifyBookingList = [
      ...upperMeasurementModifyList,
      ...lowerMeasurementModifyList,
    ];
    if (modifyBookingList[0].CUSTOMER_NAME) {
      axios
        .post(
          `/api/booking/modifyBooking`,
          {
            table_prefix: prefix,
            cart_item: modifyBookingList,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async function (response) {
          if (!response.data.error) {
            if (attachImage || attachImageFolder) {
              const camera = attachImage
                ? await fetch(attachImage).then((response) => response.blob())
                : '';
              const file = attachImageFolder ? attachImageFolder : camera;
              const name = prefix + historyOrderDetails[0].BOOKING_ORDER_NO;
              const path = 'seam/booking/';
              UploadFile(file, path, name);
            }
            dispatch(setModifyingBookingId(''));
            setOrderNumberList((old) => {
              return [...old, historyOrderDetails[0].BOOKING_ORDER_NO];
            });
            setBookingOrderNumber(historyOrderDetails[0].BOOKING_ORDER_NO);
          }
        })
        .catch(function (error) {
          if (error.response?.data?.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
        })
        .finally(() => {
          setSaveLoading(false);
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error 109',
          msg: 'Something wrong with customer details',
        })
      );
    }
  };

  const handleRefresh = () => {
    if (upperMeasuremntList[0] || lowerMeasuremntList[0]) {
      dispatch(
        setAlertWithTitle({
          title: 'Reload',
          msg: 'You have unsaved changes. Are you sure you want to leave?',
        })
      );
      return 'You have unsaved changes. Are you sure you want to leave?';
    }
  };
  const QrprintRef = useRef();
  const handleQrPrint = useReactToPrint({
    content: () => QrprintRef.current,
    onBeforeGetContent: () => {
      const style = document.createElement('style');
      style.innerHTML = `
        @page {
           size: 138px 460px;
              margin: 0
        }
      
      `;
      document.head.appendChild(style);

      return () => document.head.removeChild(style);
    },
    onAfterPrint: () => {
      afterPrintEnd();
    },
  });
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      const style = document.createElement('style');
      style.innerHTML = `
        @page {
          size: portrait;
          margin: 0;
        }
      
      `;
      document.head.appendChild(style);

      return () => document.head.removeChild(style);
    },
    onAfterPrint: () => {
      afterPrintEnd();
    },
  });
  const HandlePrintBoth = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      const style = document.createElement('style');
      style.innerHTML = `
        @page {
          size: portrait;
          margin: 0;
        }
      
      `;
      document.head.appendChild(style);

      return () => document.head.removeChild(style);
    },
    onAfterPrint: () => {
      handleQrPrint();
    },
  });

  const savebookingCustomerDetailsAPI = (parameters, modify) => {
    if (mrcNumber ? (isMrcDuplicate ? false : true) : true) {
      if (
        bookingCustomerDetails.email
          ? emailRegex.test(bookingCustomerDetails.email)
          : true
      ) {
        if (modify) {
          modifyBooking();
        } else {
          let p = '';
          if (!bookingCustomerDetails.code) {
            p = parameters.map((e) => ({
              ...e,
              MOBILE: bookingCustomerDetails.mobile,
              MOBILE_2: bookingCustomerDetails.mobile_2,
              MOBILE_3: bookingCustomerDetails.mobile_3,
              MRC_NO: mrcNumber
                ? mrcNumber
                : bookingCustomerDetails.mobile
                ? bookingCustomerDetails.mobile
                : '',
              PREFIX: customerPrefix ? customerPrefix : '',
            }));
          }
          if (!mrcNumber) {
            setMrcNumber(bookingCustomerDetails.mobile);
          }
          const data = !bookingCustomerDetails.code ? p : parameters;
          const filterdData = data.filter((d) => d.SIZE !== '');
          dispatch(setSpinnerLoading('Saving'));
          if (filterdData[0].MOBILE) {
            axios
              .post(
                `/api/booking/saveBooking`,
                {
                  table_prefix: prefix,
                  cart_item: filterdData,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then(async (res) => {
                if (!res.data.error) {
                  if (res.data?.response?.BOOKING_ORDER_NO) {
                    dispatch(
                      setBookingNumber(res.data.response.BOOKING_ORDER_NO)
                    );
                    dispatch(setBillingAdvanceAmount(''));
                    dispatch(setAdvanceVoucherDetails(''));
                    setBookingOrderNumber(res.data.response.BOOKING_ORDER_NO);
                    setOrderNumberList((old) => {
                      return [...old, res.data.response.BOOKING_ORDER_NO];
                    });
                    getHistory();
                    if (selectedFile || capturedImage) {
                      const camera = capturedImage
                        ? await fetch(capturedImage).then((response) =>
                            response.blob()
                          )
                        : '';
                      const file = selectedFile ? selectedFile : camera;
                      const name =
                        prefix + res.data.response.BOOKING_ORDER_NO + 'left';
                      const path = 'seam/booking/';
                      UploadFile(file, path, name);
                    }
                    if (selectedFile2 || capturedImage2) {
                      const camera = capturedImage2
                        ? await fetch(capturedImage2).then((response) =>
                            response.blob()
                          )
                        : '';
                      const file = selectedFile2 ? selectedFile2 : camera;
                      const name =
                        prefix + res.data.response.BOOKING_ORDER_NO + 'right';
                      const path = 'seam/booking/';
                      UploadFile(file, path, name);
                    }
                    if (selectedFile3 || capturedImage3) {
                      const camera = capturedImage3
                        ? await fetch(capturedImage3).then((response) =>
                            response.blob()
                          )
                        : '';
                      const file = selectedFile3 ? selectedFile3 : camera;
                      const name =
                        prefix + res.data.response.BOOKING_ORDER_NO + 'front';
                      const path = 'seam/booking/';
                      UploadFile(file, path, name);
                    }
                    if (selectedFile4 || capturedImage4) {
                      const camera = capturedImage4
                        ? await fetch(capturedImage4).then((response) =>
                            response.blob()
                          )
                        : '';
                      const file = selectedFile4 ? selectedFile4 : camera;
                      const name =
                        prefix + res.data.response.BOOKING_ORDER_NO + 'back';
                      const path = 'seam/booking/';
                      UploadFile(file, path, name);
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Error',
                        msg: 'Something went wrong',
                      })
                    );
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Error 108',
                      msg: res.data.error,
                    })
                  );
                }
              })
              .catch((e) => {
                console.log(e);
                if (e.response.data.name === 'TokenExpiredError') {
                  dispatch(setSessionExpired(true));
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Failed!',
                      msg: 'Something went wrong',
                      lottie: 'reject',
                    })
                  );
                }
              })
              .finally(() => dispatch(setSpinnerLoading(false)));
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Mobile Not Found',
              })
            );
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Email Error',
            msg: 'Invaild Email',
          })
        );
      }
    } else {
      setSaveLoading(false);
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: ' Duplicate MRC Found. Please use a different MRC number.',
        })
      );
    }
  };

  useEffect(() => {
    if (bookingOrderNumber) {
      setAlertTwoButtonModal({
        title: 'Print',
        msg: 'Do you want to print ?',
        status: true,
        setStatus: '',
        horizontal2: true,
        button1: 'QR Code',
        button1Click: '',
        button2: 'Measurements',
        button3: 'Both',
        button1Style: {
          backgroundColor: '#616161',
          color: 'white',
          textTransform: 'none',
          letterSpacing: '1px',
          paddingLeft: 8,
          paddingRight: 8,
        },
        button2Style: {
          backgroundColor: '#6b6969',
          color: 'white',
          marginLeft: '4px',
          textTransform: 'none',
          letterSpacing: '1px',
          paddingLeft: 0,
          paddingRight: 0,
        },
        button3Style: {
          backgroundColor: '#bad4f9',
          color: 'black',
          marginLeft: '4px',
          textTransform: 'none',
          letterSpacing: '1px',
          paddingLeft: 0,
          paddingRight: 0,
        },
        button1Click: () => {
          handleQrPrint();
          setAlertTwoButtonModal((e) => {
            return {
              ...e,
              status: false,
            };
          });
        },
        button2Click: () => {
          HandlePrint();
          setAlertTwoButtonModal((e) => {
            return {
              ...e,
              status: false,
            };
          });
        },
        button3Click: () => {
          HandlePrintBoth();
          setAlertTwoButtonModal((e) => {
            return {
              ...e,
              status: false,
            };
          });
        },
        onCancel: () => {
          afterPrintEnd();
        },
        cancelAble: true,
      });
    }
  }, [bookingOrderNumber]);

  // reload page alert with title
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (upperMeasuremntList[0] || lowerMeasuremntList[0]) {
        event.preventDefault();
        event.returnValue = ''; // This is required for most browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [upperMeasuremntList, lowerMeasuremntList]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);

  //load image
  const customerMobile = bookingCustomerDetails?.mobile
    ? bookingCustomerDetails?.mobile
    : '';

  const items = [];

  const upperDetails = {
    CUSTOMER_NAME: bookingCustomerDetails.customer_name,
    BOOKING_ORDER_NO: bookingOrderNumber,
    DATE: moment(bookingDate).format('YYYY-MM-DD'),
    MRC_NO: mrcNumber,
    ITEM_NAME: selectedUpperItemDetails?.item_name,
    UPPER_LOWER: 'UPPER',
    PREFIX: customerPrefix,
    ICODE: selectedUpperItemDetails?.icode,
  };

  const lowerDetails = {
    CUSTOMER_NAME: bookingCustomerDetails.customer_name,
    BOOKING_ORDER_NO: bookingOrderNumber,
    DATE: moment(bookingDate).format('YYYY-MM-DD'),
    MRC_NO: mrcNumber,
    ITEM_NAME: selectedLowerItemDetails?.item_name,
    UPPER_LOWER: 'LOWER',
    PREFIX: customerPrefix,
    ICODE: selectedLowerItemDetails?.icode,
  };

  if (upperMeasrment.find((u) => u.size !== '')) {
    for (let i = 0; i < upperQnty; i++) {
      items.push({ ...upperDetails });
    }
  }

  if (lowerMeasrment.find((u) => u.size !== '')) {
    for (let i = 0; i < lowerQnty; i++) {
      items.push({ ...lowerDetails });
    }
  }

  return (
    <div className="   h-full w-full flex flex-col  text-sm ">
      <div className="flex w-full flex-col h-full mt-2  ">
        <div className="flex w-full h-full  ">
          <div className="w-[50%] h-full flex flex-col ">
            <div
              style={{
                border: '1px solid #e8e2db ',
              }}
              className="rounded-sm p-2 flex items-center h-[5%] justify-between  text-red font-semibold text-[16px]"
            >
              {bookingCustomerDetails?.customer_name
                ? bookingCustomerDetails?.customer_name +
                  '    ' +
                  '-' +
                  '    ' +
                  customerMobile
                : 'Customer Name' + '  ' + '-' + ' ' + 'Mobile'}

              <div className="h-full flex items-center flex-1 justify-end  ">
                <div className="text-red">Booking Date :</div>
                <div className="flex items-center ">
                  <ReactDatePicker
                    onFocus={() => setBookingDateFocus(true)}
                    onClickOutside={() => setBookingDateFocus(false)}
                    onSelect={() => setBookingDateFocus(false)}
                    open={bookingDateFocus}
                    readOnly={true}
                    className=" bg-transparent text-sm w-[100px] text-black text-center outline-0   "
                    dateFormat="d MMM , yyyy"
                    selected={parseISO(bookingDate)}
                    onChange={(date) => {
                      localStorage.setItem(
                        'bookingDate',
                        moment(date).format('YYYY-MM-DD')
                      );
                      setBookingDate(moment(date).format('YYYY-MM-DD'));
                      dispatch(
                        setUpperDate({
                          try: '',
                          del: moment(date).add(8, 'days').format('YYYY-MM-DD'),
                          cutting: '',
                        })
                      );
                      dispatch(
                        setLowerDate({
                          try: '',
                          del: moment().add(8, 'days').format('YYYY-MM-DD'),
                          cutting: '',
                        })
                      );
                    }}
                  />
                  <div className=" p-2 flex justify-center items-center">
                    <i
                      onClick={() => {
                        setBookingDateFocus(true);
                      }}
                      className="bi text-ggray text-[18px] bi-calendar2-event flex items-center "
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: `calc(100% - 80px)`,
              }}
              className="w-full   mt-2"
            >
              <ItemDetails
                parametrInput={parametrInput}
                triggerAnimationUpper={triggerAnimationUpper}
                setParameterInput={setParameterInput}
                bookingCustomerDetails={bookingCustomerDetails}
                ItemtabScreen={ItemtabScreen}
                SetItemTabScreen={SetItemTabScreen}
                setLowerQnty={setLowerQnty}
                setLowerTryDate={setLowerTryDate}
                setlowerDeleveryDate={setlowerDeleveryDate}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                editCellValues={editCellValues}
                mrcNumber={mrcNumber}
                lowertryDate={lowerTryDate}
                lowerDeleveryDate={lowerDeleveryDate}
                bookingDate={bookingDate}
                upperList={upperList}
                SetUpperList={SetUpperList}
                lowerList={lowerList}
                SetLowerList={SetLowerList}
              />
            </div>

            <div className="w-full flex flex-col h-[150px] ">
              <Remarks
                bookingAttachImage={bookingAttachImage}
                selectedUpperItemDetails={selectedUpperItemDetails}
                parametrInput={parametrInput}
                bookingCustomerDetails={bookingCustomerDetails}
                setBookingCustomerDetails={setBookingCustomerDetails}
                setParameterInput={setParameterInput}
                lowerTryDate={lowerTryDate}
                lowerDeleveryDate={lowerDeleveryDate}
                setLowerTryDate={setLowerTryDate}
                setlowerDeleveryDate={setlowerDeleveryDate}
                upperTryDateFocus={upperTryDateFocus}
                setUpperTryDateFocus={setUpperTryDateFocus}
                upperDeliveryDateFocus={upperDeliveryDateFocus}
                setUpperDeliveryDateFocus={setUpperDeliveryDateFocus}
                lowerTryDateFocus={lowerTryDateFocus}
                lowerCuttingDateFocus={lowerCuttingDateFocus}
                setLowerCuttingDateFocus={setLowerCuttingDateFocus}
                setLowerTryDateFocus={setLowerTryDateFocus}
                lowerDeliveryDateFocus={lowerDeliveryDateFocus}
                setLowerDeliveryDateFocus={setLowerDeliveryDateFocus}
                saveBooking={saveBooking}
                saveLoading={saveLoading}
                attachImage={attachImage}
                setAttachImageModStatus={setAttachImageModStatus}
                attachImageModStatus={attachImageModStatus}
                setAttachImage={setAttachImage}
                setAttachImageFolder={setAttachImageFolder}
                attachImageFolder={attachImageFolder}
                clearBookingFields={clearBookingFields}
                setCustomerFirebasePic={setCustomerFirebasePic}
                setHistoryOrderDetails={setHistoryOrderDetails}
                setMrcNumber={setMrcNumber}
                setBookingDate={setBookingDate}
                setBookingNumber={setBookingNumber}
                alertTwoButtonModal={alertTwoButtonModal}
                SetTabScreen={SetTabScreen}
                setBookingOrderNumber={setBookingOrderNumber}
                bookingDate={bookingDate}
                setAlertTwoButtonModal={setAlertTwoButtonModal}
                upperCuttingDateFocus={upperCuttingDateFocus}
                setUpperCuttingDateFocus={setUpperCuttingDateFocus}
                setCustomerHistory={setCustomerHistory}
              />
            </div>
          </div>

          {/* ...................style.......................................................... */}

          <div className="w-[50%]  h-full flex ml-2">
            <div className="w-1/2  h-full">
              <UpperStyle
                bookingCustomerDetails={bookingCustomerDetails}
                setBookingCustomerDetails={setBookingCustomerDetails}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setAnimationWidthUpper={setAnimationWidthUpper}
                parametrInput={parametrInput}
                setParameterInput={setParameterInput}
                setQnty={setUpperQnty}
                isAnimatingUpper={isAnimatingUpper}
                animationWidthUpper={animationWidthUpper}
                triggerAnimationUpper={triggerAnimationUpper}
                mrcNumber={mrcNumber}
                remarks={remarks.upper}
                setStatus={setStyleMasterStatus}
                setStyleType={setStyleType}
                ItemtabScreen={ItemtabScreen}
                SetItemTabScreen={SetItemTabScreen}
                editCellValues={editCellValues}
                setEditCellValues={setEditCellValues}
                bookingDate={bookingDate}
                lowerList={lowerList}
                alertTwoButtonModal={alertTwoButtonModal}
                setAlertTwoButtonModal={setAlertTwoButtonModal}
              />
            </div>
            <div className="w-1/2  ml-2 h-full ">
              <LowerStyle
                bookingCustomerDetails={bookingCustomerDetails}
                setBookingCustomerDetails={setBookingCustomerDetails}
                parametrInput={parametrInput}
                setParameterInput={setParameterInput}
                trydate={lowerTryDate}
                setQnty={setLowerQnty}
                SetTabScreen={SetTabScreen}
                mrcNumber={mrcNumber}
                isAnimatingLower={isAnimatingLower}
                animationWidthLower={animationWidthLower}
                triggerAnimationLower={triggerAnimationLower}
                remarks={remarks.lower}
                setStatus={setStyleMasterStatus}
                setStyleType={setStyleType}
                SetItemTabScreen={SetItemTabScreen}
                bookingDate={bookingDate}
                alertTwoButtonModal={alertTwoButtonModal}
                setAlertTwoButtonModal={setAlertTwoButtonModal}
              />
            </div>
            <ModAttach4Image
              bookingCustomerDetails={bookingCustomerDetails}
              setBookingCustomerDetails={setBookingCustomerDetails}
              attachImageModStatus={attachImageModStatus}
              setAttachImageModStatus={setAttachImageModStatus}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              capturedImage={capturedImage}
              setCapturedImage={setCapturedImage}
              webcamStatus={webcamStatus}
              setWebcamStatus={setWebcamStatus}
              capturedImage2={capturedImage2}
              setCapturedImage2={setCapturedImage2}
              webcamStatus2={webcamStatus2}
              setWebcamStatus2={setWebcamStatus2}
              selectedFile2={selectedFile2}
              setSelectedFile2={setSelectedFile2}
              capturedImage3={capturedImage3}
              setCapturedImage3={setCapturedImage3}
              webcamStatus3={webcamStatus3}
              setWebcamStatus3={setWebcamStatus3}
              selectedFile3={selectedFile3}
              setSelectedFile3={setSelectedFile3}
              capturedImage4={capturedImage4}
              setCapturedImage4={setCapturedImage4}
              webcamStatus4={webcamStatus4}
              setWebcamStatus4={setWebcamStatus4}
              selectedFile4={selectedFile4}
              setSelectedFile4={setSelectedFile4}
            />

            <ModStyleMaster
              setStatus={setStyleMasterStatus}
              status={styleMasterStatus}
              styleType={styleType}
            />
          </div>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="hidden">
                    <button
                      style={{
                        width: '100%',
                        borderRadius: '7px',
                        height: '100%',
                        backgroundColor: '#bad4f9',
                        color: 'black',
                      }}
                    >
                      Print Report
                    </button>
                  </div>
                );
              }} // This is a dummy trigger since you don't want a button
              content={() => componentRef.current} // Reference to the component you want to print
              onAfterPrint={() => {
                clearBookingFields();
              }}
            />
            <div className="hidden print:flex">
              <MeasurmentSlip
                ref={componentRef}
                upperData={upperMeasrment}
                lowerData={lowerMeasrment}
                bookingOrderNumber={bookingOrderNumber}
                lowerTryDate={lowerTryDate}
                lowerDeleveryDate={lowerDeleveryDate}
                lowerRemarks={remarks.lower}
                upperRemarks={remarks.upper}
                mobile={bookingCustomerDetails.mobile}
                date={moment(bookingDate).format('YYYY-MM-DD')}
                customer_name={bookingCustomerDetails.customer_name}
                bookingCustomerDetails={bookingCustomerDetails}
                selectedUpperItemDetails={selectedUpperItemDetails}
                selectedLowerItemDetails={selectedLowerItemDetails}
              />
            </div>
            {bookingOrderNumber && (
              <div className="hidden print:flex">
                <QrCodeSlip
                  ref={QrprintRef}
                  value={bookingOrderNumber}
                  item={items}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default Measurements;
