import axios from 'axios';
import { isFireBaseLoginOn } from '../utility/common';
import { child, get, set, ref } from 'firebase/database';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  setfirebaseStatusField,
  setIsProductionModuleAllowed,
  setmasterTablePrifx,
  setUserCode,
} from '../utility/Redux/profile';
import { deleteWholeDatabase, HandleIndexedDB } from '../utility/func/func';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { setToken } from '../utility/Redux/security';
import database from '../utility/component/functions/firebase';
const secretPass = process.env.REACT_APP_SECRET_KEY;
const authSecret = process.env.REACT_APP_AUTH_SECRET_KEY;

const excuteFirebase = (
  userDetail,
  dispatch,
  expireAt,
  loginResponse,
  email,
  remember,
  password,
  move,
  i,
  maxLimit
) => {
  checkFirebaseLoginManager(
    userDetail.table_prefix,
    userDetail.code,
    dispatch,
    expireAt,
    `status${i}`
  )
    .then(() => {
      moveFinancialyear(
        userDetail,
        loginResponse,
        email,
        dispatch,
        remember,
        password,
        move,
        expireAt
      );
    })
    .catch((o) => {
      if (i < maxLimit) {
        i++;
        excuteFirebase(
          userDetail,
          dispatch,
          expireAt,
          loginResponse,
          email,
          remember,
          password,
          move,
          i,
          maxLimit
        );
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'This account is already logged in.',
          })
        );
      }
    });
};
export function runLoginFunc(
  dispatch,
  email,
  password,
  hmacString,
  secretPass,
  remember,
  move
) {
  dispatch(setSpinnerLoading('Loading'));
  loadUserInfo(email, hmacString)
    .then((userDetail) => {
      if (isFireBaseLoginOn) {
        verifyCredintails(email, password, hmacString, dispatch)
          .then((loginResponse) => {
            const expireAt = new Date().getTime() + 60 * 60000;
            let maxLimit = userDetail.max_user_login_count;
            let attemptedCount = 1;
            excuteFirebase(
              userDetail,
              dispatch,
              expireAt,
              loginResponse,
              email,
              remember,
              password,
              move,
              attemptedCount,
              maxLimit
            );
          })
          .catch((err) => {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: err,
              })
            );
          });
      }
    })
    .catch((err) => {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: err,
        })
      );
    });
}
export const loadUserInfo = (email, hmacString) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        '/api/users/userInfo',
        {
          user_email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then((e) => {
        if (!e.data.error) {
          if (e?.data?.response[0]) {
            if (e?.data?.response[0].email_verified === 'N') {
              reject(
                'Your account email needs to be verified. Please check your inbox for a verification link'
              );
            } else {
              if (Number(e?.data?.response[0].valid_user) !== 1) {
                reject(
                  'Thank you for registering. Your account is currently being processed. You will be able to login once your account has been activated.'
                );
              } else {
                resolve(e?.data?.response[0]);
              }
            }
          } else {
            reject('User not found');
          }
        } else {
          reject('Something went wrong');
        }
      })
      .catch(() => {
        reject('Something went wrong');
      });
  });
};

export const verifyCredintails = (email, password, hmacString, dispatch) => {
  return new Promise((resolve, reject) => {
    const data = {
      user_email: email ? email : '',
      user_password: password,
    };
    axios
      .post('/api/users/login', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((response) => {
        if (response !== null) {
          if (response.data.error) {
            reject(response.data.error);

            dispatch(setSpinnerLoading(false));
          } else {
            resolve(response);
          }
        } else {
          reject('Something went wrong');
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error) {
          reject(e?.response?.data?.error);
        } else {
          reject('Something went wrong');
        }
      });
  });
};
const storeRememberData = async (email, passwrd) => {
  const e = CryptoJS.AES.encrypt(JSON.stringify(email), secretPass).toString();
  const p = CryptoJS.AES.encrypt(
    JSON.stringify(passwrd),
    secretPass
  ).toString();

  localStorage.setItem(
    'rememberd',
    JSON.stringify({
      email: e,
      password: p,
    })
  );
};
const moveFinancialyear = (
  userDetail,
  response,
  email,
  dispatch,
  remember,
  password,
  move,
  expireAt
) => {
  if (
    moment(userDetail.end_date).format('YYYY-MM-DD') >=
    moment().format('YYYY-MM-DD')
  ) {
    if (userDetail.user_email === email) {
      if (userDetail.table_prefix !== null) {
        if (userDetail.user_status === 'ACTIVE') {
          const productionStatus = userDetail.production_module;
          dispatch(
            setIsProductionModuleAllowed(
              productionStatus === 'Y' ? true : false
            )
          );
          const databaseName = 'userDetails';
          const storeName = 'data';
          if (userDetail) {
            deleteWholeDatabase(databaseName);

            const dataToSave = [
              {
                start_date: userDetail.start_date,
                end_date: userDetail.end_date,
                currency_name: userDetail.currency_name,
                currency_symbol: userDetail.currency_symbol,
                user_email: userDetail.user_email,
                ICODE: 1,
                address: userDetail.address,
                city: userDetail.city,
                company_email: userDetail.company_email,
                company_name: userDetail.company_name,
                company_website: userDetail.company_website,
                user_mobile: userDetail.user_mobile,
                user_name: userDetail.user_name,
              },
            ];

            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
            });
          }

          const isProductionAllow = CryptoJS.AES.encrypt(
            productionStatus,
            authSecret
          ).toString();
          const encrptCode = CryptoJS.AES.encrypt(
            userDetail.code.toString(),
            process.env.REACT_APP_SECRET_KEY
          ).toString();
          localStorage.setItem('isProductionAllow', isProductionAllow);
          localStorage.setItem('userCode', encrptCode);
          dispatch(setUserCode(userDetail.code));
          dispatch(setmasterTablePrifx(userDetail.table_prefix));

          dispatch(setToken(response.data.token));

          if (remember) {
            storeRememberData(email, password);
          } else {
            localStorage.removeItem('rememberd');
          }
          const encryptPass = CryptoJS.AES.encrypt(
            password,
            secretPass
          ).toString();
          localStorage.setItem('p', encryptPass);
          localStorage.setItem('expiresAt', expireAt);

          move('/auth/financial_year', {
            state: {
              CompanyEmail: userDetail.company_email,
              UserEmail: email,
              companyName: userDetail.company_name,
            },
          });
        } else {
          dispatch(
            setAlertMsg(
              'Access to your account has been denied. Please contact the admin to activate your account.'
            )
          );

          dispatch(setALertStatus(true));
        }
      } else {
        dispatch(setAlertMsg('Crediantials Issue Kindly Contact Support Team'));
        dispatch(setALertStatus(true));
      }

      //  localStorage.setItem('token',  response.data.token );
    } else {
      dispatch(setAlertMsg('Email does not exist'));
      dispatch(setALertStatus(true));
    }
  } else {
    dispatch(
      setAlertMsg(
        'Your License has Expired.Please Contact support for renewal.'
      )
    );
    dispatch(setALertStatus(true));
  }
};
const checkIsRejected = (connectedRef, statusField, dispatch) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      get(child(connectedRef, `/${statusField}/msg`))
        .then((msg) => {
          if (msg.exists()) {
            const data = msg.val();

            if (!data.includes('rejected')) {
              resolve(); // Successfully resolved
            } else {
              reject(new Error('Connection rejected')); // Rejected with an error
            }
          } else {
            reject(new Error('No message found')); // Rejected due to missing message
          }
        })
        .catch((err) => {
          console.log(err);

          reject(err); // Handle any errors from the Firebase get function
        });
    }, 4000);
  });
};
const checkFirebaseLoginManager = (
  prefix,
  userCode,
  dispatch,
  expiresAt,
  statusField
) => {
  return new Promise((resolve, reject) => {
    const connected = ref(database, `loginAuth/${prefix}/user/${userCode}`);
    const setConnectMsgRef = ref(
      database,
      `loginAuth/${prefix}/user/${userCode}/${statusField}/msg`
    );
    const setConnectRef = ref(
      database,
      `loginAuth/${prefix}/user/${userCode}/${statusField}/connection`
    );

    // Dispatch initial state
    dispatch(setfirebaseStatusField(statusField));

    // Set the connection message
    set(setConnectMsgRef, `isConnected`);

    // Proceed to check rejection status
    checkIsRejected(connected, statusField, dispatch)
      .then(() => {
        set(setConnectRef, `c-${expiresAt}`);
        set(setConnectMsgRef, 'connected');
        dispatch(setfirebaseStatusField(statusField));
        // If checkIsRejected resolves, resolve the outer promise
        resolve();
      })
      .catch((error) => {
        console.log(error);
        // If checkIsRejected rejects, reject the outer promise with the error
        reject(error);
      });
  });
};
