import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { AddTocartHeader } from '../utility/component/datagrid.js/header';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectTableMasterPrefix,
} from '../utility/Redux/profile';
import { useEffect } from 'react';
import { selectToken } from '../utility/Redux/security';
import { useState } from 'react';
import moment from 'moment';
import { AddToCartButton } from '../utility/component/buttons';
import CreateBillingCartWithTax from '../utility/component/functions/createBillingCartWithTax';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import {
  selectModifyBillingCartlist,
  setBillingCartList,
} from '../utility/Redux/billing';
import { selectCustomerPrefix } from '../utility/Redux/booking';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { useNavigate } from 'react-router-dom';

const AddToCart = ({
  bookingCustomerDetails,
  mrcNumber,
  bookingOrderNumber,
  OrderNumberList,
}) => {
  const prifix = useSelector(selectPrefix);
  const masterprefix = useSelector(selectTableMasterPrefix);
  const [list, setlist] = useState([]);
  const token = useSelector(selectToken);
  const modifyList = useSelector(selectModifyBillingCartlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerPrefix = useSelector(selectCustomerPrefix);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const StyleColums = [
    {
      field: 'BOOKING_ORDER_NO',
      type: 'string',
      align: 'center',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 5,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Order'} </div>,
    },

    {
      field: 'DATE',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div>{moment(params.value).format('DD-MM-YYYY')}</div>
      ),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Date'} </div>,
    },

    {
      field: 'select',
      type: 'string',
      width:
        window.innerWidth <= 1024
          ? (window.innerWidth / 100) * 8
          : (window.innerWidth / 100) * 8,
      align: 'right',
      editable: false,
      renderCell: (param) => {
        return (
          <div
            className="flex justify-center w-full"
            onClick={() => {
              if (param.value === 'NO') {
                let index = list.findIndex((item) => item.id === param.row.id);
                const updatedList = list.map((item) => {
                  const data = item;
                  if (item.id === param.row.id) {
                    return { ...item, select: 'YES' };
                  } else {
                    return data;
                  }
                });
                setlist(updatedList);
              } else {
                let index = list.findIndex((item) => item.id === param.row.id);
                const updatedList = list.map((item) => {
                  const data = item;
                  if (item.id === param.row.id) {
                    return { ...item, select: 'NO' };
                  } else {
                    return data;
                  }
                });
                setlist(updatedList);
              }
            }}
          >
            {param.value === 'NO' ? (
              <i className="bi bi-square"></i>
            ) : (
              <i className="bi bi-check-square"></i>
            )}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div className="text-white"> {'Select'} </div>,
    },
  ];

  const loadOrdersForBill = () => {
    dispatch(setSpinnerLoading('Loading Bill'));
    axios
      .post(
        `/api/booking/loadOrdersForBill`,
        {
          table_prefix: prifix,
          MRC_NO: mrcNumber,
          PREFIX: customerPrefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (!response?.data?.error) {
          const data = response.data.response;
          let rowList = [];
          for (let index = 0; index < data.length; index++) {
            const isLastBill = OrderNumberList.filter(
              (o) => o === data[index].BOOKING_ORDER_NO
            );
            rowList.push({
              ...data[index],
              id: index + 1,
              select: OrderNumberList[0]
                ? isLastBill[0]
                  ? 'YES'
                  : 'NO'
                : 'NO',
            });
          }
          setlist(rowList);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => dispatch(setSpinnerLoading(false)));
  };

  const loadOrdersDetails = (t, order) => {
    const filterdList = list.filter((item) => item.select === 'YES');
    if (filterdList[0] || order) {
      const bookinList = filterdList.map((item) => item.BOOKING_ORDER_NO);
      dispatch(setSpinnerLoading('loading order details'));
      const data = {
        table_prefix: prifix,
        table_prefix_master: masterprefix,
        BOOKING_ORDER_NO: `(${order ? order : bookinList})`,
        MRC_NO: mrcNumber,
        PREFIX: customerPrefix,
      };
      if (bookinList[0] || order) {
        axios
          .post(`/api/booking/loadOrdersItemsForBilling`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            if (!response?.data?.error) {
              const data = response.data.response;
              let billingList = [];
              for (let i = 0; i < data.length; i++) {
                const itemDetails = {
                  icode: data[i].ICODE,
                  item_name: data[i].ITEM_NAME,
                  regional_iname: '',
                  section_name: data[i].SECTION_NAME,
                  upper_lower: data[i].UPPER_LOWER,
                  rate: data[i].RATE,
                  sewing_hsn_code: data[i].SEWING_HSN_CODE,
                  sewing_tax1_rate: data[i].SEWING_TAX1_RATE,
                  sewing_tax2_rate: data[i].SEWING_TAX2_RATE,
                  sewing_tax3_rate: data[i].SEWING_TAX3_RATE,
                  cutting_hsn_code: data[i].CUTTING_HSN_CODE,
                  cuting_tax1_rate: data[i].CUTING_TAX1_RATE,
                  cuting_tax2_rate: data[i].CUTING_TAX2_RATE,
                  cuting_tax3_rate: data[i].CUTING_TAX3_RATE,
                };
                const customerDetails = {
                  code: data[i].CODE,
                  customer_name: data[i].CUSTOMER_NAME,
                  familyMemberName: data[i].FAMILY_MEM_NAME,
                  mobile: data[i].MOBILE,
                  phone: data[i].PHONE,
                  address: data[i].ADDRESS,
                  city: data[i].CITY,
                  state: data[i].STATE,
                  country: data[i].COUNTRY,
                  company_name: bookingCustomerDetails.company_name,
                };

                const l = CreateBillingCartWithTax(
                  itemDetails,
                  customerDetails,
                  data[i].QNTY,
                  data[i].BOOKING_ORDER_NO,
                  data[i].REMARKS,
                  mrcNumber,
                  customerPrefix
                );

                billingList.push(l);
              }

              dispatch(setBillingCartList(billingList));
            }
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      } else {
        dispatch(setBillingCartList([]));
        dispatch(setSpinnerLoading(false));
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Please select any order to add bill.',
        })
      );
    }
  };

  useEffect(() => {
    if (!modifyList[0]) {
      loadOrdersForBill();
    }
  }, []);
  useEffect(() => {
    if (OrderNumberList[0] && list[0]) {
      loadOrdersDetails();
    }
  }, [list, OrderNumberList]);
  return (
    <div className="h-full w-full   flex flex-col justify-between">
      {' '}
      <div
        style={{
          height: window.innerWidth <= 1424 ? '100%' : '100%',
          border: '1px solid #e8e2db',
        }}
        className="rounded-sm overflow-hidden"
      >
        <DataGrid
          sx={{
            backgroundColor: 'white',
            height: window.innerWidth <= 1424 ? '100%' : '100%',
            border: '0px solid #e8e2db',
            fontSize: 14,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
              display: 'none',
            },
            '& .MuiDataGrid-pagination': {
              display: 'none',
            },
          }}
          rowHeight={33}
          headerHeight={0}
          columns={StyleColums}
          rows={list}
          getRowId={(item) => item.id}
          hideFooter
          scrollbarSize={0}
          rowsPerPageOptions={[100]}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          editMode="cell"
          // onCellEditCommit={editCell}
          disableColumnMenu={true}
          components={{
            Header: () => (
              <AddTocartHeader columns={StyleColums} setlist={setlist} />
            ),

            NoRowsOverlay: () => (
              <div className="w-full h-full flex items-center justify-center text-ggray">
                No Order
              </div>
            ),
          }}
        />
      </div>
      <div className="w-full my-2 h-8 ">
        <AddToCartButton onClick={loadOrdersDetails} />
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default AddToCart;
