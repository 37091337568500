import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { SchemaValidateCompany } from './schemaValidate';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import AlertModal from '../utility/component/modals/alert';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Country, State, City } from 'country-state-city';
import TailringLogo from '../utility/images/tailiringLogo.svg';
import talringX from '../utility/images/Tailoring_Text.svg';
import Gicon from '../utility/images/G_Icon.svg';

import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckVerifyEmailAlert,
} from '../utility/Redux/modal';
import {
  ArrowBack_button,
  Next_button,
  Signup_button,
} from '../utility/component/buttons';
import AlertModal2 from '../utility/component/modals/alertWithTitle';
import moment from 'moment';
export const PhoneCompnySignup = () => {
  const inputprops = {
    sx: {
      fontSize: '14px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.5px solid #6e6d6b',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#03a9f4', // Change to your desired color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '0.1px solid #03a9f4', // Change to your desired color on focus
      },
    },
  };
  const inputLabels = {
    sx: {
      fontSize: '14px',
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [isCompanyExist, setCompnyExist] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [companyEmail, setCmpnyEmail] = useState();
  const [allReadyAddedUser, setAllReadyAddedUser] = useState();
  const [companyTypeDetails, setcomTypeDetails] = useState(true);
  const countries = Country.getAllCountries();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);

  const initialValues = {
    company: '',
    group: '',
    email: '',
    address: '',
    city: '',
    state: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    userEmail: '',
    userName: '',
    companyPassword: '',
  };

  function handleEnter(event) {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);

      if (index !== 18) {
        form.elements[index + 2].focus();
      } else {
        form.elements[index + 3].focus();
      }

      event.preventDefault();
    }
  }
  const createFinacialYear = (code, token) => {
    const data = {
      company_code: code,
      company_email: values.email.toLowerCase(),
      company_name: values.company,
      year: moment().format('YYYY'),
    };
    axios
      .post(
        `/api/users/createFinancialYear`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log('');
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaValidateCompany,
    onSubmit: (values) => {
      const text = values.company;
      const words = text.split(' ');

      const result =
        words.reduce((acc, word) => {
          if (word) {
            acc += word[0];
          }
          return acc;
        }, '') + '_';
      if (!isUserExist) {
        const data = {
          user_email: values.userEmail.toLowerCase(),
          user_password: values.password,
          user_name: values.userName,
          user_type: 'MANAGER',
          user_status: 'ACTIVE',
          company_email: values.email.toLowerCase(),
          company_name: values.company,
          company_mobile: values.mobile,
          company_website: values.group,
          company_logo: '',
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          pin_code: values.pincode,
          currency_name: 'rupee',
          currency_symbol: '₹',
          regd_no1: '',
          regd_no2: '',
          regd_no3: '',
          disputes: `All Disputes Subject to ${values.city}`,
          table_prefix: result.toLowerCase(),
          // start_date: moment().format('YYYY-MM-DD'),
          // end_date: moment().add(25, 'days').format('YYYY-MM-DD'),
        };
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        const email = values.userEmail;

        const hmac = CryptoJS.HmacSHA256(email, secretPass);

        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        axios
          .post(`/api/users/registerCompany`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              localStorage.clear('rememberd');
              // localStorage.setItem('user',JSON.stringify(values))
              const code = response.data.results.insertId;
              const token = response.data.token;
              createFinacialYear(code, token);
              navigate('/auth/login', {
                state: {
                  signupEmail: values.userEmail,
                },
              });
              dispatch(setCheckVerifyEmailAlert(true));
            } else {
              dispatch(setAlertWithTitle('Something went wrong'));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  });

  const CompanyInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = values.email;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: values.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response.data[0]) {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Company already Registered',
            })
          );
          values.email = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const userInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = values.userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    axios
      .post(
        `/api/users/userInfo`,
        {
          user_email: values.userEmail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response.data.response([0])) {
          dispatch(setAlertMsg('User exist with same email '));
          dispatch(setALertStatus(true));
          values.userEmail = '';

          setAllReadyAddedUser(values.userEmail);
          setUserExist(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(values.email)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
      CompanyInfo();
    }

    //  if (values.email.includes('@')){
    //   CompanyInfo()
    // }
    if (companyEmail !== values.email) {
      setCompnyExist(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (isCompanyExist) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(values.userEmail)) {
        // this is a valid email address
        // call setState({email: email}) to update the email
        // or update the data in redux store.
        userInfo();
      }
    }
    if (allReadyAddedUser !== values.userEmail) {
      setUserExist(false);
    } else {
      setUserExist(true);
    }
  }, [values.userEmail, values.email]);

  useEffect(() => {
    if (values.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === values.country
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [values.country]);
  useEffect(() => {
    if (values.state && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === values.state
      );
      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [values.state]);

  const handleSignup = () => {
    if (!values.email || errors.email) {
      dispatch(setAlertMsg('Please enter company email'));
      dispatch(setALertStatus(true));
    } else {
      if (!values.company || errors.company) {
        dispatch(setAlertMsg('Please enter company name'));
        dispatch(setALertStatus(true));
      } else {
        if (!values.group || errors.group) {
          dispatch(setAlertMsg('Please enter website'));
          dispatch(setALertStatus(true));
        } else {
          if (!values.address || errors.address) {
            dispatch(setAlertMsg('Please enter company address'));
            dispatch(setALertStatus(true));
          } else {
            if (!values.city || errors.city) {
              dispatch(setAlertMsg('Please enter city'));
              dispatch(setALertStatus(true));
            } else {
              if (!values.state || errors.state) {
                dispatch(setAlertMsg('Please enter state'));
                dispatch(setALertStatus(true));
              } else {
                if (!values.mobile || errors.mobile) {
                  dispatch(setAlertMsg('Please enter company mobile'));
                  dispatch(setALertStatus(true));
                } else {
                  if (!values.userEmail || errors.userEmail) {
                    dispatch(setAlertMsg('Please enter user email'));
                    dispatch(setALertStatus(true));
                  } else {
                    if (!values.userName || errors.userName) {
                      dispatch(setAlertMsg('Please enter user name'));
                      dispatch(setALertStatus(true));
                    } else {
                      if (!values.password || errors.password) {
                        dispatch(setAlertMsg('Please enter password'));
                        dispatch(setALertStatus(true));
                      } else {
                        if (!values.confirmPassword || errors.confirmPassword) {
                          dispatch(
                            setAlertMsg('Please enter confirm password')
                          );
                          dispatch(setALertStatus(true));
                        } else {
                          handleSubmit();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <div className=" items-center flex  px-10 w-screen  h-screen justify-center bg-white  ">
      <div
        className={`  bg-white  rounded-lg    pb-4  mt-10 mb-10    `}
        style={{ width: '100%' }}
      >
        <div>
          <div className="  h-[110%] ">
            <div className="flex   h-full">
              <div className=" flex flex-col  justify-center items-center   h-full w-full ">
                <div
                  style={{
                    height: '100px',
                    width: '100px',
                  }}
                >
                  <img src={TailringLogo} />
                </div>
                <div className="flex  justify-center  ">
                  <div className="  flex justify-center  ">
                    <div>
                      <div className="w-full   flex justify-center">
                        <div
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            height: 'auto',
                            width: 134,
                            backgroundColor: 'white',
                            borderRadius: 100,
                          }}
                        >
                          <img src={talringX} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-1 font-semibold "> SignUp</div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex-col  t:flex  w-full text-sm mt-2 ">
            {/* <div className="flex  py-2 mx-2 justify-center items-center">
              <button
                className="flex w-6/12 justify-center flex:1 "
                onClick={() => setcomTypeDetails(true)}
              >
                <div
                  className={`font-normal tracking-[.06em]   ${
                    companyTypeDetails ? 'text-orange' : 'text-gray '
                  } `}
                >
                  Company Details
                </div>{' '}
              </button>

              <button
                className=" flex w-6/12 justify-center flex:1 "
                onClick={() => setcomTypeDetails(false)}
              >
                <div
                  className={` font-normal tracking-[.06em]   text-normal ${
                    companyTypeDetails ? 'text-gray' : 'text-orange'
                  } `}
                >
                  User Details
                </div>
              </button>
            </div> */}
            {/* company details */}

            {companyTypeDetails ? (
              <div className=" w-full  ">
                <div className="w-full pt-1">
                  <div className="flex w-full">
                    <div className="w-full  ">
                      <div className="flex   items-center">
                        <div className="w-full">
                          <TextField
                            size={'small'}
                            onKeyDown={handleEnter}
                            type="email"
                            InputProps={inputprops}
                            InputLabelProps={inputLabels}
                            name="email"
                            id="email"
                            label={
                              values.email ? errors.email : 'Company Email'
                            }
                            onBlur={handleBlur}
                            error={values.email && errors.email ? true : false}
                            fullWidth
                            // placeholder='Company Email'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.email}
                          />
                        </div>
                      </div>

                      <div className="flex  w-full items-center mt-3  ">
                        <div className=" w-full">
                          <TextField
                            fullWidth
                            type="company"
                            name="company"
                            id="company"
                            InputProps={inputprops}
                            InputLabelProps={inputLabels}
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? 'Company Name'
                                : values.company
                                ? errors.company
                                : 'Company Name'
                            }
                            error={
                              isCompanyExist
                                ? false
                                : values.company && errors.company
                                ? true
                                : false
                            }
                            //  placeholder="Company Name"
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.company}
                            size={'small'}
                          />
                        </div>
                      </div>

                      <div className="flex mt-3  items-center">
                        <div className="w-full">
                          <TextField
                            size={'small'}
                            onKeyDown={handleEnter}
                            type="group"
                            name="group"
                            id="group"
                            InputProps={inputprops}
                            InputLabelProps={inputLabels}
                            label={
                              isCompanyExist
                                ? 'Website'
                                : values.group
                                ? errors.group
                                : 'Website'
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.group && errors.group
                                ? true
                                : false
                            }
                            fullWidth
                            //  placeholder='Your group'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.group}
                          />
                        </div>
                      </div>

                      <div className="flex mt-3  items-center">
                        <div className="w-full">
                          <TextField
                            size={'small'}
                            type="address"
                            name="address"
                            id="address"
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? 'Address'
                                : values.address
                                ? errors.address
                                : 'Address'
                            }
                            InputProps={inputprops}
                            InputLabelProps={inputLabels}
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.address && errors.address
                                ? true
                                : false
                            }
                            fullWidth
                            // placeholder='Your address'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.address}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex mt-3 w-full items-center">
                          <div className=" w-full">
                            <TextField
                              select
                              InputProps={inputprops}
                              InputLabelProps={inputLabels}
                              size="small"
                              id="country"
                              name="country"
                              label="Country"
                              value={values.country}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Adjust the height as needed
                                      maxWidth: 150,
                                    },
                                  },
                                },
                              }}
                              fullWidth
                              onChange={handleChange}
                            >
                              {countries.map((e, index) => (
                                <MenuItem
                                  id="country"
                                  name="country"
                                  label="Country"
                                  key={index}
                                  value={e.name}
                                >
                                  {e.name}
                                </MenuItem>
                              ))}

                              {/* Add more MenuItem components as needed */}
                            </TextField>
                          </div>
                        </div>

                        <div className="flex mt-3  w-full  ml-3 items-center">
                          <div className="w-full">
                            <TextField
                              select
                              InputProps={inputprops}
                              InputLabelProps={inputLabels}
                              size="small"
                              id="state"
                              name="state"
                              label="State"
                              value={values.state}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Adjust the height as needed
                                      maxWidth: 150,
                                    },
                                  },
                                },
                              }}
                              fullWidth
                              onChange={handleChange}
                            >
                              {stateList.map((e, index) => (
                                <MenuItem
                                  id="country"
                                  name="country"
                                  label="Country"
                                  key={index}
                                  value={e.name}
                                >
                                  {e.name}
                                </MenuItem>
                              ))}

                              {/* Add more MenuItem components as needed */}
                            </TextField>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex mt-3 w-full items-center">
                          <div className=" w-full">
                            <TextField
                              select
                              InputProps={inputprops}
                              InputLabelProps={inputLabels}
                              size="small"
                              id="city"
                              name="city"
                              label="City"
                              value={values.city}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Adjust the height as needed
                                      maxWidth: 150,
                                    },
                                  },
                                },
                              }}
                              fullWidth
                              onChange={handleChange}
                            >
                              {cityList.map((e, index) => (
                                <MenuItem
                                  id="city"
                                  name="city"
                                  label="City"
                                  key={index}
                                  value={e.name}
                                >
                                  {e.name}
                                </MenuItem>
                              ))}

                              {/* Add more MenuItem components as needed */}
                            </TextField>
                          </div>
                        </div>

                        <div className="flex mt-3  w-full  ml-3 items-center">
                          <div className="w-full">
                            <TextField
                              size={'small'}
                              type="text"
                              onKeyDown={handleEnter}
                              InputProps={inputprops}
                              InputLabelProps={inputLabels}
                              name="pincode"
                              id="pincode"
                              label={'Pin Code'}
                              onBlur={handleBlur}
                              error={
                                isCompanyExist
                                  ? false
                                  : values.pincode && errors.pincode
                                  ? true
                                  : false
                              }
                              fullWidth
                              onChange={handleChange}
                              onSubmit={handleSubmit}
                              value={values.pincode}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex mt-3  items-center">
                        <div className="w-full">
                          <TextField
                            size={'small'}
                            type="number"
                            name="mobile"
                            id="mobile"
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? 'Company Mobile'
                                : values.mobile
                                ? errors.mobile
                                : 'Company Mobile'
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.mobile && errors.mobile
                                ? true
                                : false
                            }
                            fullWidth
                            // placeholder='Company Mobile'
                            onChange={handleChange}
                            InputProps={inputprops}
                            InputLabelProps={inputLabels}
                            onSubmit={handleSubmit}
                            value={values.mobile}
                          />
                        </div>
                      </div>

                      <div className="flex mt-3 items-center "></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grow ">
                <div className="w-full pt-1">
                  <div className="flex mt-3  items-center">
                    <div className="w-full">
                      <TextField
                        size={'small'}
                        type="email"
                        name="userEmail"
                        onKeyDown={handleEnter}
                        id="userEmail"
                        label={
                          values.userEmail
                            ? isUserExist
                              ? 'Already Registerd Email'
                              : errors.userEmail
                            : ' User Email'
                        }
                        onBlur={handleBlur}
                        error={
                          values.userEmail
                            ? errors.userEmail || isUserExist
                              ? true
                              : false
                            : false
                        }
                        fullWidth
                        //  placeholder='User Email'
                        onChange={handleChange}
                        InputProps={inputprops}
                        InputLabelProps={inputLabels}
                        onSubmit={handleSubmit}
                        value={values.userEmail}
                      />
                    </div>
                  </div>

                  <div className="flex w-full items-center pt-2 ">
                    <div className=" w-full">
                      <TextField
                        fullWidth
                        type="userName"
                        name="userName"
                        id="userName"
                        onKeyDown={handleEnter}
                        label={values.userName ? errors.userName : ' User Name'}
                        error={
                          values.userName && errors.userName ? true : false
                        }
                        // placeholder="First userName"
                        onChange={handleChange}
                        InputProps={inputprops}
                        InputLabelProps={inputLabels}
                        onSubmit={handleSubmit}
                        value={values.userName}
                        size={'small'}
                      />
                    </div>
                  </div>

                  <div className="flex mt-3 items-center ">
                    <div className="w-full flex">
                      <TextField
                        size={'small'}
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        error={
                          values.password && errors.password ? true : false
                        }
                        label={values.password ? errors.password : 'Password'}
                        name="password"
                        onKeyDown={handleEnter}
                        id="password"
                        // placeholder='Password'
                        onChange={handleChange}
                        InputLabelProps={inputLabels}
                        onSubmit={handleSubmit}
                        value={values.password}
                        InputProps={{
                          ...inputprops,
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex mt-3 w-full items-center">
                    <div className="w-full ">
                      <TextField
                        size={'small'}
                        fullWidth
                        label={
                          values.confirmPassword
                            ? errors.confirmPassword
                            : 'Confirm Password'
                        }
                        error={
                          values.confirmPassword && errors.confirmPassword
                            ? true
                            : false
                        }
                        type={showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        onKeyDown={handleEnter}
                        id="confirmPassword"
                        // placeholder='confirmPassword Password'
                        onChange={handleChange}
                        InputLabelProps={inputLabels}
                        onSubmit={handleSubmit}
                        value={values.confirmPassword}
                        InputProps={{
                          ...inputprops,
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-1">
                    <button
                      onClick={() => {
                        handleClickShowPassword();
                      }}
                    >
                      Show Password
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* user details */}
          </div>

          {companyTypeDetails ? (
            <div className="w-full flex justify-center">
              <Next_button onClick={() => setcomTypeDetails(false)} />
            </div>
          ) : (
            <div className="   pt-6  flex justify-center ">
              <ArrowBack_button onClick={() => setcomTypeDetails(true)} />
              <Signup_button onClick={handleSignup} />
            </div>
          )}
          {/* <div className='w-full flex justify-center pt-2 '>
            <Button type='submit'  variant='contained' color='orange' sx={{width:'60%'}} > <div >Signup </div> </Button> 
                  </div> */}
        </form>
        <AlertModal />

        <div className="w-full flex justify-center text-sm pt-3 ">
          Already have an account ?
          <div
            onClick={() => navigate('/auth/login')}
            className="text-orange font-medium pl-2"
          >
            Login
          </div>
        </div>
        <div className=" mt-8 text-[13px] w-full justify-center text- text-brown flex">
          A product of{' '}
          <div className="pl-1 flex items-center">
            <img
              alt=""
              src={Gicon}
              style={{
                width: '14px',
                height: '14px',
              }}
            />
          </div>
          <label className="font-semibold pl-1 pr-1"> genius office</label>
          2023 All rights reserved.
        </div>
        <AlertModal />
        <AlertModal2 />
      </div>
    </div>
  );
};
