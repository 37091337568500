import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
  SelectAlertStatus,
  selectAlertMsg,
  setALertStatus,
  selectAlertOkStatus,
  setAlertOkStatus,
  setloading,
} from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/ico.png';

export default function AlertModal() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(SelectAlertStatus);
  const alertMsg = useSelector(selectAlertMsg);

  const okStatus = useSelector(selectAlertOkStatus);
  const handleClose = () => dispatch(setALertStatus(false));

  const myFunction = () => {
    // your logic here
    if (alertStatus) {
      if (Number(okStatus) === 0) {
        dispatch(setAlertOkStatus(Number(okStatus) + 1));
      } else {
        dispatch(setAlertOkStatus(0));
        dispatch(setALertStatus(false));
        dispatch(setloading(false));
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        event.preventDefault();

        // 👇️ your logic here
        if (alertStatus) {
          myFunction();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [alertStatus, okStatus]);

  return (
    <div className=" rounded-lg">
      <Modal open={alertStatus}>
        <div
          className=" bg-brown overflow-hidden rounded-lg  "
          style={{
            position: 'absolute',
            top:
              window.innerWidth <= 500
                ? '50%'
                : window.innerWidth <= 1024
                ? '40%'
                : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            divShadow: 24,
          }}
        >
          <div className="text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 max-w-[450px] pt-3 items-center ">
            <div className="flex  w-full pl-7 pr-7 pb-2 items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown  d:text-md ">{alertMsg}</div>
              </div>
            </div>
            <div className="flex justify-end pb-3  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                <div className="px-3">Ok </div>{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
