import { useRef, useState } from 'react';
import {
  selectBillingAdvanceAmount,
  selectBillingCartList,
  selectBillSettlement,
  setAdvanceVoucherDetails,
  setBillingAdvanceAmount,
  setBillingCartList,
  setBillSettlement,
} from '../utility/Redux/billing';
import { useDispatch, useSelector } from 'react-redux';

import { selectToken } from '../utility/Redux/security';
import { selectPrefix } from '../utility/Redux/profile';
import moment from 'moment';
import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { FormatNumber2 } from '../utility/component/tools';
import ViewBillImage from '../utility/component/modals/viewBill';
import ModEPayment from '../utility/component/modals/ePaymentType';
import { useNavigate } from 'react-router-dom';
export const Del_BillingCardFooter = (props) => {
  const {
    rows,
    columns,
    setVoucherModStatus,
    bookingCustomerDetails,
    mrcNumber,
    isDelivery,
    state,
    orderID,
    setAlertTwoButtonModal,
    alertTwoButtonModal,
    saveStage,
    isMakeBillApiRun,
    isDeliveredApiRun,
    employee,
    qrBillList,
    bill_delevery_cart,
    setBill_delevery_cart,
    customerPrefixState,
    billSettelmentDetails,
    setDeliveryStatusAPI,
  } = props;
  const [ePaymentModStatus, setEPaymentModStatus] = useState(false);
  const [ePaymentTypeState, setEPaymentTypeState] = useState('');
  const [saveBillCartList, setsaveBillCartList] = useState([]);
  const [billNumber, SetBillnumber] = useState('');
  const billCartList = useSelector(selectBillingCartList);
  const [recievedAmt, setRecievedAmt] = useState(0);
  const [remarks, setRemarks] = useState('');
  const billingCartList = useSelector(selectBillingCartList);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const thermalRef = useRef();
  const billSettelmentFields = useSelector(selectBillSettlement);
  const navigate = useNavigate();
  const saveStageWithEmployee = () => {
    const totalDeliveryItems = bill_delevery_cart.filter(
      (i) => i.delivery === true
    );

    bill_delevery_cart.map((r) => {
      if (r.delivery) {
        saveStage(orderID, {
          table_prefix: prefix,
          SERIAL_NO: '',
          DATE: moment().format('YYYY-MM-DD'),
          BOOKING_ORDER_NO: r.BOOKING_ORDER_NO,
          STAGE_NAME: 'Delivery',
          EMPLOYEE_CODE: employee.code,
          EMPLOYEE_MOBILE: employee.mobile,
          EMPLOYEE_NAME: employee.employee_name,
          CURRENT_EMPLOYEE: employee.employee_name,
          DEPARTMENT_NAME: 'Delivery',
          ICODE: r.ICODE,
          ITEM_NAME: r.ITEM_NAME,
          UPPER_LOWER: r.UPPER_LOWER,
          QR_CODE: r.ID,
          CURRENT_EMP_MOBILE: employee.mobile,
          MRC_NO: mrcNumber,
          PREFIX: customerPrefixState,
        });
      }
      console.warn('totalDeliverd' + totalDeliveryItems.length);
      console.warn('remaining' + billSettelmentDetails[0].remainingOrders);
      if (
        totalDeliveryItems.length === billSettelmentDetails[0].remainingOrders
      ) {
        setDeliveryStatusAPI(r.BOOKING_ORDER_NO, r.ICODE, 'YES');
      }

      return;
    });
    dispatch(
      setAlertWithTitle({
        title: 'Alert',
        msg: 'Data saved',
      })
    );
    navigate('/');
    dispatch(
      setBillSettlement({
        cashAmount: '',
        btcAmount: '',
        epaymentAmount: '',
        epaymentType: '',
        cardAmount: '',
        cardNumber: '',
        Remarks: '',
        clothAmount: '',
        DISCOUNT_RATE: 0,
        DISCOUNT_AMOUNT: 0,
      })
    );
    SetBillnumber('');
    dispatch(setBillingCartList([]));
    setBill_delevery_cart([]);
    dispatch(setBillingAdvanceAmount(''));
    dispatch(setAdvanceVoucherDetails(''));
  };

  ///its only run in case delevery

  const saveAdvanceVoucherAPI = (invNo) => {
    dispatch(setSpinnerLoading('Saving '));
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: '',
      DATE: moment().format('YYYY-MM-DD'),
      INVNO: 0,
      PAY_MODE: 'CASH',
      CARD_NO: '',
      EPAYMENT_BANK: '',
      CUSTOMER_NAME: bookingCustomerDetails.customer_name,
      ADDRESS: bookingCustomerDetails.address,
      MOBILE: bookingCustomerDetails.mobile,
      DEBIT: 0,
      CREDIT: Number(recievedAmt),
      AMOUNT_DESC: remarks,
      LATITUDE: '',
      LONGITUDE: '',
      APPROVED: 'YES',
      MRC_NO: mrcNumber,
      PREFIX: customerPrefixState,
    };
    axios
      .post(`/api/vouchers/saveVoucher`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        if (response.data.result.affectedRows === 1) {
          saveStageWithEmployee();

          // setAlertTwoButtonModal({
          //   ...alertTwoButtonModal,
          //   status: true,
          //   title: 'Success',
          //   msg: 'Data saved successfully.',
          //   button1: 'Ok',

          //   button1Click: () => {
          //     setAlertTwoButtonModal({
          //       ...alertTwoButtonModal,
          //       status: false,
          //     });
          //   },
          // });
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
      })
      .finally(() => {
        dispatch(setAdvanceVoucherDetails(''));
        dispatch(setSpinnerLoading(false));
      });
  };

  ///its only run in case delevery

  const BillingAdvanceAmount = useSelector(selectBillingAdvanceAmount);

  const dispatch = useDispatch();
  const dueAmt = billSettelmentDetails[0]
    ? billSettelmentDetails[0].DUE_AMT - billSettelmentDetails[0].TOTAL_CREDIT
    : 0;
  const totalAmount = billSettelmentDetails[0] ? dueAmt : 0;
  return (
    <div className="w-full mt-2">
      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    border: '1px solid #383535',
                    borderTop: '1px solid #383535',
                    borderLeft: '1px solid #383535',
                    borderRight: '0px solid #383535',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className={`${
                    isDelivery ? '' : 'rounded-bl-sm'
                  } text-[#383535]`}
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px solid #383535',
                      }}
                      className="w-[60%] flex justify-start  p-1 pl-3"
                    >
                      Balance Due
                    </div>

                    <div className="flex p-1 flex-1 justify-end pr-3">
                      {billSettelmentDetails[0] && FormatNumber2(dueAmt)}
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="w-full text-right text-red font-semibold p-2"
                  >
                    1300.00
                  </div>
                ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                  }}
                  className=" h-full items-center border-r border-black flex justify-end "
                >
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                      border: '1px solid #383535',
                      borderRight: '0px solid #383535',
                      borderLeft: '1px solid #383535',
                    }}
                    className="pl-3 h-full flex items-center justify-end   "
                  >
                    <input
                      type="text"
                      className="w-full text-red"
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      placeholder="Remarks"
                      style={{
                        outline: 'none',
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <div className=" w-full flex ">
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end  items-center "
              style={
                item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
              }
            >
              {header === 'BOOKING_ORDER_NO' ? (
                <div
                  style={{
                    borderBottom: '1px solid #383535',

                    width: columns[index + 1].width + columns[index].width,
                  }}
                  className={`${
                    isDelivery ? '' : 'rounded-bl-sm'
                  } text-[#383535]`}
                >
                  <div className="w-full flex justify-between">
                    <div
                      style={{
                        borderRight: '1px solid #383535',
                        borderLeft: '1px solid #383535',
                      }}
                      className="w-[60%] flex justify-start  p-1 pl-3"
                    >
                      Cloth Balance
                    </div>

                    <div className="flex p-1 flex-1 justify-end pr-3">
                      {billSettelmentDetails[0] &&
                        FormatNumber2(billSettelmentDetails[0]?.CLOTH_BALANCE)}
                    </div>
                  </div>
                </div>
              ) : null}

              {/* {header === 'ITEM_NAME' ? (
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="w-full text-right text-red font-semibold p-2"
                  >
                    1300.00
                  </div>
                ) : null} */}

              {header === 'QNTY' ? (
                <div
                  style={{
                    width: columns[index].width + columns[index + 1].width,
                    borderLeft: '1px solid #383535',
                  }}
                  className=" h-full items-center border-r border-black flex justify-end "
                >
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                      borderBottom: '1px solid #383535',
                    }}
                    className="pl-3 h-full flex items-center justify-end   "
                  >
                    <div className="flex-1 flex border-black h-full items-center border-r">
                      Balance
                    </div>
                    <div className="flex-1 flex pl-3">
                      {' '}
                      {billSettelmentDetails[0] &&
                        FormatNumber2(
                          billSettelmentDetails[0]?.CLOTH_BALANCE +
                            billSettelmentDetails[0]?.DUE_AMT -
                            recievedAmt
                        )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {
        <div className=" w-full flex ">
          {columns.map((item, index) => {
            const header = item.field;
            return (
              <div
                key={index}
                className="  text-black flex justify-end  items-center "
                style={
                  item.flex ? { flex: item.flex } : { backgroundColor: 'white' }
                }
              >
                {header === 'BOOKING_ORDER_NO' ? (
                  <div
                    style={{
                      border: '1px solid #383535',
                      borderLeft: '1px solid #383535',
                      borderRight: '0px solid #383535',
                      borderTop: '0px solid #383535',

                      width: columns[index + 1].width + columns[index].width,
                    }}
                    className=" text-[#383535] "
                  >
                    <div className="w-full flex justify-between">
                      <div
                        style={{
                          borderRight: '1px solid #383535',
                        }}
                        className="w-[60%] flex justify-start  p-1 pl-3"
                      >
                        Amount Received
                      </div>

                      <div className="flex p-1 flex-1 justify-end  pr-3">
                        <input
                          type="number"
                          autoFocus={true}
                          placeholder="0.00"
                          className="w-full outline-none text-right "
                          value={recievedAmt === 0 ? '' : recievedAmt}
                          onChange={(e) => {
                            setRecievedAmt(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* {header === 'ITEM_NAME' ? (
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="w-full text-right text-red font-semibold p-2"
                  >
                    1300.00
                  </div>
                ) : null} */}

                {header === 'QNTY' ? (
                  <div
                    style={{
                      width: columns[index].width + columns[index + 1].width,
                    }}
                    className=" h-full border-l border-black border-r items-center flex justify-end "
                  >
                    <div
                      style={{
                        width: columns[index].width + columns[index + 1].width,
                        borderRight: '0px solid #383535',
                        borderBottom: '1px solid #383535',
                      }}
                      className=" h-full flex items-center justify-end font-semibold   "
                    >
                      {' '}
                      <button
                        variant="contained"
                        onClick={() => {
                          if (mrcNumber && customerPrefixState) {
                            saveAdvanceVoucherAPI();
                          }
                        }}
                        className="bg-green"
                        style={{
                          textTransform: 'none',
                          letterSpacing: '1px',
                          padding: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <div className="   ">Save </div>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      }

      <div className="hidden print:flex">
        {billNumber && (
          <ViewBillImage
            ref={thermalRef}
            billInvoice={billNumber}
            list={saveBillCartList}
            billSettlement={[
              {
                cash_amount: billSettelmentFields.cashAmount,
                card_amount: billSettelmentFields.cardAmount,
                btc_amount: billSettelmentFields.btcAmount,
                epayment: billSettelmentFields.epaymentAmount,
              },
            ]}
            date={billingCartList[0].DATE}
          />
        )}
      </div>

      <ModEPayment
        status={ePaymentModStatus}
        setStatus={setEPaymentModStatus}
        ePaymentType={ePaymentTypeState}
        setEPaymentType={setEPaymentTypeState}
      />
    </div>
  );
};
