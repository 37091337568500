import React, { useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import logo from '../utility/images/tailiringLogo.svg';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectTableMasterPrefix } from '../utility/Redux/profile';
import HsnCodeHelp from './hsnMasterHelp';
import {
  setAlertWithTitle,
  setHsnCodeHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import ItemMaster from '../utility/component/help/itemMaster';
import { toProperCase } from '../utility/component/format';

const inputClasses =
  'w-full px-3 py-2 mt-1 text-input border rounded-lg focus:outline-none focus:ring ring-primary';
const containerClasses = 'mb-4';
const buttonClasses =
  'bg-green text-primary-foreground px-4 py-2 rounded-lg hover:bg-primary/80';

const OpenItemModal = ({ status, onclose, UPPER_LOWER, afterSave }) => {
  const masterPrefix = useSelector(selectTableMasterPrefix);
  const token = useSelector(selectToken);
  const dispacth = useDispatch();
  const [itemList, setItemList] = useState([]);
  const emtypData = {
    table_prefix_master: masterPrefix,
    ICODE: '',
    ITEM_NAME: '',
    REGIONAL_INAME: '',
    ITEM_DESCRIPTION: '',
    RATE: '',
    SECTION_NAME: 'Indian',
    UPPER_LOWER: UPPER_LOWER,
    SEWING_HSN_CODE: 0,
    SEWING_TAX1_RATE: 0,
    SEWING_TAX2_RATE: 0,
    SEWING_TAX3_RATE: 0,
    CUTTING_HSN_CODE: 0,
    CUTING_TAX1_RATE: 0,
    CUTING_TAX2_RATE: 0,
    CUTING_TAX3_RATE: 0,
    STOP_YN: 'NO',
    CATEGORY: 'MALE',
    LOWER_ICODE: 0,
  };
  const [data, setData] = useState(emtypData);
  const getData = (sec) => {
    const data = {
      table_prefix_master: masterPrefix,
      SECTION_NAME: 'Indian',
      ICODE: '',
      ITEM_NAME: '',
    };

    axios
      .post(`/api/masters/listItemMaster`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (!response?.data?.error) {
          setItemList(response.data.response);
        }
      })
      .catch(function (error) {
        return null;
      });
  };
  const saveData = () => {
    if (data.ITEM_NAME && Number(data.RATE) > 1 && data.RATE) {
      const isDup = itemList.filter((o) => o.item_name === data.ITEM_NAME);
      if (!isDup[0]) {
        dispacth(setSpinnerLoading('Saving...'));
        axios
          .post(`/api/masters/saveItemMaster`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (!res.data.error) {
              dispacth(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Data saved successfully',
                })
              );
              setData(emtypData);
              afterSave(res);
            } else {
              dispacth(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Something Went wrong',
                })
              );
            }
          })
          .catch(function (error) {
            if (error.response.data.name === 'TokenExpiredError') {
              dispacth(setSessionExpired(true));
            }
            console.log(error);
            dispacth(
              setAlertWithTitle({
                title: 'ERROR',
                msg: 'Something went wrong.',
              })
            );
          })
          .finally(() => {
            dispacth(setSpinnerLoading(false));
          });
      } else {
        dispacth(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Duplicate items not allowed',
          })
        );
      }
    } else {
      if (!data.ITEM_NAME) {
        dispacth(
          setAlertWithTitle({
            title: 'Required',
            msg: 'Item name missing',
          })
        );

        const item = document.getElementById('item-name');
        item?.focus();
      } else {
        if (data.RATE < 1 || !data.RATE) {
          dispacth(
            setAlertWithTitle({
              title: 'Required',
              msg: 'Item Rate missing',
            })
          );

          const item = document.getElementById('rate');
          item?.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);
  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" text-primary-foreground min-h-screen flex items-center justify-center">
          <div className="bg-card bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-bold ">
                Create New Item - {UPPER_LOWER}
              </h2>
              <button onClick={onclose}>
                <i className="bi bi-x-square-fill text-red text-[20px]"></i>
              </button>
            </div>
            <FormGroup
              label="Item Name"
              type="text"
              id="item-name"
              value={data.ITEM_NAME}
              onChange={(e) => {
                setData((o) => {
                  return {
                    ...o,
                    ITEM_NAME: toProperCase(e.target.value),
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  const i = document.getElementById('rate');
                  i.focus();
                }
              }}
              name="item-name"
              placeholder="Enter item name"
            />
            <FormGroup
              label="Rate"
              type="number"
              id="rate"
              name="rate"
              value={data.RATE}
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  const i = document.getElementById('category');
                  i.focus();
                }
              }}
              onChange={(e) => {
                setData((o) => {
                  return {
                    ...o,
                    RATE: e.target.value,
                  };
                });
              }}
              placeholder="Enter price"
            />

            <FormGroup
              label="HSN Code"
              type="text"
              onFocus={() => {
                if (!data.SEWING_HSN_CODE) {
                  dispacth(setHsnCodeHelpStatus(true));
                }
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  const i = document.getElementById('category');
                  i.focus();
                }
              }}
              value={data.SEWING_HSN_CODE}
              search={() => {
                dispacth(setHsnCodeHelpStatus(true));
              }}
              id="hsn-code"
              name="hsn-code"
              placeholder="Enter HSN code"
            />
            <div className={containerClasses}>
              <label htmlFor="category" className="block text-sm font-medium">
                Category
              </label>
              <select
                id="category"
                name="category"
                className={inputClasses}
                value={data.CATEGORY}
                onKeyDown={(e) => {
                  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    saveData();
                  }
                }}
                onChange={(e) => {
                  setData((o) => {
                    return {
                      ...o,
                      CATEGORY: e.target.value,
                    };
                  });
                }}
              >
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            </div>
            <button
              type="button"
              onClick={() => {
                saveData();
              }}
              className={buttonClasses}
            >
              Create Item
            </button>
          </div>
          <HsnCodeHelp
            onRowClick={(p) => {
              setData({
                ...data,
                SEWING_HSN_CODE: p.row.HSN_CODE,
                SEWING_TAX1_RATE: p.row.GST / 2,
                SEWING_TAX2_RATE: p.row.GST / 2,
                SEWING_TAX3_RATE: p.row.GST,
                CUTTING_HSN_CODE: p.row.HSN_CODE,
                CUTING_TAX1_RATE: p.row.GST / 2,
                CUTING_TAX2_RATE: p.row.GST / 2,
                CUTING_TAX3_RATE: p.row.GST,
              });

              dispacth(setHsnCodeHelpStatus(false));
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
const FormGroup = ({
  label,
  type,
  onFocus,
  search,
  id,
  name,
  onKeyDown,
  placeholder,
  required,
  value,
  onChange,
}) => {
  return (
    <div className={containerClasses}>
      <div className="flex">
        <label htmlFor={id} className="block text-sm font-medium">
          {label}
        </label>
        {search && (
          <button onClick={search}>
            <i className="bi bi-search text-orange ml-2 flex items-center"></i>
          </button>
        )}
      </div>

      <input
        type={type}
        id={id}
        onFocus={onFocus}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={inputClasses}
        required={required}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
export default OpenItemModal;
