import React from 'react';
import { GetItemDetails } from './list';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectTableMasterPrefix } from '../utility/Redux/profile';
import { useEffect } from 'react';
import {
  selectItemDetails,
  selectLowerBookingItemDetails,
  selectLowerMeasuremnt,
  selectModifiedBookingStatus,
  selectModyingBookingID,
  selectRemarks,
  selectUpperBookingItemDetails,
  selectUpperMeasuremnt,
  setLowerMeasuremnt,
  setLowerMeasuremntList,
  setLowerQnty,
  setRemarks,
  setSelctedBookingUpperitemDetails,
  setSelectedBooingLowerItemDetails,
  setUpperMeasuremnt,
  setUpperMeasuremntList,
  setUpperQnty,
} from '../utility/Redux/booking';

import { selectStatus, setAlertWithTitle } from '../utility/Redux/modal';
import { BookingItemDetailsButton } from '../utility/component/buttons';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import Lottie from 'lottie-react';
import spinner from '../utility/json/Spinner.json';
import moment from 'moment';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import '../utility/styles/customeKeyboardStyle.css';

const ItemDetails = ({
  bookingCustomerDetails,
  setParameterInput,
  setSelectedRow,
  editCellValues,
  setLowerQnty,
  ItemtabScreen,
  SetItemTabScreen,
  bookingDate,
  upperList,
  SetUpperList,
  lowerList,
  SetLowerList,
}) => {
  const modifyingBookingId = useSelector(selectModyingBookingID);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectTableMasterPrefix);
  const SelectedUpperItem = useSelector(selectUpperBookingItemDetails);
  const SelectedLowerItem = useSelector(selectLowerBookingItemDetails);
  const upperMeasrment = useSelector(selectUpperMeasuremnt);
  const lowerMeasrment = useSelector(selectLowerMeasuremnt);
  const itemDetails = useSelector(selectItemDetails);
  const remarks = useSelector(selectRemarks);
  const modifiedOrderStatus = useSelector(selectModifiedBookingStatus);
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    if (itemDetails[0]) {
      const upperList = itemDetails.filter(
        (item) => item.upper_lower === 'UPPER'
      );
      const lowerList = itemDetails.filter(
        (item) => item.upper_lower === 'LOWER'
      );
      if (upperList[0]) {
        SetUpperList(upperList);
      } else {
        SetUpperList([]);
      }
      if (lowerList[0]) {
        SetLowerList(lowerList);
      } else {
        SetLowerList([]);
      }
    }
  }, [itemDetails]);

  useEffect(() => {
    if (!itemDetails[0]) {
      GetItemDetails(token, dispatch, prefix);
    }
  }, []);

  const loading = useSelector(selectStatus);

  return (
    <div className="h-full mb-2 overflow-hidden text-gray-700">
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Lottie
            animationData={spinner}
            loop={true}
            style={{ fontSize: 15, height: '30px' }}
            color="red"
          />
        </div>
      ) : (
        <div className="h-full flex flex-col">
          <div className="flex w-full   ">
            <div
              onClick={() => {
                SetItemTabScreen(1);
              }}
              style={{
                borderLeft:
                  ItemtabScreen === 1
                    ? '1px solid  #e8e2db'
                    : '1px solid #f2f5f3',
                borderTop:
                  ItemtabScreen === 1
                    ? '1px solid  #e8e2db'
                    : '0px solid #dee3e0',
                borderBottom:
                  ItemtabScreen === 1
                    ? '0px solid  #e8e2db'
                    : '1px solid  #e8e2db',
                borderRight:
                  ItemtabScreen === 1
                    ? '1px solid  #e8e2db'
                    : '1px solid #c8ccc9',
                color: ItemtabScreen === 1 ? 'black' : '#a8a3a3',
                fontWeight: ItemtabScreen === 1 ? 600 : 400,
                backgroundColor: ItemtabScreen === 1 ? 'white' : '#faf4ed',
                marginTop: ItemtabScreen === 1 ? '0px' : '4px',
              }}
              className="w-1/2  hover:cursor-pointer font-roboto flex justify-center p-2 rounded-t-sm text-[16px] items-center  "
            >
              <div
                style={{
                  lineHeight: '20px',
                  letterSpacing: '0.5px',
                }}
              >
                Upper / Lower
              </div>
            </div>

            <div
              onClick={() => {
                SetItemTabScreen(2);
              }}
              style={{
                borderLeft:
                  ItemtabScreen === 2
                    ? '1px solid  #e8e2db'
                    : '1px solid #f2f5f3',
                borderTop:
                  ItemtabScreen === 2
                    ? '1px solid  #e8e2db'
                    : '0px solid #dee3e0',
                borderBottom:
                  ItemtabScreen === 2
                    ? '0px solid  #e8e2db'
                    : '1px solid  #e8e2db',
                borderRight:
                  ItemtabScreen === 2
                    ? '1px solid  #e8e2db'
                    : '1px solid #e0dede',
                color: ItemtabScreen === 2 ? 'black' : '#a8a3a3',
                fontWeight: ItemtabScreen === 2 ? 600 : 400,
                backgroundColor: ItemtabScreen === 2 ? 'white' : '#faf4ed',
                marginTop: ItemtabScreen === 2 ? '0px' : '4px',

                lineHeight: '20px',
                letterSpacing: '0.5px',
              }}
              className="w-1/2  hover:cursor-pointer flex font-roboto justify-center p-2 rounded-t-sm text-[16px]"
            >
              Keyboard
            </div>
          </div>
          {ItemtabScreen === 1 ? (
            <div
              style={{
                border: '1px solid #e8e2db',
                borderTop: '0px solid #e8e2db',
              }}
              className=" flex flex-col grow rounded-b-sm overflow-y-scroll   p-2 "
            >
              <div className=" scroll-m-10 h-[100px] pr-2 ">
                <div className="grid gap-2 grid-cols-2 l:grid-cols-2 2xl:grid-cols-3  rounded-sm  ">
                  {upperList[0]
                    ? upperList.map((item, key) => {
                        // const shirts = item.img;

                        return (
                          <div key={key} className="flex items-center ">
                            <BookingItemDetailsButton
                              key={key}
                              bg={
                                SelectedUpperItem.icode === item.icode
                                  ? '#f2e4d3'
                                  : '#e8e2db'
                              }
                              title={item.item_name}
                              isSelected={
                                SelectedUpperItem.icode === item.icode
                                  ? true
                                  : false
                              }
                              icode={item.icode}
                              type={item.upper_lower}
                              onClick={() => {
                                if (
                                  !modifiedOrderStatus ||
                                  !SelectedUpperItem.icode
                                ) {
                                  if (!modifyingBookingId) {
                                    if (
                                      bookingCustomerDetails?.customer_name &&
                                      bookingCustomerDetails?.mobile
                                    ) {
                                      if (
                                        upperMeasrment[0] &&
                                        SelectedUpperItem.icode !== item.icode
                                      ) {
                                        setAlertTwoButtonModal({
                                          ...alertTwoButtonModal,
                                          status: true,
                                          title: 'Parameter!',
                                          msg: 'Do you want to clear all parameters and create new ?',
                                          button1: 'Yes',
                                          button2: 'No',
                                          button1Click: () => {
                                            changeItemParameterUpper(
                                              item,
                                              SelectedUpperItem,
                                              dispatch,
                                              setAlertTwoButtonModal,
                                              alertTwoButtonModal,
                                              remarks
                                            );
                                          },
                                        });
                                      } else {
                                        dispatch(
                                          setSelctedBookingUpperitemDetails(
                                            item
                                          )
                                        );
                                      }
                                    } else {
                                      dispatch(
                                        setAlertWithTitle({
                                          title: 'Alert',
                                          msg: 'Please select any Customer first.',
                                        })
                                      );
                                    }
                                  } else {
                                    dispatch(
                                      setAlertWithTitle({
                                        title: 'Error!',
                                        msg: 'Can not change item in Modify',
                                      })
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>

                <div className="grid gap-2 grid-cols-2 l:grid-cols-2 2xl:grid-cols-3 rounded-sm  mt-2">
                  {lowerList[0]
                    ? lowerList.map((item, key) => {
                        // const shirts = item.img;

                        return (
                          <div key={key} className="flex items-center ">
                            <BookingItemDetailsButton
                              bg={
                                SelectedLowerItem.icode === item.icode
                                  ? '#fad9cd'
                                  : '#f8e4dd'
                              }
                              type={item.upper_lower}
                              title={item.item_name}
                              isSelected={
                                SelectedLowerItem.icode === item.icode
                                  ? true
                                  : false
                              }
                              icode={item.icode}
                              onClick={() => {
                                if (
                                  !modifiedOrderStatus ||
                                  !SelectedLowerItem.icode
                                ) {
                                  if (!modifyingBookingId) {
                                    if (
                                      bookingCustomerDetails?.customer_name &&
                                      bookingCustomerDetails?.mobile
                                    ) {
                                      if (
                                        lowerMeasrment[0] &&
                                        SelectedLowerItem.icode !== item.icode
                                      ) {
                                        setAlertTwoButtonModal({
                                          ...alertTwoButtonModal,
                                          status: true,
                                          title: 'Parameter!',
                                          msg: 'Do you want to clear all parameters and create new ?',
                                          button1: 'Yes',
                                          button2: 'No',
                                          button1Click: () => {
                                            changeItemParameterLower(
                                              item,
                                              SelectedUpperItem,
                                              dispatch,

                                              remarks,
                                              setAlertTwoButtonModal,
                                              alertTwoButtonModal
                                            );
                                          },
                                        });
                                      } else {
                                        dispatch(
                                          setSelectedBooingLowerItemDetails(
                                            item
                                          )
                                        );
                                      }
                                    } else {
                                      dispatch(
                                        setAlertWithTitle({
                                          title: 'Customer Mobile!',
                                          msg: 'Please select Customer Mobile to continue.',
                                        })
                                      );
                                    }
                                  } else {
                                    dispatch(
                                      setAlertWithTitle({
                                        title: 'Error!',
                                        msg: 'Can not change item in Modify',
                                      })
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        );
                      })
                    : null}

                  <AlertModalTwoButton
                    details={alertTwoButtonModal}
                    setAlertTwoButtonModal={setAlertTwoButtonModal}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Keyboard
              onKeyPress={(e) => {
                if (e === '{bksp}') {
                  setParameterInput((p) => {
                    const r = p.slice(0, -1);

                    return r;
                  });
                } else {
                  if (e === '{enter}') {
                    const i = upperMeasrment.findIndex(
                      (e) => e.CODE === editCellValues.id
                    );

                    setSelectedRow(upperMeasrment[i + 1].CODE);
                  } else {
                    if (e.length === 1) {
                      setParameterInput((p) => p + e);
                      setActiveButton(e);
                    }
                  }
                }
              }}
              buttonTheme={[
                {
                  class: 'custom-button',
                  buttons:
                    'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z q w e r t y u i o p a s d f g h j k l z x c v b n m 1 {shift} {lock} {tab} .com {space} 2 3 4 5 6 7 8 9 0 ! @ # $ % ^ & * ( ) _  - + = [ ] { } | ; : , . < > ? / ` ~ {bksp} {enter} ',
                },
                {
                  class: 'custom-button-active',

                  buttons: activeButton ? activeButton : '',
                },
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemDetails;

export function changeItemParameterUpper(
  item,
  SelectedUpperItem,
  dispatch,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  remarks
) {
  if (SelectedUpperItem.lower_icode > 0 && SelectedUpperItem.lower_icode) {
    dispatch(setLowerMeasuremntList([]));

    dispatch(setSelectedBooingLowerItemDetails(''));
    dispatch(setLowerMeasuremnt([]));

    setAlertTwoButtonModal({
      ...alertTwoButtonModal,
      status: false,
      title: 'Parameter!',
      button1: 'Yes',
      button2: 'No',
    });
    dispatch(
      setRemarks({
        upper: '',
        lower: '',
      })
    );
    dispatch(setUpperQnty(1));
    dispatch(setLowerQnty(1));
  } else {
    dispatch(
      setRemarks({
        ...remarks,
        upper: '',
      })
    );
  }

  dispatch(setUpperMeasuremntList([]));
  dispatch(setSelctedBookingUpperitemDetails(item));
  dispatch(setUpperMeasuremnt([]));

  dispatch(setUpperQnty(1));
  setAlertTwoButtonModal({
    ...alertTwoButtonModal,
    status: false,
    title: 'Parameter!',
    button1: 'Yes',
    button2: 'No',
  });
}

export function changeItemParameterLower(
  item,
  SelectedUpperItem,
  dispatch,

  remarks,
  setAlertTwoButtonModal,
  alertTwoButtonModal
) {
  if (SelectedUpperItem?.lower_icode && SelectedUpperItem?.lower_icode > 0) {
    dispatch(setUpperMeasuremntList([]));
    dispatch(setSelctedBookingUpperitemDetails(''));
    dispatch(setUpperMeasuremnt([]));
    dispatch(
      setRemarks({
        upper: '',
        lower: '',
      })
    );

    dispatch(setUpperQnty(1));
    dispatch(setLowerQnty(1));
  } else {
    dispatch(
      setRemarks({
        ...remarks,
        lower: '',
      })
    );
  }
  dispatch(setLowerMeasuremntList([]));

  dispatch(setSelectedBooingLowerItemDetails(item));
  dispatch(setLowerMeasuremnt([]));
  dispatch(setLowerQnty(1));
  setAlertTwoButtonModal({
    ...alertTwoButtonModal,
    status: false,
    title: 'Parameter!',
    button1: 'Yes',
    button2: 'No',
  });
}
